import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getDateString} from "utils/date";
import {bannedStatus, Profile, ProfileRoles, ProfileStatus} from "@thelovebridge/core/src/entities/Profile";
import {ChangeUserRoleRequest, ModalChangeUserRole} from "components/common/Modal/ModalChangeUserRole";
import {ChangeUserStatusRequest, ModalChangeUserStatus} from "components/common/Modal/ModalChangeUserStatus";
import styled from "styled-components";
import {ButtonStPrimary} from "components/common/ButtonSt";
import {TableWrap} from "components/common/TableWrap";
import Table from "rc-table";
import BoxNoData from "components/common/BoxNoData";
import {useListUsersQuery, userManagementSlice} from "state/usersManagementState";
import {useAppDispatch} from "state/store";
import {shortenAddress} from "utils/address";
import {FormInputSec} from "components/common/FormInput";
import {useDebounce} from "usehooks-ts";
import {PaginationSt,TextDbNextPrev} from "components/common/PaginationSt";
import Pagination from "rc-pagination";
import {useTranslation} from "react-i18next";

interface PropsTypeUserTable{
  isOwner: boolean,
}
const PAGE_SIZE = 8

const UsersTable = ({isOwner}:PropsTypeUserTable) => {
  const {t} = useTranslation()
  const {data,isLoading} = useListUsersQuery(1)
  const dispatch = useAppDispatch()
  const updateTableFnc = useCallback(() => {
    dispatch(userManagementSlice.util.invalidateTags(['UserManagement']))
  },[dispatch])
  const [changeUserStatusRequest, setChangeUserStatusRequest] = useState<ChangeUserStatusRequest | null>(null)
  const [changeUserRoleRequest, setChangeUserRoleRequest] = useState<ChangeUserRoleRequest | null>(null)
  const [isVisible,setIsVisible] = useState<boolean>(false)
  const [isVisibleRole,setIsVisibleRole] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchTerm,setSearchTerm] = useState<string>('')
  const debounceTerm = useDebounce(searchTerm.trim(),800)
  const [allUser,setAllUser] = useState<Profile[]>([])
  const [dataTable,setDataTable] = useState<Profile[]>([])
  const columns = useMemo(()=> {
    return [
      {
        title:'User Address',
        dataIndex: 'userAddress',
        key:'userAddress',
        width: 130,
        render(value:string){
          return `${shortenAddress(value.toLowerCase())}`
        }
      },
      {
        title:'Email',
        dataIndex: 'email',
        key:'email',
        width:200,
      },
      {
        title:'Create At',
        dataIndex: 'createdAt',
        key:'createdAt',
        width:180,
        render(value:number){
          return <>{getDateString(value)}</>
        }
      },
      {
        title:'Role',
        dataIndex: 'role',
        key:'role',
        width:120,
        render(value:string,row:Profile) {
          return row?.isOwner === 1 ? 'OWNER' : value
        }
      },
      {
        title:'Status',
        dataIndex: 'status',
        key:'status',
        width:100,
        render(value: ProfileStatus) {
          return value === ProfileStatus.BANNED_MANUAL ? 'BANNED' : value
        }
      },
      {
        title:'Actions',
        dataIndex: 'actions',
        key:'actions',
        width:120,
        render(value:any,row:Profile){
          const {status,role,userAddress} = row
          const isBanned = bannedStatus.includes(status)
          const isAdmin = role === ProfileRoles.ADMIN
          return (
            <BtnGroup>
              <BtnAction onClick={() => {
                setIsVisible(true)
                setChangeUserStatusRequest({
                  userAddress: userAddress,
                  newStatus: isBanned ? ProfileStatus.ACTIVE : ProfileStatus.BANNED_MANUAL
                })
              }} disabled={isAdmin}>
                {isBanned ? 'UNBAN': 'BAN'}
              </BtnAction>
              {isOwner && !isBanned &&(
                <BtnAction onClick={() => {
                  setIsVisibleRole(true)
                  setChangeUserRoleRequest({
                    userAddress: userAddress,
                    newRole: isAdmin ? ProfileRoles.NORMAL : ProfileRoles.ADMIN
                  })
                }}>
                  {isAdmin ? 'REMOVE ADMIN': 'MAKE ADMIN'}
                </BtnAction>
              )}
            </BtnGroup>
          )
        }
      }
    ]
  },[isOwner])
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }
  const onChangePagination = (current:number) =>{
    setCurrentPage(current)
  }
  const onFilter = useCallback(() => {
    if(!data || data.length === 0){
      return
    }
    let allUserQuery:Profile[] = []
    if(debounceTerm !== ''){
      allUserQuery = data.filter((item:Profile) => {
        return item.userAddress.toLowerCase().includes(debounceTerm.toLowerCase()) || item.role.includes(debounceTerm.toUpperCase()) || (item.isOwner === 1 && 'OWNER'.includes(debounceTerm.toUpperCase()))
      })
    }else{
      allUserQuery = data
    }
    const dataLockTable = allUserQuery.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
    setAllUser(allUserQuery)
    setDataTable(dataLockTable)
  },[data,debounceTerm,currentPage])
  useEffect(() => {
    if(isLoading){
      return
    }
    onFilter()
  },[isLoading,onFilter])
  useEffect(() => {
    setCurrentPage(1)
  },[debounceTerm])
	return (
		<>
      <Filter>
        <FormInputSec placeholder={t('admin.users.search')} onChange={onSearch} value={searchTerm}/>
      </Filter>
      <SectionContent>
        {
          dataTable && <TableWrap>
            <Table
              columns={columns}
              data={dataTable as any}
              rowKey={'userAddress'}
              sticky={true}
              className={'rc-sticky-cs'}
              emptyText={() => <BoxNoData />}
            />
          </TableWrap>
        }
        {
          allUser && allUser.length > 8 && <PaginationSt>
            <Pagination
              onChange={(current:number) => {
                onChangePagination(current)
              }}
              current={currentPage}
              pageSize={PAGE_SIZE}
              total={allUser.length}
              showTitle={false}
              prevIcon={() => (
                <TextDbNextPrev>
                  Prev
                </TextDbNextPrev>
              )}
              nextIcon={() => (
                <TextDbNextPrev>
                  Next
                </TextDbNextPrev>
              )}
            />
          </PaginationSt>
        }
      </SectionContent>
      {isVisible && <ModalChangeUserStatus changeUserStatusRequest={changeUserStatusRequest} onDismiss={() => {
        setIsVisible(false)
        setChangeUserStatusRequest(null)
      }} isOpen={isVisible} updateTableFnc={updateTableFnc}/>}
      {
        isVisibleRole && <ModalChangeUserRole changeUserRoleRequest={changeUserRoleRequest} onDismiss={() => {
          setIsVisibleRole(false)
          setChangeUserRoleRequest(null)
        }} isOpen={isVisibleRole} updateTableFnc={updateTableFnc}/>
      }
    </>
	);
};

export default UsersTable;

const Filter = styled.div``
const SectionContent = styled.div`
  margin-top: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.primaryColor2};
  box-shadow: ${({ theme }) => theme.boxShadow3};
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 20px;
`
const BtnGroup = styled.div`
  ${({theme}) => theme.flexColumn};
  gap: 8px;
`
const BtnAction = styled(ButtonStPrimary)`
  padding: 5px 0;
  ${({ theme }) => theme.text1618};
  letter-spacing: 0.8px;
`
