import React from 'react';
import styled from 'styled-components';
import { SideBar } from '../common/SideBar';
import useAuth from 'hooks/useAuth';
import { Text100120 } from '../common/Typography';

interface PropsTypeLayoutPrimary {
  children?: React.ReactNode
}
export const LayoutAdmin = ({ children }: PropsTypeLayoutPrimary) => {
  const {isAdmin, isOwner} = useAuth()

  return (
    <LayoutAdminWrapper>
      <BodyWrapper>
          <BodyContent>
            {isAdmin || isOwner ? (
              <>
                <SideBar />
                {children && <Content>{children}</Content>}
              </>
            ) : (
              <Text100120>This page requires admin authentication. Please connect with an admin account.</Text100120>
            )}
          </BodyContent>
      </BodyWrapper>
    </LayoutAdminWrapper>
  )
}

const LayoutAdminWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryColor1};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const BodyWrapper = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 138px;
  @media only screen and (max-width: 1024px) {
    margin-top: 120px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 90px;
  }
  @media only screen and (max-width: 575px) {
    margin-top: 120px;
  }
`

const BodyContent = styled.main`
  display: flex;
  max-width: 1240px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  flex: 1 0 auto;
  min-height: 400px;
`

const Content = styled.div`
  flex: 0 0 calc(100% - 250px);
  max-width: calc(100% - 250px);
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
