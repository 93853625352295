import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {FormDatePicker} from "components/common/FormDatePicker";

const DatetimePicker = ({
  onChange,
  startDate,
  minDate,
  maxDate,
  placeholder,
  Icon,
  secondary=false,
}: {
  startDate?: number
  minDate?: Date
  maxDate?: Date
  onChange: (date: Date) => void
  placeholder?: string
  Icon?: any
  secondary?:boolean
}) => {
  return (
    <FormDatePicker secondary={secondary}>
      <DatePicker
        selected={startDate ? moment.unix(startDate).toDate() : new Date()}
        minDate={minDate}
        maxDate={maxDate ?? undefined}
        onChange={onChange}
        placeholderText={placeholder ?? 'Choose a date'}
        showYearDropdown
        className={'date-picker-input'}
      />
      {Icon && <Icon className={'date-picker-icon'} size={20} />}
    </FormDatePicker>
  )
}

export default DatetimePicker
