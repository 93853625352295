import {createContext} from "react";
import {OperatingSystem} from "models/System";

interface ISystemContext{
  system: OperatingSystem
}
const initialStateSystem = {
  system: OperatingSystem.UNKNOWN
}

export const OperatingSystemContext = createContext<ISystemContext>(initialStateSystem)