import i18n from "i18next";
import Backend from 'i18next-http-backend';
import {Parameters} from "@thelovebridge/core/src/constants/ParametersConstants";
import axios from 'axios'
import {initReactI18next} from "react-i18next";

const loadResources = async () => {
	const dataTranslationLocal = await fetch(`/locales/en/translation.json`,{
			headers : {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		}
	).then((response:Response) =>{
		return response.json();
	})
	return await axios.get(`${process.env.REACT_APP_URL_API}parameters/${Parameters.TRANSLATION}`)
		.then((response) => {
			const dataServer = response.data
			return {
				...dataServer,
				...dataTranslationLocal
			}
		})
		.catch(() => {
			// translation en default
			return dataTranslationLocal
		});
}

const backendOptions = {
	loadPath: '{{lng}}|{{ns}}',
	request: (options: any, url: any, payload: any, callback: any) => {
		try {
			loadResources().then((response) => {
				callback(null, {
					data: response,
					status: 200,
				});
			});
		} catch (e) {
			console.error(e);
			callback(null, {
				status: 500,
			});
		}
	},
};

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		backend: backendOptions,
		debug: false,
		interpolation: {
			escapeValue: false,
		}
	});

export default i18n;
