import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IPublicKey{
	publicKey: undefined | string
}

const initialState:IPublicKey = {
	publicKey: undefined
}

const publicKeySlice = createSlice({
	name: 'publicKey',
	initialState,
	reducers:{
		updatePublicKey(state,action:PayloadAction<{publicKey:string|undefined}>){
			state.publicKey = action.payload.publicKey
		}
	}
})

export const {updatePublicKey} = publicKeySlice.actions
export default publicKeySlice.reducer
