import { TypedDataSigner } from '@ethersproject/abstract-signer/src.ts';
import { BigNumber } from '@ethersproject/bignumber';
import { verifyTypedData } from '@ethersproject/wallet';
import { Signer } from 'ethers';

import { Gift as GiftEntity } from '../entities/Gifts';
import { domain } from './SignatureUtils';

const Gift = [
    { name: 'to', type: 'address' },
    { name: 'quantity', type: 'uint' },
    { name: 'expTimestamp', type: 'uint' }
];

const types = {
    Gift
};

const message = (gift: Partial<GiftEntity>) => ({
    to: gift.userAddress,
    quantity: BigNumber.from(gift.quantity),
    expTimestamp: BigNumber.from(gift.expTimestamp)
});

export const generateGiftSignature = (
    chainId: string,
    contractAddress: string,
    signer: Signer,
    gift: Partial<GiftEntity>
) => {
    return (signer as any as TypedDataSigner)._signTypedData(
        domain(chainId, contractAddress),
        types,
        message(gift)
    );
};

export const verifyGiftSignature = (
    chainId: string,
    contractAddress: string,
    adminAddress: string,
    gift: GiftEntity
) => {
    const recoverAddress = verifyTypedData(
        domain(chainId, contractAddress),
        types,
        message(gift),
        gift.signature!
    );

    return recoverAddress === adminAddress;
};
