import {
	LocksOnBridgeIPFS,
	LocksOnCloudIPFS,
	LocksVariationIPFS,
	LockVariation
} from '@thelovebridge/core/src/constants/LockVariation'
import {BASE_URL_IPFS} from "constants/baseUrl";
import axios from "axios";
import {lockAnimationMp4, lockAnimationUrl} from "constants/lockAnimationUrl";

export enum LOCK_URL{
	NORMAL= 'NORMAL',
	BRIDGE = 'BRIDGE',
	CLOUD = 'CLOUD'
}
export const getUrlLock = (variation: LockVariation,type?: LOCK_URL): string => {
	const arrayFilter = type === LOCK_URL.BRIDGE ? LocksOnBridgeIPFS : type === LOCK_URL.CLOUD ? LocksOnCloudIPFS : LocksVariationIPFS
	const found = arrayFilter.find((item) => item.variation === variation)
	return found ? `${BASE_URL_IPFS}${found.ipfs.replace('ipfs://','/')}` : ''
}

export const getUrlLockAnimation = (variation: any) : string => {
	return `${lockAnimationMp4[variation]}`
}

export const getUrlLockAnimationGif = (variation: any) : string => {
	return `${lockAnimationUrl[variation]}`
}
export const getUrlMedia = async (ipfs:string) => {
	try{
		const res:any = await axios.get(ipfs)
		const {content,contentType} = res.data
		return {
			content,
			contentType
		}
	} catch (e:any) {
		console.log(e,'Get Media Error')
		return undefined
	}
}
