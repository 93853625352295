import { useState, useEffect } from 'react';
import {OrientationType} from "models/OrientationType";

export default function useScreenOrientation():OrientationType {
  const [orientation, setOrientation] = useState<OrientationType>(window.screen.orientation.type as OrientationType);
  useEffect(() => {
    const handleOrientationChange = () =>
      setOrientation(window.screen.orientation.type as OrientationType);
    window.addEventListener('orientationchange', handleOrientationChange);
    return () =>
      window.removeEventListener('orientationchange', handleOrientationChange);
  }, []);

  return orientation;
}