import React, { useState } from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import { Text100120, Text2024 } from '../Typography'
import { ButtonStPrimary, ButtonStSecondary } from '../ButtonSt';
import { ModalTypeBase } from './ModalType';
import Loader from '../Loader';
import { useChangeUserStatusMutation } from 'state/usersManagementState'
import { shortenAddress } from 'utils/address'
import { ProfileStatus } from '@thelovebridge/core/src/entities/Profile'
import {toast} from "react-toastify";
import {ToastError} from "components/common/Toasts";
import {useTranslation} from "react-i18next";

export interface ChangeUserStatusRequest {
  userAddress: string
  newStatus: ProfileStatus
}

interface PropsTypeModalChangeUserStatus extends ModalTypeBase {
  changeUserStatusRequest: ChangeUserStatusRequest | null
  updateTableFnc: () => void
}

export const ModalChangeUserStatus = ({ changeUserStatusRequest, onDismiss,isOpen,updateTableFnc }: PropsTypeModalChangeUserStatus) => {
  const [changeUserStatus] = useChangeUserStatusMutation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const {t} = useTranslation()
  const handleChangeUserStatus = async () => {
    if(!changeUserStatusRequest){
      return;
    }
    setIsSubmitting(true)
    try {
      await changeUserStatus(changeUserStatusRequest)
      updateTableFnc()
      onDismiss()
    } catch (e) {
      console.log('Change user Status Error', e)
      toast.error(<ToastError>{t('modal.userStatus.messageFailed')}</ToastError>, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      })
    }finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal onDismiss={onDismiss} name={'modal-change-user-status'} isOpen={isOpen}>
      <ModalContent>
        <ModalTitle>
          <Text100120>{t('modal.userStatus.title')}</Text100120>
          {
            changeUserStatusRequest &&<Text2024>{t('modal.userStatus.description.text1')} <strong>{shortenAddress(changeUserStatusRequest.userAddress)}</strong> <>{t('modal.userStatus.description.text2')}</> {changeUserStatusRequest.newStatus}</Text2024>
          }
        </ModalTitle>
        <ModalBody>
          <GroupCta>
            <CtaSkip onClick={onDismiss}>{t('button.skip')}</CtaSkip>
            <CtaContinue onClick={handleChangeUserStatus} disabled={isSubmitting}>
              {
                isSubmitting ? <>
                  <Loader />
                  {t('button.confirm')}
                </> : <>{t('button.confirm')}</>
              }
            </CtaContinue>
          </GroupCta>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  padding-left: 85px;
  padding-right: 85px;
  ${({ theme }) => theme.containerModal};
`
const ModalTitle = styled.div`
  ${({ theme }) => theme.flexColumnCenterHoz};
  margin-bottom: 40px;
  & > * {
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    & > h1 {
      font-size: ${({ theme }) => theme.fontSize60};
    }
  }
`
const ModalBody = styled.div`
  width: 100%;
`
const GroupCta = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const CtaSkip = styled(ButtonStPrimary)`
  padding: 8px 24px;
  letter-spacing: 2.4px;
`
const CtaContinue = styled(ButtonStSecondary)`
  padding: 8px 24px;
  letter-spacing: 2.4px;
  ${({theme}) => theme.flexRowCenter};
  gap: 10px;
`
