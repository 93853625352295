import React from 'react';
import styled from "styled-components";
import {Text3244} from "components/common/Typography";
import {useTranslation} from "react-i18next";

const BoxNoData = () => {
	const {t} = useTranslation()
	return (
		<BoxNoDataWrap>
			<Text3244>{t('noData')}</Text3244>
		</BoxNoDataWrap>
	);
};

export default BoxNoData;
const BoxNoDataWrap = styled.div`
	min-height: 260px;
	${({theme}) => theme.flexRowCenter};
	p{
		text-transform: uppercase;
		letter-spacing: 1px;
	}
`