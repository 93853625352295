import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'

export const gallerySlice = createApi({
  tagTypes: ['Gallery'],
  reducerPath: 'Gallery',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    getGallery: build.query({
      query: ({userAddress}:{userAddress?:string}) => {
        return {
          url: `gallery`,
          params:{userAddress}
        }
      },
      providesTags: ['Gallery']
    })
  })
})
export const { useGetGalleryQuery } = gallerySlice
