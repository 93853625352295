import React, {useMemo} from 'react'
import { Gift, GiftStatus } from '@thelovebridge/core/src/entities/Gifts'
import { getDateString } from 'utils/date'
import { ButtonStPrimary } from 'components/common/ButtonSt'
import styled from 'styled-components'
import {TableWrap} from "components/common/TableWrap";
import Table from "rc-table";
import BoxNoData from "components/common/BoxNoData";
import {useTranslation} from "react-i18next";
import { shortenAddress } from 'utils/address'
import moment from "moment";
import {isAddress} from "@ethersproject/address";

export const GiftTable = ({handleDelete, handleSend, data}:{ handleDelete: (id: number) => void, handleSend: (gift: Gift) => void, data: Gift[] }) => {
  const {t} = useTranslation()
  const columns = useMemo(() => {
    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 100
      },
      {
        title: 'User Address',
        dataIndex: 'ensAddress',
        key: 'ensAddress',
        width: 150,
        render(value:string,row: Gift){
          return <>{value && isAddress(value) ? shortenAddress(value) : value ?? shortenAddress(row.userAddress)}</>
        }
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        width: 100,
        render(value:any,row: Gift){
          const {quantity} = row
          return <>{quantity === -1 ? '_' : quantity}</>
        }
      },
      {
        title: 'Expiration Date',
        dataIndex: 'expTimestamp',
        key: 'expTimestamp',
        width: 180,
        render(value: number) {
          return <>{getDateString(value)}</>
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        render(value: string, gift:Gift) {
          const {expTimestamp} = gift
          const momentTimestamp = moment(new Date()).unix()
          return <>{value === GiftStatus.SENT && expTimestamp < momentTimestamp ? 'Expired' : `${value}`}</>
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 100,
        render(value:any, gift: Gift){
          return <>
            {gift.status === GiftStatus.SENT && <BtnAction onClick={() => handleDelete(gift.id)}>{t('button.cancel')}</BtnAction>}
            {gift.status === GiftStatus.PREPARING && <BtnAction onClick={() => handleSend(gift)}>{t('button.sign')}</BtnAction>}
          </>
        }
      },
    ]
  },[handleDelete,t,handleSend])
  return (
    <TableWrap>
      <Table columns={columns} data={data as any} rowKey={'id'} sticky={true}
             className={'rc-sticky-cs'} emptyText={() => <BoxNoData/>}/>
    </TableWrap>
  )
}

const BtnAction = styled(ButtonStPrimary)`
  padding: 5px 0;
  ${({ theme }) => theme.text1618};
`
