import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import {Lock} from "@thelovebridge/core/src/entities/Lock";

export const lockManagementApi = createApi({
	tagTypes: ['LockAdmin'],
	reducerPath: 'LockAdmin',
	baseQuery: fetchBaseQuery(defaultParameters),
	endpoints: (build) => ({
		listLocks:build.query<Lock[],void>({
			query:() => {
				return{
					url: 'admin/locks',
					method:'GET'
				}
			},
			providesTags: ['LockAdmin']
		}),
		hide: build.mutation({
			query: (id: string) => {
				return {
					url: `admin/locks/${id}/hide`,
					method: 'PATCH'
				}
			}
		}),
		unHide: build.mutation({
			query: (id: string) => {
				return {
					url: `admin/locks/${id}/unhide`,
					method: 'PATCH'
				}
			}
		}),
	})
})
export const {
	useListLocksQuery,
	useHideMutation,
	useUnHideMutation
} = lockManagementApi
