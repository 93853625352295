import styled, { css } from 'styled-components';

export const ButtonStPrimary = styled.button<{ $disable?: boolean }>`
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 28px;
  text-align: center;
  ${({ theme }) => theme.text2430};
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryColor7};
  padding: 16px 36px;
  background: ${({ theme }) => theme.white};
  width: 100%;
  position: relative;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.2s ease-in;
  &:hover, &.isActive {
    background: ${({ theme }) => theme.bgPrimary};
    color: ${({ theme }) => theme.white};
  }
  ${({ $disable }) =>
    $disable &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 28px;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 2;
      }
    `};
  &:disabled{
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 28px;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 2;
    }
    pointer-events: none;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 32px;
    ${({ theme }) => theme.text2024};
  }
`
export const ButtonStSecondary = styled(ButtonStPrimary)`
  border-radius: 32px;
  background-color: transparent;
  background-image: ${({ theme }) => theme.bgPrimary};
  padding: 8px 32px;
  color: ${({ theme }) => theme.white};
  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.primaryColor7};
  }
`
