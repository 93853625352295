import React from 'react';
import styled, { css } from 'styled-components';
import { FormInputSt } from '../FormInputSt';

interface PropsTypeFormInputSec {
  alignLeft?: boolean
  placeholder?: string
  id?: string
  name?: string
  value?: any
  onChange?: any
  defaultValue?:any
  onBlur?:any
  type?:string
  min?:string
  disabled?:boolean
  onKeyPress?:any
}
const FormInputSec = ({ alignLeft = false,type='text',disabled=false, ...rest }: PropsTypeFormInputSec) => {
  return <FormInputControlSec {...rest} alignLeft={alignLeft} disabled={disabled} autoComplete={'off'}/>
}
export default FormInputSec
const FormInputControlSec = styled(FormInputSt)<{ alignLeft?: boolean | undefined }>`
  background-color: ${({ theme }) => theme.secondaryColor1};
  color: ${({ theme }) => theme.primaryColor8};
  &::placeholder {
    color: ${({ theme }) => theme.primaryColor5};
    text-align: left;
    ${({ alignLeft }) =>
      alignLeft &&
      css`
        text-align: left;
      `}
  }
`
