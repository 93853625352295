import React, { useCallback, useEffect, useState } from 'react'
import HelmetWrapper, { HelmetProps } from 'components/common/HelmetWrapper'
import { useLocation } from 'react-router-dom'
import { SeoValues } from 'constants/seoValues'

const WithHelmet = (OriginalComponent: any) => {
		const WithHelmetHOC = () => {
			const { pathname } = useLocation()

			const [helmetProps, setHelmetProps] = useState<HelmetProps>({
				title: 'The Love Bridge'
			})

			useEffect(() => {
				setHelmetProps(prevState => SeoValues[pathname] ? { ...prevState, ...SeoValues[pathname] } : prevState)
			}, [pathname])

			const onUpdateHelmet = useCallback((helmetProps: HelmetProps) => {
				setHelmetProps(prevState => {
					return {
						...prevState,
						...helmetProps
					}
				})
			}, [])

			return (
				<React.Fragment>
					<HelmetWrapper helmetProps={helmetProps} />
					<OriginalComponent onUpdateHelmet={onUpdateHelmet} />
				</React.Fragment>
			);
		}
		return WithHelmetHOC
};

export default WithHelmet;
