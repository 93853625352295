import { Network } from 'alchemy-sdk';

import { ChainIds } from '../constants/NetworkConstants';

export const getAlchemyNetwork = (chainId?: ChainIds): Network => {
    let network;
    switch (chainId) {
        case ChainIds.SEPOLIA:
            network = Network.ETH_SEPOLIA;
            break;
        default:
            network = Network.ETH_MAINNET;
    }
    return network;
};
