export interface Document {
  name: string;
  description: string;
  files: Array<File>;
}

export const prepareFormData = (doc: Document) : FormData => {
  const form = new FormData();
  form.append('data', JSON.stringify({
    name: doc.name,
    description: doc.description})
  );
  for (let index = 0; index < doc.files.length; index++) {
    const file = doc.files[index];
    form.append('asset', file, file.name);
  }
  return form;
}
