import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ButtonStPrimary } from '../ButtonSt';
import styled from 'styled-components';

export interface MenuOption {
    route: string;
    name: string;
    onlyAdmin?: boolean;
    onlyOwner?: boolean;
}
export const MenuLink = ({ menuOption }: { menuOption: MenuOption }) => {
    const location = useLocation();
    return (
        <Link to={menuOption.route}>
            <MenuButton className={location.pathname === menuOption.route ? 'isActive' : ''}>{menuOption.name}</MenuButton>
        </Link>
    )
}

const MenuButton = styled(ButtonStPrimary)`
  padding: 5px 10px;
`
