import React, { useEffect } from 'react';
import { LayoutAdmin } from 'components/layout';
import { Text100120 } from 'components/common/Typography';

export default function Admin() {

	useEffect(() => {
		document.title = 'Admin Panel || The Love Bridge';
	}, []);

	return (
		<LayoutAdmin>
			<Text100120>
				Select an item from the menu to start.
			</Text100120>
		</LayoutAdmin>
	);
}
