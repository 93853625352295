import React, { useEffect } from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import { WEB_PAGES } from '../../pages';
import { PATH } from '../../config';
import ProtectedRoute from '../ProtectedRoute';
import useAuth from "hooks/useAuth";

const RouterConfig = () => {
  const { pathname } = useLocation()
  const history = useHistory();
  const {isBanned} = useAuth()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  useEffect(() => {
    if(!isBanned || !history){
      return
    }
    history.push(PATH.BANNED);
  },[isBanned,history])
  return (
    <Switch>
      {/* List all public routes here */}
      <Route exact path={PATH.HOME}>
        <WEB_PAGES.HOME />
      </Route>
      <Route exact path={PATH.SIGN_LOCKS}>
        <WEB_PAGES.SIGN_LOCKS />
      </Route>
      <Route exact path={PATH.SIGN_NEW_LOCK_VARIATION}>
        <WEB_PAGES.SIGN_LOCKS />
      </Route>
      <ProtectedRoute exact path={PATH.INVENTORY}>
        <WEB_PAGES.INVENTORY />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.HOME}>
        <WEB_PAGES.ADMIN.HOME />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.GIFTS}>
        <WEB_PAGES.ADMIN.GIFTS />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.USERS}>
        <WEB_PAGES.ADMIN.USERS />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.OWNERSHIP}>
        <WEB_PAGES.ADMIN.OWNERSHIP />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.CONTRACT}>
        <WEB_PAGES.ADMIN.CONTRACT />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.WITHDRAWALS}>
        <WEB_PAGES.ADMIN.WITHDRAWALS />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.REPORT}>
        <WEB_PAGES.ADMIN.REPORT />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.LOCKS}>
        <WEB_PAGES.ADMIN.LOCKS />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.TRANSLATIONS}>
        <WEB_PAGES.ADMIN.TRANSLATIONS />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.ADMIN.SUBSCRIPTIONS}>
        <WEB_PAGES.ADMIN.SUBSCRIPTIONS />
      </ProtectedRoute>
      <ProtectedRoute exact path={PATH.LOCK}>
        <WEB_PAGES.SIGN_LOCKS />
      </ProtectedRoute>
      <Route exact path={PATH.PUBLIC_KEY_PAGE}>
        <WEB_PAGES.PUBLIC_KEY_PAGE/>
      </Route>
      <Route path={PATH.LOCK_DETAIL} component={WEB_PAGES.LOCK_DETAIL}/>
      <Route path={PATH.CONTACT_US} component={WEB_PAGES.CONTACT_US}/>
      <Route path={PATH.PRIVACY_POLICY} component={WEB_PAGES.PRIVACY_POLICY}/>
      <Route path={PATH.TERMS_OF_SERVICE} component={WEB_PAGES.TERMS_OF_SERVICE}/>
      <Route path={PATH.OUR_TEAM} component={WEB_PAGES.OUR_TEAM}/>
      <Route path={PATH.ROAD_MAP} component={WEB_PAGES.ROAD_MAP}/>
      <Route path={PATH.BANNED} component={WEB_PAGES.BANNED} />
      <Route path={PATH.NO_PAGE} component={WEB_PAGES.PAGE_NOT_FOUND} />
    </Switch>
  )
}
export default RouterConfig
