export const lockAnimationUrl:any = {
  "BLUE":  'https://drive.google.com/thumbnail?id=1KbnXh9tg6w8lwl4e5MZdnSPfrDL6F20i&sz=w1920-h1280',
  "GREEN": 'https://drive.google.com/thumbnail?id=10QyixXsy26vTk6kkiwRTwj4swisLJJ0o&sz=w1920-h1280',
  "NEON": 'https://drive.google.com/thumbnail?id=1ngqgqUus_nApyN4Tk-8GEutp-lNS-PMt&sz=w1920-h1280',
  "ORANGE": 'https://drive.google.com/thumbnail?id=12mZGITOFXdeCJxXDl-UeuaX8WhKpIZBo&sz=w1920-h1280',
  "PINK": 'https://drive.google.com/thumbnail?id=1SFVlT6lKRGNzL10KdcBixoLXzI3agNud&sz=w1920-h1280',
  "PURPLE": 'https://drive.google.com/thumbnail?id=1Fa_aBYp7ibgEmLtD4WPtpceegpyPmaOt&sz=w1920-h1280',
  "RED":  'https://drive.google.com/thumbnail?id=12Ug5auDab2hfnXIVe7pMSOF68ETS_kNC&sz=w1920-h1280',
  "YELLOW": 'https://drive.google.com/thumbnail?id=1LpgQwyUlVRR8goB7VPIM83nkaI7IW-Qg&sz=w1920-h1280',
}

export const lockAnimationMp4:any = {
  "BLUE": 'https://player.vimeo.com/video/927334812?autoplay=1&loop=1&autopause=0',
  "GREEN": 'https://player.vimeo.com/video/927334803?autoplay=1&loop=1&autopause=0',
  "NEON": 'https://player.vimeo.com/video/927334846?autoplay=1&loop=1&autopause=0',
  "ORANGE": 'https://player.vimeo.com/video/927334831?autoplay=1&loop=1&autopause=0',
  "PINK": 'https://player.vimeo.com/video/927334794?autoplay=1&loop=1&autopause=0',
  "PURPLE": 'https://player.vimeo.com/video/927334784?autoplay=1&loop=1&autopause=0',
  "RED":  'https://player.vimeo.com/video/927334821?autoplay=1&loop=1&autopause=0',
  "YELLOW": 'https://player.vimeo.com/video/927334855?autoplay=1&loop=1&autopause=0',
}