import React from 'react';
import { Route,Redirect } from 'react-router-dom';
import {PATH} from "../../config";
import useAuth from "hooks/useAuth";

interface PropsTypeProtectedRoute {
  children: React.ReactNode
  path: string
  exact?: boolean
}
function ProtectedRoute({ children, path, exact, ...rest }: PropsTypeProtectedRoute) {
  const { address } = useAuth()
  return <Route path={path} exact {...rest} render={({location}) => {
    return !address ?  <Redirect
      to={{
        pathname: PATH.HOME,
        state: { from: location }
      }}
    /> : (
      <>{children}</>
    )
  }} />
}
export default ProtectedRoute
