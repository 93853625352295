import React, { useMemo } from 'react';
import {
  createGlobalStyle,
  css,
  DefaultTheme,
  StyleSheetManager,
  ThemeProvider as StyledComponentsThemeProvider
} from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

const colors = () => {
  return {
    white: '#FFFFFF',
    black: '#000000',
    warning: '#b47700',
    primaryColor1: '#FDBAFF',
    primaryColor2: '#FDC3FF',
    primaryColor3: '#FCA4FF',
    primaryColor4: '#FEABFB',
    primaryColor5: '#FB85FF',
    primaryColor6: '#FF9CFB',
    primaryColor7: '#CC09BD',
    primaryColor8: '#D909C1',
    primaryColor9: '#CB0ABD',
    primaryColor10: '#A954A5',
    primaryColor11: '#FFE7FE',
    primaryColor12: '#DE4BCD',
    primaryColor13: '#D802BE',
    secondaryColor1: 'rgba(255, 255, 255, 0.6)',
    secondaryColor2: '#7D39B8',
    bgColor1: 'rgba(185, 117, 187, 0.4)',
    bgColor2: 'rgba(231, 107, 235, 0.6)',
    bgColor3: 'rgba(255, 255, 255, 0.4)',
    bgColor4: 'rgba(165,207,250,0.5)',
    bgText: 'linear-gradient(90deg, #D802BE 0%, #0B7DB1 100%)',
    bgPrimary: 'linear-gradient(90deg, #D802BE 0%, #0B7DB1 100%)',
    bgPrimary1: 'linear-gradient(90deg, #D802BE 0.07%, #0B7DB1 100.07%)',
    bgGradient1: 'linear-gradient(rgba(255, 122, 242, 0.4), rgba(53, 174, 235, 0.4))',
    bgGradient2: 'linear-gradient(270deg, #0083C5 0%, #FF64F0 100%)',
    bgGradient3: 'linear-gradient(90deg, rgba(216, 2, 190, 0.2), rgba(11, 125, 177, 0.2))',
    bgGradient4: 'linear-gradient(180deg, #FEE0FF 0%, #FCA7FF 100%)',
    bgGradient5: 'linear-gradient(90deg, rgba(255, 143, 241, 0.5) 0%, rgba(171, 229, 255, 0.5) 100%)',
    bgGradient6: 'linear-gradient(rgba(255, 143, 241, 0.4), rgba(171, 229, 255, 0.4))',
    bgGradient7: 'linear-gradient(rgba(171, 229, 255, 0.4), rgba(255, 143, 241, 0.4))',
    bgGradient9: 'linear-gradient(180deg, rgba(253,186,255,1) 0%, rgba(253,199,255,0.4) 25%, rgba(201,235,255,0.5) 50%, rgba(165,207,250,0.5) 100%)',
    bgGradient10: 'linear-gradient(180deg,rgba(171,229,255,0.4),rgba(253,186,255,1))',
    boxShadow1: '4px 12px 40px 0px #BE68BB99',
    boxShadow2: '8px 8px 40px 0px rgba(190, 104, 187, 0.80)',
    boxShadow3: '8px 8px 40px 0 rgba(190, 104, 187, 0.60)',
    boxShadow4: '8px 8px 40px 0 rgba(190, 104, 187, 0.80)',
    boxShadow5: '8px 8px 40px 0px #BE68BB',
  }
}
const fontSizes = () => {
  return {
    fontSize100: '100px',
    fontSize84: '84px',
    fontSize80: '80px',
    fontSize60: '60px',
    fontSize48: '48px',
    fontSize40: '40px',
    fontSize36: '36px',
    fontSize32: '32px',
    fontSize28: '28px',
    fontSize24: '24px',
    fontSize20: '20px',
    fontSize18: '18px',
    fontSize16: '16px',
    fontSize14: '14px',
    fontSize12: '12px'
  }
}
const fontFamilyArial = 'Arial, sans-serif'
const fontFamilies = () => {
  return {
    fontArial: fontFamilyArial
  }
}
export const theme = (): DefaultTheme => {
  return {
    ...colors(),
    ...fontSizes(),
    ...fontFamilies(),
    flexRowCenter: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    flexRowCenterVertical: css`
      display: flex;
      align-items: center;
    `,
    flexColumn: css`
      display: flex;
      flex-direction: column;
    `,
    flexColumnCenterHoz: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    flexColumnCenter: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    container: css`
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      padding-left: 80px;
      padding-right: 80px;
      @media only screen and (max-width: 1199px) {
        padding-left: 64px;
        padding-right: 64px;
      }
      @media only screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    `,
    text1214: css`
      font-size: ${({ theme }) => theme.fontSize12};
      line-height: 1.667;
    `,
    text1220: css`
      font-size: ${({ theme }) => theme.fontSize12};
      line-height: 1.1667;
    `,
    text1416: css`
      font-size: ${({ theme }) => theme.fontSize12};
      line-height: 1.1428;
    `,
    text1618: css`
      font-size: ${({ theme }) => theme.fontSize16};
      line-height: 1.125;
    `,
    text1620: css`
      font-size: ${({ theme }) => theme.fontSize16};
      line-height: 1.25;
    `,
    text1624: css`
      font-size: ${({ theme }) => theme.fontSize16};
      line-height: 1.5;
    `,
    text1628: css`
      font-size: ${({ theme }) => theme.fontSize16};
      line-height: 1.75;
    `,
    text2024: css`
      font-size: ${({ theme }) => theme.fontSize20};
      line-height: 1.2;
    `,
    text2430: css`
      font-size: ${({ theme }) => theme.fontSize24};
      line-height: 1.25;
    `,
    text2832: css`
      font-size: ${({ theme }) => theme.fontSize28};
      line-height: 1.1428;
    `,
    text3236: css`
      font-size: ${({ theme }) => theme.fontSize32};
      line-height: 1.125;
    `,
    text3238: css`
      font-size: ${({ theme }) => theme.fontSize32};
      line-height: 1.375;
    `,
    text3244: css`
      font-size: ${({ theme }) => theme.fontSize32};
      line-height: 1.1875;
    `,
    text3644: css`
      font-size: ${({ theme }) => theme.fontSize36};
      line-height: 1.222;
    `,
    text4048: css`
      font-size: ${({ theme }) => theme.fontSize40};
      line-height: 1.2;
    `,
    text4860: css`
      font-size: ${({ theme }) => theme.fontSize48};
      line-height: 1.25;
    `,
    text100120: css`
      font-size: ${({ theme }) => theme.fontSize100};
      line-height: 1.2;
    `,
    textShadow: css`
      text-shadow:
        -2px -2px 0 ${({ theme }) => theme.primaryColor8},
        2px -2px 0 ${({ theme }) => theme.primaryColor8},
        -2px 2px 0 ${({ theme }) => theme.primaryColor8},
        2px 2px 0 ${({ theme }) => theme.primaryColor8};
    `,
    textBaseGradient: css`
      background: ${({ theme }) => theme.bgText};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `,
    containerModal: css`
      padding: 40px 100px;
      @media only screen and (max-width: 1024px) {
        padding-left: 60px;
        padding-right: 60px;
      }
      @media only screen and (max-width: 767px) {
        padding: 32px;
      }
      @media only screen and (max-width: 575px) {
        padding: 16px;
      }
    `
  }
}
export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const themeObject = useMemo(() => theme(), [])

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
    </StyleSheetManager>
  )
}
export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1,h2,h3,h4,h5,h6,p{
    margin: 0;
  }
  ul{
    padding:0;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  body > iframe{
    display: none;
  }
  img{
    display: block;
    width: 100%;
    vertical-align: middle;
  }
  table{
    border-collapse: collapse;
  }
  div[role="menu"][data-state="open"]{
    background-color: ${({theme}) => theme.white};
  }
  #checkout-with-card-iframe{
    border: none !important;
  }
  @keyframes bounceInDown {
    from{
      top: 0
    }
    to{
      top: 20%;
    }
  }
  @keyframes bounceOutUp {
    from{
      bottom: 0
    }
    to{
      bottom: 20%;
    }
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .Toastify__toast-body{
    div{
      ${({ theme }) => theme.text2430}
      font-family: ${({ theme }) => theme.fontArial};
      color: ${({ theme }) => theme.primaryColor8};
      line-height: 1;
      @media only screen and (max-width: 1024px) {
        font-size: ${({ theme }) => theme.fontSize20};
        line-height: 1.15;
      }
      @media only screen and (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSize18};
        line-height: 1.38;
      }
    }
  }

  @keyframes animationScaleUp {
    0% {
      transform: translateX(-50%) scale(0);
    }
   
    100% {
      transform: translateX(-50%) scale(1);
    }
  }
`
