import React from 'react';
import styled, {css} from 'styled-components';
import {IMAGES} from "assets/images";
import useWindowDimensions from "hooks/useWindowDimensions";
import useScreenOrientation from "hooks/useScreenOrientation";
import {OrientationType} from "models/OrientationType";

interface PropsTypeLayoutPrimary {
  children?: React.ReactNode
  noBgTop?:boolean
}
export const LayoutPrimary = ({ children,noBgTop = false }: PropsTypeLayoutPrimary) => {
  const windowDimension = useWindowDimensions()
  const {width} = windowDimension
  const orientation = useScreenOrientation()
  return (
    <LayoutPrimaryWrapper noBgTop={noBgTop} widthAttr={width} landscape={width < 1024 && OrientationType.LANDSCAPE === orientation}>
      {children && <Main noBgTop={noBgTop} widthAttr={width} landscape={width < 1024 && OrientationType.LANDSCAPE === orientation}>
        <BodyBox>{children}</BodyBox>
      </Main>}
    </LayoutPrimaryWrapper>
  )
}

const LayoutPrimaryWrapper = styled.div<{noBgTop:boolean | undefined; widthAttr:number;landscape:boolean}>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ noBgTop,landscape }) =>
    !noBgTop &&
    css`
       &::after,&::before{
           content: '';
           position: absolute;
           top: 138px;
           left: 0;
           right: 0;
           z-index: 0;
           @media only screen and (max-width: 1023px) {
               top: 120px;
               height: 300px;
           }
           @media only screen and (max-width: 767px) {
               height: 200px;
               top: 90px;
           }
           @media only screen and (max-width: 575px) {
               top: 140px;
           }
       } 
       &::before{
         background-image: url(${IMAGES.ONLY_CLOUD});
         background-size: cover;
         background-repeat: no-repeat;
       }
       &::after{
         background-image: ${({theme}) => theme.bgGradient10};
       } 
    `};
  &::before,&::after{
    height: ${({widthAttr,noBgTop}) => !noBgTop ? (widthAttr > 1024 ? '28vw' : widthAttr > 767 ? '300px' : '240px') : 'auto'};
  }
  &::after,&::before{
    top: ${({landscape}) => landscape && '0 !important'};
  }
`
const Main = styled.main<{noBgTop:boolean | undefined;widthAttr:number;landscape:boolean}>`
  flex: 1 0 auto;
  margin-top: ${({landscape}) => landscape ? '0 !important' : '138px'};
  position: relative;
  z-index: 2;
  &::after{
    --top: 0px;  
    content: '';
    position: absolute;
    top: ${({widthAttr,noBgTop}) => !noBgTop ? (widthAttr > 1024 ? 'calc(28vw + var(--top))' : widthAttr > 767 ? 'calc(300px + var(--top))' : 'calc(240px + var(--top))') : '0'};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: ${({theme}) => theme.bgGradient9};
    z-index: 0;
    display: none;
  }
  ${({ noBgTop }) =>
    !noBgTop &&
    css`
     padding-top: 60px;
     &::after{
       display: block;
     }
  `};
  @media only screen and (max-width: 1023px) {
    margin-top: 120px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 90px;
  }
  @media only screen and (max-width: 575px) {
    margin-top: 140px;
  }
`
const BodyBox = styled.div`
  position: relative;
  z-index: 2;
`
