import React, { useEffect, useState } from 'react'
import { LayoutAdmin } from 'components/layout';
import useAuth from 'hooks/useAuth'
import { Text100120 } from 'components/common/Typography'
import { ModalTransferOwnership } from 'components/common/Modal/ModalTransferOwnership'
import { useHistory } from 'react-router-dom'
import { PATH } from '../../../config'
import { FormInputSec } from 'components/common/FormInput'
import styled from "styled-components";
import {ButtonStSecondary} from "components/common/ButtonSt";
import {isAddress} from "utils/address";
import {useTranslation} from "react-i18next";

export default function Ownership() {
  const {t} = useTranslation()
  const {isOwner} = useAuth()
  const [transferToAddress, setTransferToAddress] = useState<string | null>(null)

  const history = useHistory()
  const [visibleModal,setVisibleModal] = useState<boolean>(false)
  const onOpen = () => {
    setVisibleModal(true)
  }
  useEffect(() => {
    document.title = 'Ownership || Admin Panel || The Love Bridge'
  }, [])
  const handleDismiss = () => {
    setTransferToAddress(null)
    setVisibleModal(false)
    history.push({
      pathname: PATH.ADMIN.HOME
    })
  }
  return (
    <LayoutAdmin>
      <FormInputSec
        placeholder={t('admin.ownership.userAddress')}
        id={'userAddress'}
        name={'userAddress'}
        value={transferToAddress ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTransferToAddress(e.target.value)}
      />
      {
        isOwner &&  <ButtonTransfer onClick={onOpen} disabled={!isAddress(transferToAddress)}>{t('admin.ownership.transfer')}</ButtonTransfer>
      }
      {isOwner ?(
        <>
          {visibleModal && <ModalTransferOwnership userAddress={transferToAddress} onDismiss={handleDismiss} isOpen={visibleModal}/>}
        </>
      ) : <Text100120>{t('admin.ownership.notification')}</Text100120>}
    </LayoutAdmin>
  )
}
const ButtonTransfer = styled(ButtonStSecondary)``
