import styled, {css} from 'styled-components';
import {BoxStType} from "models/common";

type TypeSize = BoxStType | undefined
export const BoxSt = styled.div<{ size?: TypeSize; boxGradient?:boolean }>`
  background-color: ${({ theme }) => theme.primaryColor4};
  ${({ size }) => {
    switch (size) {
      case BoxStType.LARGE:
        return `
          padding: 32px 60px;
          border-radius:40px;
          @media only screen and (max-width:1024px){
            padding: 32px;
          }
        `
      case BoxStType.MEDIUM:
        return `
          padding: 32px 40px;
          border-radius:40px;
          @media only screen and (max-width:1024px){
            padding: 32px;
          }
        `
      case BoxStType.SMALL:
        return `
          padding: 24px 40px;
          border-radius:40px;
          @media only screen and (max-width:1024px){
            padding: 32px;
          }
        `
      default:
        return `
          padding:20px;
          border-radius: 20px;
        `
    }
  }};
  ${({boxGradient}) =>
    boxGradient &&
    css`
     background-color: transparent;
     background-image: ${({theme}) => theme.bgGradient1};
  `};
`
export const BoxImageSt = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow2};
  border-radius: 16px;
  img {
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }
`
export const BoxGradientSt = styled.div`
  background: ${({ theme }) => theme.bgColor2};
  box-shadow: ${({ theme }) => theme.boxShadow3};
  backdrop-filter: blur(10px);
  ${({ theme }) => theme.flexRowCenter};
`
