import React, {useEffect} from 'react';
import {LayoutAdmin} from "components/layout";
import styled from "styled-components";
import { Text3236} from "components/common/Typography";
import SectionChangePrice from "pages/Admin/Contract/SectionChangePrice";
import SectionCurrencies from "pages/Admin/Contract/SectionCurrencies";

const Contract = () => {
	useEffect(() => {
		document.title = 'Contract || Admin Panel || The Love Bridge'
	}, [])
	return (
		<LayoutAdmin>
			<Section>
				<TitleSection>Change Price</TitleSection>
				<SectionContent>
					<SectionChangePrice />
				</SectionContent>
			</Section>
			<Section>
				<TitleSection>Change payment currencies
				</TitleSection>
				<SectionContent>
					<SectionCurrencies />
				</SectionContent>
			</Section>
		</LayoutAdmin>
	);
};

export default Contract;
const Section = styled.section``
const TitleSection = styled(Text3236)`
	${({theme}) => theme.textBaseGradient};
`
const SectionContent = styled.div`
	margin-top: 20px;
	gap: 20px;
  background: ${({ theme }) => theme.primaryColor2};
  box-shadow: ${({ theme }) => theme.boxShadow3};
  backdrop-filter: blur(10px);
	padding: 20px;
	border-radius: 20px;
`

