import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import {Lock} from "@thelovebridge/core/src/entities/Lock";

export const lockDetailSlice = createApi({
	tagTypes: ['lockDetail'],
	reducerPath: 'lockDetail',
	baseQuery: fetchBaseQuery(defaultParameters),
	endpoints: (build) => ({
		getDetailLock: build.query<Lock,number>({
			query: (id:number) => {
				return {
					url: `locks/${id}`,
					method: 'GET'
				}
			}
		}),
		getDetailLockLogged:build.query<Lock,number>({
			query: (id:number) => {
				return {
					url: `locks/${id}/private`,
					method: 'GET'
				}
			}
		})
	})
})
export const { useLazyGetDetailLockQuery,useLazyGetDetailLockLoggedQuery } = lockDetailSlice
