import React, {useEffect, useMemo, useState} from 'react'
import {
	useAddress,
	useConnectionStatus,
	useDisconnect, useEmbeddedWalletUserEmail,
	useUser,
	useWalletConfig
} from '@thirdweb-dev/react'
import {ModalConnectStatusType} from 'components/common/Modal/ModalType'
import {MODAL_CONNECT_STATUS} from 'components/common/Modal/Modal.const'
import {AuthContext} from './AuthContext'

import { bannedStatus, Profile, ProfileRoles } from '@thelovebridge/core/src/entities/Profile'
import {useUpdateEmailUserMutation} from "state/userState";
import {PATH} from "../../config";
import usePrevious from "hooks/usePrevious";
import { useHistory, useLocation } from 'react-router-dom'
import {updatePublicKey} from "state/publicKeyState";
import {useAppDispatch} from "state/store";

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const history = useHistory()
	const location = useLocation()
	const email = useEmbeddedWalletUserEmail()
	const [updateEmail] = useUpdateEmailUserMutation()
	const disconnect = useDisconnect();
	const address = useAddress();
	const connectionStatus = useConnectionStatus()
	const {user, isLoggedIn } = useUser();
	const wallet = useWalletConfig()
	const previousAddress = usePrevious(address)
	const [showModalConnect, setShowModalConnect] = useState<boolean>(true)
	const [popupView, setPopupView] = useState<ModalConnectStatusType>(MODAL_CONNECT_STATUS.CONNECT)
	const dispatch = useAppDispatch()
	const handleDisconnect = () => disconnect()
	const isConnected = useMemo(() => {
		return isLoggedIn
	},[isLoggedIn])
	const isSigned = useMemo(() => {
		return connectionStatus === 'connected' && isLoggedIn && !!user && 'data' in user
	},[connectionStatus, isLoggedIn, user])

	const isOwner = useMemo(() => {
		if (!user || !user.data) return false
		const checkuser = user.data as any as Profile
		return checkuser?.isOwner ? String(checkuser.isOwner) === "1" : false
	}, [user])

	const isAdmin = useMemo(() => {
		if (!user || !user.data) return false
		const checkuser = user.data as any as Profile
		return checkuser?.role ? checkuser.role === ProfileRoles.ADMIN.toString() : false
	}, [user])

	const isBanned = useMemo(() => {
		if (!user || !user.data) return false
		const checkuser = user.data as any as Profile
		return checkuser?.status ? bannedStatus.includes(checkuser.status) : false
	}, [user])

	const isWalletMetamask = useMemo(() => {
		return wallet?.id === 'metamask'
	},[wallet])

	const isSignWithEmail = useMemo(() => {
		return wallet?.meta.name === 'Email'
	},[wallet])
	useEffect(() => {
		if(!isSigned) {
			return;
		}
		if(user && !(user?.data as any as Profile)?.email && email){
			const emailUser = email.data
			if(emailUser){
				const updateUser = async () => {
					await updateEmail({email:emailUser})
				}
				updateUser().then()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[isSigned,user])
	//redirect to HomePage when switch account
	useEffect(() => {
		if(address && previousAddress && address !== previousAddress){
			dispatch(updatePublicKey({publicKey:undefined}))
			if (!location.pathname.startsWith(PATH.SIGN_LOCKS))
				history.push(PATH.HOME)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[isLoggedIn,address,previousAddress])
	const isNotProductionEnv = useMemo(() => {
		const locationUrl = window.location.href
		return locationUrl.includes('http://') || locationUrl.includes('https://testnet')
	},[])
	return (
		<AuthContext.Provider
			value={{
				isConnected,
				popupView,
				setPopupView,
				handleDisconnect,
				setShowModalConnect,
				showModalConnect,
				user: user as any as Profile,
				address,
				isOwner,
				isAdmin,
				isLoading: false,
				isBanned,
				isSigned,
				isWalletMetamask,
				isSignWithEmail,
				isNotProductionEnv
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
export default AuthProvider
