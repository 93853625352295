import React from 'react';
import {ConnectWallet, lightTheme} from "@thirdweb-dev/react";
import styled, { css } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import {PATH} from "../../../config";
interface PropsTypeConnect{
	loginOptional?:boolean
	fullWidth?: boolean
}
const ConnectWalletThirdWeb = ({loginOptional = false, fullWidth = false} : PropsTypeConnect) => {
	const history = useHistory()
	const location = useLocation()
	return (
		<ConnectWalletWrap fullWidth={fullWidth}>
			<ConnectWallet
				theme={lightTheme({
					colors: {
						accentText: "#CC09BD",
						accentButtonBg: "#CC09BD",
						modalBg: "white",
						primaryButtonBg: fullWidth ? "rgb(217, 9, 193)" : "white",
						primaryText: 'rgb(217, 9, 193)',
						secondaryText: "rgb(253, 186, 255)",
						primaryButtonText: fullWidth ? "white": "#CC09BD",
					},
				})}
				switchToActiveChain={true}
				modalSize={"compact"}
				welcomeScreen={{}}
				modalTitleIconUrl={""}
				termsOfServiceUrl={"/terms-of-service"}
				privacyPolicyUrl={"/privacy-policy"}
				auth={{
					loginOptional,
					onLogout: () => {
						if (!location.pathname.startsWith(PATH.SIGN_LOCKS))
							history.push(PATH.HOME)
					}
				}}
			/>
		</ConnectWalletWrap>
	);
};

export default ConnectWalletThirdWeb;
const ConnectWalletWrap = styled.div<{fullWidth: boolean | undefined}>`
	& > button:first-of-type{
		${({ fullWidth }) =>
			fullWidth &&
			css`
      	width: 100%;
    `};
		padding: 8px 24px;
		border-radius: 28px;
		${({theme}) => theme.text2024};
		letter-spacing: 2px;
		&:hover{
			background: ${({ theme }) => theme.bgPrimary};
			color: ${({ theme }) => theme.white};
			.tw-connected-wallet{
				&__address,&__balance{
					color: ${({ theme }) => theme.white};
				}
			}
		}
		&.tw-connected-wallet{
			min-width: auto;
			img{
				width: 24px !important;
				height: 24px !important;
        & + div{
					gap: 0 !important;
        }
			}
		}
	}
`
