import { AppState, useAppDispatch, useAppSelector } from 'state/store';
import {updatePublicKey} from "state/publicKeyState";
import {useCallback} from "react";

export const usePublicKey = () => {
	const dispatch = useAppDispatch()
	const { publicKey} = useAppSelector((state:AppState) => {
		return state.publicKeySlice
	})
	const updatePublicKeyFnc = useCallback((publicKey:string|undefined) => {
		dispatch(updatePublicKey({publicKey}))
	},[dispatch])
	return {
		publicKey,
		updatePublicKeyFnc
	}
}
