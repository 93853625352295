import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';
import { motion,AnimatePresence } from "framer-motion";
import {ModalTypeBase} from "components/common/Modal/ModalType";
interface PropsTypeModal extends ModalTypeBase{
  children: React.ReactNode,
  name:string,
}
const Modal = ({ onDismiss, children,name,isOpen=false }: PropsTypeModal) => {
  const refModal = useRef<HTMLDivElement | null>(null)

  const handleDismiss = () => {
    document.body.style.overflow = 'unset'
    onDismiss()
  }
  useOnClickOutside(refModal, handleDismiss)

  useEffect(() => {
    if(isOpen){
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  },[isOpen])
  return ReactDOM.createPortal(
    <>
      {
        isOpen && <AnimatePresence>
          <motion.div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 13,
              height: "100vh"
            }}
            key={`modal-${name}`}
            initial={{
              opacity: 0,
              scale: 0.75,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                ease: "easeOut",
                duration: 0.15,
              },
            }}
            exit={{
              opacity: 0,
              scale: 0.75,
              transition: {
                ease: "easeIn",
                duration: 0.25,
              },
            }}>
              <ModalWrapper>
                <ModalContent ref={refModal}>
                  {children}
                </ModalContent>
              </ModalWrapper>
          </motion.div>
        </AnimatePresence>
      }
    </>,
    document.body
  )
}
export default Modal
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.bgColor1};
  backdrop-filter: blur(14px);
`
const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.primaryColor1};
  box-shadow: ${({ theme }) => theme.boxShadow5};
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 40px 20px;
`
