import React, {useEffect} from 'react';
import {LayoutAdmin} from "components/layout";
import styled from "styled-components";
import {Text3236} from "components/common/Typography";
import {useGetReportManagementQuery} from "state/reportManagement";
import LockReportTable from "pages/Admin/Report/LockReportTable";
import ProfileReportTable from "pages/Admin/Report/ProfileReportTable";
import {useTranslation} from "react-i18next";

const Report = () => {
	const {data} = useGetReportManagementQuery()
	const {t} = useTranslation()
	useEffect(() => {
		document.title = 'Report || Admin Panel || The Love Bridge'
	}, [])
	return (
		<LayoutAdmin>
			<Section>
				<TitleSection>{t('admin.report.lock')}</TitleSection>
				<SectionContent>
					{
						data && <LockReportTable data={data.reportedLocks}/>
					}
				</SectionContent>
			</Section>
			<Section>
				<TitleSection>{t('admin.report.user')}</TitleSection>
				<SectionContent>
					{
						data && <ProfileReportTable data={data.reportedProfiles}/>
					}
				</SectionContent>
			</Section>
		</LayoutAdmin>
	);
};

export default Report;
const Section = styled.section``
const TitleSection = styled(Text3236)`
	${({theme}) => theme.textBaseGradient};
`
const SectionContent = styled.div`
	margin-top: 20px;
	gap: 20px;
  background: ${({ theme }) => theme.primaryColor2};
  box-shadow: ${({ theme }) => theme.boxShadow3};
  backdrop-filter: blur(10px);
	padding: 20px;
	border-radius: 20px;
`