import React from 'react';
import { LayoutSecondary } from 'components/layout';
import { Text100120 } from 'components/common/Typography';
import styled from 'styled-components';

export default function PageNotFound() {
  return (
    <LayoutSecondary>
      <Message>Page not found!</Message>
    </LayoutSecondary>
  )
}
const Message = styled(Text100120)``
