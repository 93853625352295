export enum ProfileStatus {
    ACTIVE = 'ACTIVE',
    BANNED_MANUAL = 'BANNED_MANUAL',
    BANNED_AUTO = 'BANNED_AUTO'
}

export enum ProfileRoles {
    ADMIN = 'ADMIN',
    NORMAL = 'NORMAL'
}

export const bannedStatus = [
    ProfileStatus.BANNED_MANUAL,
    ProfileStatus.BANNED_AUTO
];

export interface Profile {
    userAddress: string;
    email?: string;
    name?: string;
    isSubscribed?: boolean;
    role: ProfileRoles;
    status: ProfileStatus;
    createdAt: number;
    isOwner?: number;
    expirationTime?: string;
    reportsCount: number;

    data?: {
        userAddress: string;
        email?: string;
        role: ProfileRoles;
        status: ProfileStatus;
        createdAt: number;
    }
}
