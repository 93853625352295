import styled from 'styled-components';

import React, { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { OptionType } from '../SelectSingleCs/SelectType';
interface PropsTypeSelectSingleCs {
  options: OptionType[]
  initialSelected?: OptionType | undefined | null
  onChoose?: (nameField: string, option: OptionType) => void
  nameField?: string
  classCs?: string
  isDisabled?:boolean | undefined
  isSearchable?:boolean
}
const SelectSingleCs = ({ options, initialSelected, onChoose, classCs = '', nameField,isDisabled=false,isSearchable=false }: PropsTypeSelectSingleCs) => {
  const [selectedOptions, setSelectedOptions] = useState<SingleValue<OptionType>>(() =>
    initialSelected ? initialSelected : options[0]
  )
  const handleChangeSelectedOption = (option: SingleValue<OptionType>) => {
    setSelectedOptions(option as OptionType)
    if (onChoose && nameField) {
      onChoose(nameField, option as OptionType)
    }
  }
  return (
    <SelectSingleBox>
      <Select
        classNamePrefix={`select-cs2-pre`}
        className={`select-cs2 ${classCs}`}
        onChange={handleChangeSelectedOption}
        defaultValue={initialSelected ? initialSelected : options[0]}
        value={options ? options.find((option) => option.value === initialSelected?.value) : selectedOptions}
        isSearchable={isSearchable}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        options={options}
        isDisabled={isDisabled}
      />
    </SelectSingleBox>
  )
}

export default SelectSingleCs
export const SelectSingleBox = styled.div`
  .select-cs2 {
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.primaryColor1};
      width: 4px;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.primaryColor1};
      width: 4px;
      border-radius: 4px;
    }
  }
  .select-cs2-pre {
    &__placeholder{
      ${({ theme }) => theme.text1624};
    }
    &__input-container {
      margin: 0;
      font-family: ${({ theme }) => theme.fontArial};
      font-weight: 700;
      color: ${({ theme }) => theme.primaryColor8};
      ${({ theme }) => theme.text1624};
      padding-top: 0;
      padding-bottom: 0;
    }
    &__option {
      padding: 6px 16px;
      color: ${({ theme }) => theme.primaryColor8};
      &:active {
        background-color: transparent;
      }
    }
    &__menu {
      box-shadow: none;
      margin: 10px 0 0 0;
      min-width: 188px;
      right: 0;
    }
    &__menu-list {
      border-radius: 8px;
      background-color: ${({ theme }) => theme.white};
      box-shadow: ${({ theme }) => theme.boxShadow1};
    }
    &__control {
      cursor: pointer !important;
      border: none;
      box-shadow: none;
      min-height: 48px;
      height: 48px;
      border-radius: 12px;
      padding: 12px 16px;
      &--is-focused {
        box-shadow: none;
      }
    }

    &__value-container {
      display: flex !important;
      padding-left: 4px;
      padding-right: 4px;
      &--has-value {
        color: ${({ theme }) => theme.primaryColor1};
        padding: 0;
      }
    }

    &__clear-indicator {
      display: none !important;
    }

    &__single-value {
      font-family: ${({ theme }) => theme.fontArial};
      font-weight: 700;
      color: ${({ theme }) => theme.primaryColor8};
      ${({ theme }) => theme.text1624};
      input {
        display: none;
      }
    }
    &__option {
      font-family: ${({ theme }) => theme.fontArial};
      color: ${({ theme }) => theme.primaryColor8};
      background-color: transparent;
      ${({ theme }) => theme.text1618};
      cursor: pointer;
    }
    &__option--is-focused {
      background-color: ${({ theme }) => theme.primaryColor2};
    }
    &__option--is-selected {
      background-color: ${({ theme }) => theme.primaryColor2};
      color: ${({ theme }) => theme.primaryColor8};
    }
    &__indicator-separator {
      display: none;
    }
    &__dropdown-indicator {
      padding: 4px;
      color:${({theme}) => theme.primaryColor8};
    }
  }
  .select-cs3 {
    .select-cs2-pre__control {
      background-color: transparent;
    }
    .select-cs2-pre {
      &__single-value {
        font-family: inherit;
        ${({ theme }) => theme.text3236};
      }
      &__option {
        font-family: inherit;
        ${({ theme }) => theme.text2024};
        letter-spacing: 1px;
      }
      &__dropdown-indicator {
        color:${({theme}) => theme.white};
      }
    }
  }
`
