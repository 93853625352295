import styled, {css} from 'styled-components'
export const FormDatePicker = styled.div<{secondary?:boolean}>`
  position: relative;
  .react-datepicker {
    font-family: ${({ theme }) => theme.fontArial};
    border: none;
    ${({ theme }) => theme.boxShadow1};
    border-radius: 12px;
    font-size: ${({ theme }) => theme.text1624};
    background: ${({ theme }) => theme.bgPrimary};
    &__header {
      background-color: transparent;
    }
    &__header__dropdown{
      *{
        font-family: ${({theme}) => theme.fontArial};  
      }  
    }  
    &__input-container {
      & >input {
        font-family: ${({ theme }) => theme.fontArial};
        ${({ theme }) => theme.text1624};
        padding: 12px 40px 12px 16px;
        color: ${({ theme }) => theme.primaryColor8};
        background-color: ${({ theme,secondary }) => secondary ? theme.primaryColor1 : theme.primaryColor11};
        border: none;
        border-radius: 12px;
        width: 100%;
        outline: none;
        position: relative;
        ${({secondary}) =>
          secondary && css`
          height: 48px;
          text-align: left;  
        `};
        :disabled {
          color: grey;
        }
        &::-webkit-input-placeholder {
          color: ${({ theme }) => theme.primaryColor10};
        }

        &:-ms-input-placeholder {
          color: ${({ theme }) => theme.primaryColor10};
        }

        &::placeholder {
          color: ${({ theme }) => theme.primaryColor10};
        }
      }
    }
    &__day-name,
    &__day,
    &__time-name {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-family: ${({ theme }) => theme.fontArial};
      color: ${({ theme }) => theme.white};
      font-weight: bold;
    }

    &-time__header {
      text-transform: uppercase;
    }
    &__current-month,
    &-time__header,
    &-year-header {
      font-family: ${({ theme }) => theme.fontArial};
      color: ${({ theme }) => theme.white};
    }
    &__month-container {
      font-family: ${({ theme }) => theme.fontArial};
    }
    &__triangle {
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
    &__day:hover,
    &__month-text:hover,
    &__quarter-text:hover,
    &__year-text:hover {
      background-color: ${({ theme }) => theme.bgColor3};
    }
    &__day--keyboard-selected,
    &__month-text--keyboard-selected,
    &__quarter-text--keyboard-selected,
    &__year-text--keyboard-selected,
    &__day--selected {
      background-color: ${({ theme }) => theme.bgColor2};
    }
    &-popper[data-placement^='bottom'] {
      z-index: 99;
    }
    &__day--disabled,
    &__month-text--disabled,
    &__quarter-text--disabled,
    &__year-text--disabled {
      color: ${({ theme }) => theme.black};
    }
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: ${({ theme }) => theme.primaryColor12};
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .date-picker-icon{
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);  
  }  
`
