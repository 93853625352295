import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'

export const indexerSlice = createApi({
  reducerPath: 'Indexer',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    confirmTx: build.mutation({
      query: (transactionHash: string) => {
        return {
          url: `indexer/confirmTx`,
          method: 'POST',
          body: { transactionHash }
        }
      }
    })
  })
})
export const { useConfirmTxMutation } = indexerSlice
