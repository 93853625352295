import React, {useEffect, useMemo, useState} from 'react';
import {LayoutAdmin} from "components/layout";
import styled from "styled-components";
import {Text3236} from "components/common/Typography";
import CurrenciesTable from "pages/Admin/Withdrawals/CurrenciesTable";
import {formatEther, formatUnits} from "@ethersproject/units";
import {useSaleInformation} from "hooks/useSaleInformation";
import { Alchemy } from 'alchemy-sdk';
import {getChainFromString} from "@thelovebridge/core/src/constants/NetworkConstants";
import {getAlchemyNetwork} from "@thelovebridge/core/src/utils/AlchemyUtils";
import {IMAGES} from "assets/images";
import {OptionType} from "components/common/SelectSingleCs/SelectType";
import {useTranslation} from "react-i18next";
import { useBalanceForAddress } from '@thirdweb-dev/react'

const iconCurrency = [
	{ name: 'ETH', icon: IMAGES.ICON_ETH},
	{ name: 'USDT', icon: IMAGES.ICON_USDT },
	{ name: 'USD', icon: IMAGES.ICON_USD },
]
const Withdrawals = () => {
	const {t} = useTranslation()
	const balance = useBalanceForAddress(String(process.env.REACT_APP_TLB_NFT_CONTRACT_ADDRESS))
	const [balanceETH,setBalanceETH] = useState('0')
	const [balancesERC,setBalancesERC] = useState<any[]>([])
	const {currencies} = useSaleInformation()
	const dataTable = useMemo(() => {
		if(!currencies){
			return
		}
		return currencies.map((item:OptionType) =>{
			const indexErc = balancesERC.findIndex((itemErc) => itemErc.contractAddress === item.value)
			const itemErc:any = balancesERC[indexErc]
			const {label,value} = item
			const balance = itemErc ?  formatUnits(itemErc.tokenBalance, 6): '0'
			const indexCurrency = iconCurrency.findIndex(item => item.name.toLowerCase() === label.toLowerCase())
			if(label === 'ETH'){
				return{
					name: 'ETH',
					contractAddress: '',
					balance: balanceETH,
					icon:IMAGES.ICON_ETH
				}
			}
			return {
				name: label,
				contractAddress: value,
				balance,
				icon: indexCurrency > -1 ? iconCurrency[indexCurrency].icon : ''
			}
		})
	},[balanceETH,currencies,balancesERC])
	useEffect(() => {
		document.title = 'Withdrawals || Admin Panel || The Love Bridge'
	}, [])
	useEffect(( ) => {
		if(!balance.data?.value)
			return

		;(async ()=> {
			const formatETH = formatEther(balance.data.value.sub(balance.data.value.mod(1e13)))
			setBalanceETH(formatETH)
		})()
	},[balance])
	useEffect(() => {
		if(!currencies || currencies.length === 0){
			return
		}
		;(async () =>{
			const network = getAlchemyNetwork(getChainFromString(String(process.env.REACT_APP_CHAIN_ID)));
			const alchemy = new Alchemy({apiKey: String(process.env.REACT_APP_ALCHEMY_KEY), network});
			const currenciesNotEth = currencies.filter((currency:OptionType) => currency.value !== '' && currency.value !== 'card')
			if(currenciesNotEth.length > 0){
				const balancesERC = await alchemy.core.getTokenBalances(String(process.env.REACT_APP_TLB_NFT_CONTRACT_ADDRESS), [...currenciesNotEth].map((cu) => cu.value));
				setBalancesERC(balancesERC.tokenBalances)
			}
		})()
	},[currencies])
	return (
		<LayoutAdmin>
			<Section>
				<TitleSection>{t('admin.withdrawals.title')}</TitleSection>
				<SectionContent>
					<CurrenciesTable dataTable={dataTable}/>
				</SectionContent>
			</Section>
		</LayoutAdmin>
	);
};

export default Withdrawals;
const Section = styled.section``
const TitleSection = styled(Text3236)`
	${({theme}) => theme.textBaseGradient};
`
const SectionContent = styled.div`
	margin-top: 20px;
	gap: 20px;
  background: ${({ theme }) => theme.primaryColor2};
  box-shadow: ${({ theme }) => theme.boxShadow3};
  backdrop-filter: blur(10px);
	padding: 20px;
	border-radius: 20px;
`
