import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import {Lock} from "@thelovebridge/core/src/entities/Lock";
export const commonSlice = createApi({
	tagTypes: ['Common'],
	reducerPath: 'Common',
	baseQuery: fetchBaseQuery(defaultParameters),
	endpoints: (build) => ({
		searchLock: build.query<Lock[],string>({
			query: (keyword:string) => {
				return {
					url: `search/${keyword}`,
				}
			},
			providesTags: ['Common']
		})
	})
})
export const { useSearchLockQuery } = commonSlice
