import React, {useMemo} from 'react';
import {useGetSubscriptionsQuery} from "state/usersManagementState";
import Table from "rc-table";
import BoxNoData from "components/common/BoxNoData";
import {TableWrap} from "components/common/TableWrap";
import moment from "moment";

const TableSubscriptions = () => {
	const {data} = useGetSubscriptionsQuery()
	const columns = useMemo(() => {
		return [
			{
				title:'Created At',
				dataIndex: 'createdAt',
				key:'createdAt',
				width: 100,
				render(value:number){
					return moment.unix(value).format("DD-MM-YYYY")
				}
			},
			{
				title:'Name',
				dataIndex: 'name',
				key:'name',
				width: 200
			},
			{
				title:'Email',
				dataIndex: 'email',
				key:'email',
				width: 200
			},
		]
	},[])
	return (
		<TableWrap>
			<Table
				columns={columns} data={data as any ? data : []} rowKey={(record:any,index:number|undefined) => {
				return index ? index : Math.random()
			}} sticky={true}
				className={'rc-sticky-cs'}
				emptyText={() => <BoxNoData />}/>
		</TableWrap>
	);
};

export default TableSubscriptions;