import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import { prepareFormData } from 'utils/prepare-format-data'

export const mediaSlice = createApi({
	tagTypes: ['Media'],
	reducerPath: 'Media',
	baseQuery: fetchBaseQuery(defaultParameters),
	endpoints: (build) => ({
		getUrlMedia: build.query<void,string>({
			query: (name:string) => {
				return {
					url: 'upload',
					params: {
						name
					}
				}
			},
		}),
		uploadAsset: build.mutation<string, File>({
			query: (file: File) => {
				return {
					url: 'ipfs/asset',
					method: 'POST',
					body: prepareFormData({ name: file.name, description: '',files: [file] })
				}
			},
		}),
		deleteAsset: build.mutation({
			query:(cid:string) => {
				return {
					url: `ipfs/asset/${cid}`,
					method: 'DELETE'
				}
			}
		})
	})
})
export const {
	useLazyGetUrlMediaQuery,
	useUploadAssetMutation,
	useDeleteAssetMutation
} = mediaSlice
