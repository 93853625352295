import {LockType} from "@thelovebridge/core/src/constants/LockType";

export enum BoxStType{
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}
export interface IContentProduct{
  content_type: LockType,
  content_id: string | string[],
  content_name: string,
  num_items: number,
  content_group_id: null
}