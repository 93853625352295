import React, { useState } from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import { Text100120, Text2024 } from '../Typography'
import { ButtonStPrimary, ButtonStSecondary } from '../ButtonSt';
import { ModalTypeBase } from './ModalType';
import Loader from '../Loader';
import { useDeleteGiftMutation } from 'state/giftsState'
import {toast} from "react-toastify";
import {ToastError, ToastSuccess} from "components/common/Toasts";
import {useTranslation} from "react-i18next";

interface PropsTypeModalDeleteGift extends ModalTypeBase {
  idToDelete?: number | undefined
}

export const ModalDeleteGift = ({ idToDelete, onDismiss,isOpen }: PropsTypeModalDeleteGift) => {
  const [deleteGift] = useDeleteGiftMutation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const {t} = useTranslation()
  const handleDelete = async (id?: number) => {
    setIsSubmitting(true)
    try {
      if (!id) return
      const response = await deleteGift(id)
      setIsSubmitting(false)

      if ((response as any).error) {
        toast.error(<ToastSuccess>Error removing gift</ToastSuccess>, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000
        })
      } else {
        toast.success(<ToastSuccess>Remove Gift Success</ToastSuccess>, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000
        })
      }
      onDismiss()
    } catch (e) {
      console.log('Remove Gift Error', e)
      toast.error(<ToastError>{t('modal.deleteGift.messageFailed')}</ToastError>, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      })
    }
  }

  return (
    <Modal onDismiss={onDismiss} name={'modal-delete-gift'} isOpen={isOpen}>
      <ModalContent>
        <ModalTitle>
          <Text100120>{t('modal.deleteGift.title')}</Text100120>
          <Text2024>{t('modal.deleteGift.subTitle')}</Text2024>
        </ModalTitle>
        <ModalBody>
          <GroupCta>
            <CtaSkip onClick={onDismiss} disabled={isSubmitting}>{t('button.skip')}</CtaSkip>
            <CtaContinue onClick={() => handleDelete(idToDelete)} disabled={isSubmitting}>
              {
                isSubmitting ? <>
                  <Loader />
                  {t('button.confirm')}
                </> : <>{t('button.confirm')}</>
              }
            </CtaContinue>
          </GroupCta>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  padding-left: 85px;
  padding-right: 85px;
  ${({ theme }) => theme.containerModal};
`
const ModalTitle = styled.div`
  ${({ theme }) => theme.flexColumnCenterHoz};
  margin-bottom: 40px;
  & > * {
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    & > h1 {
      font-size: ${({ theme }) => theme.fontSize60};
    }
  }
`
const ModalBody = styled.div`
  width: 100%;
`
const GroupCta = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const CtaSkip = styled(ButtonStPrimary)`
  padding: 8px 24px;
  letter-spacing: 2.4px;
`
const CtaContinue = styled(ButtonStSecondary)`
  padding: 8px 24px;
  letter-spacing: 2.4px;
  ${({theme}) => theme.flexRowCenter};
  gap: 10px;
`
