import React, {useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import {FormInputSec} from "components/common/FormInput";
import {ButtonStSecondary} from "components/common/ButtonSt";
import {Text2024, Text24Arial} from "components/common/Typography";
import {useNftContract} from "hooks/useContract";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useSaleInformation} from "hooks/useSaleInformation";
import Loader from "components/common/Loader";
import {ToastError, ToastSuccess} from "components/common/Toasts";
import {useEnsureNetwork} from "hooks/useEnsureNetwork";
import Table from "rc-table";
import BoxNoData from "components/common/BoxNoData";
import {TableWrap} from "components/common/TableWrap";
import {useTranslation} from "react-i18next";

const SectionChangePrice = () => {
	const {t} = useTranslation()
	const nftContract = useNftContract()
	const {currencies,refetch} = useSaleInformation()
	const [isRemoving,setIsRemoving] = useState<boolean>(false)
	const [addressRemove,setAddressRemove] = useState<string | undefined>(undefined)
	const { ensureNetwork, isExpectedNetwork } = useEnsureNetwork()
	const formikPrice = useFormik({
		initialValues: {
			addressCurrency: '',
		},
		validationSchema: Yup.object().shape({
			addressCurrency: Yup.string().required('New price is required'),
		}),
		onSubmit: async (values: FormikValues) => {
			if(!isExpectedNetwork){
				await ensureNetwork()
				return
			}else{
				if (!nftContract)
					return

				setSubmitting(true)
				try {
					const { addressCurrency } = values
					const tx = await nftContract.changePaymentCurrency(addressCurrency,true)
					await tx.wait(1)
					toast.success(<ToastSuccess>{t('admin.contract.currency.messageSuccess')}</ToastSuccess>, {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 2000
					})
					refetch()
				} catch (e) {
					toast.error(<ToastError>{t('admin.contract.currency.messageFailed')}</ToastError>, {
						position: toast.POSITION.BOTTOM_RIGHT,
					})
				} finally {
					resetForm()
					setSubmitting(false)
				}
			}
		}
	})
	const { setSubmitting,handleChange,values,isSubmitting,isValid,dirty,handleSubmit,resetForm } = formikPrice
	const handleRemoveCurrency = useCallback((address:string) => {
		if(!nftContract){
			return
		}
		;(async () => {
			setAddressRemove(address)
			setIsRemoving(true)
			try{
				const tx = await nftContract.changePaymentCurrency(address,false)
				await tx.wait(1)
				toast.success(<ToastSuccess>{t('admin.contract.currency.removeSuccess')}</ToastSuccess>, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000
				})
				refetch()
			}catch {
				toast.error(<ToastError>{t('admin.contract.currency.removeFailed')}</ToastError>, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000
				})
			}finally {
				setIsRemoving(false)
				setAddressRemove('')
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[nftContract,refetch])
	const columns = useMemo(() => {
		return [
			{
				title:'Currency',
				dataIndex: 'label',
				key:'label',
				width: 100,
			},
			{
				title:'Contract Address',
				dataIndex: 'value',
				key:'value',
				width: 400
			},
			{
				title:'Actions',
				dataIndex: 'remove',
				key:'remove',
				width: 120,
				render(value:any,row:any){
					const {value:valueAddress} = row
					return valueAddress === '' ? <></> : <RemoveCurrency onClick={() => handleRemoveCurrency(valueAddress)} disabled={isRemoving}>
						{
							addressRemove === valueAddress ? <>
								<Loader width={10}/>
								{t('button.remove')}
							</> : <>{t('button.remove')}</>
						}
					</RemoveCurrency>
				}
			},
		]
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[handleRemoveCurrency,addressRemove,isRemoving])
	return (
		<SectionChangeCurrencyWrap>
			<FormPrice onSubmit={handleSubmit}>
				<NameField>{t('admin.contract.currency.form.add.label')}</NameField>
				<FormPriceChange>
					<FormInputSec id={'addressCurrency'} name={'addressCurrency'} placeholder={t('admin.contract.currency.form.add.placeholder')} value={values.addressCurrency ?? ''} onChange={handleChange}/>
					<ButtonChange type='submit' disabled={isSubmitting || !isValid || !dirty}>
						{
							isSubmitting ? <><Loader/> {t('admin.contract.currency.form.add.label')}</>: <>{t('admin.contract.currency.form.add.label')}</>
						}
					</ButtonChange>
				</FormPriceChange>
			</FormPrice>
			<ListCurrency>
				<TitleSub>{t('admin.contract.currency.list')}</TitleSub>
				{
					<TableWrap>
						{
							currencies &&	<Table
								columns={columns}
								data={currencies as any}
								rowKey="label"
								emptyText={() => <BoxNoData />}
								sticky={true}
                className={'rc-sticky-cs'}/>
						}
					</TableWrap>
				}
			</ListCurrency>
		</SectionChangeCurrencyWrap>
	);
};

export default SectionChangePrice;
const SectionChangeCurrencyWrap = styled.div``
const FormPriceChange = styled.div`
	${({theme}) => theme.flexRowCenterVertical};
	gap: 10px;
`
const NameField = styled(Text24Arial)`
	font-size: ${({theme}) => theme.fontSize16};
	line-height: 1.25;
	font-family: ${({theme}) => theme.fontArial};
	color: ${({theme}) => theme.primaryColor8};
	margin-bottom: 10px;
	font-weight: 700;
	letter-spacing: -0.5px;
`

const FormPrice = styled.form`
	width: 100%;
`
const ButtonChange = styled(ButtonStSecondary)`
	padding: 8px 24px;
	flex: 0 0 180px;
	${({theme}) => theme.text1620};
	letter-spacing: 0.8px;
	${({theme}) => theme.flexRowCenter};
	gap: 10px;
  &:hover{
    background-color: ${({theme}) => theme.primaryColor2};
  }
`
const ListCurrency = styled.ul`
	margin-top: 20px;
	border-radius: 12px;
	list-style: none;
`
const TitleSub = styled(Text2024)`
	letter-spacing: 0.5px;
	text-transform: uppercase;
`
const RemoveCurrency = styled(ButtonStSecondary)`
	${({theme}) => theme.text1620};
	line-height: 1;
	padding: 8px 12px;
	flex: 0 0 100px;
	max-width: 100px;
	border-radius: 10px;
	letter-spacing: 1px;
	margin-left: auto;
	${({theme}) => theme.flexRowCenter};
	gap: 10px;
	&:hover{
		background-color: ${({theme}) => theme.primaryColor2};
	}
	&:disabled{
		&::before{
      border-radius: 10px;
    }
	}
`
