import React, {  useState } from 'react'
import Modal from './Modal'
import styled from 'styled-components'
import { Text100120, Text2024 } from '../Typography'
import { ButtonStPrimary, ButtonStSecondary } from '../ButtonSt'
import { ModalTypeBase } from './ModalType'
import Loader from '../Loader'

import {isAddress, shortenAddress} from 'utils/address'
import { useEnsureNetwork } from 'hooks/useEnsureNetwork'
import { useNftContract } from 'hooks/useContract'
import {toast} from "react-toastify";
import {ToastError} from "components/common/Toasts";
import {useTranslation} from "react-i18next";
import { useConfirmTxMutation } from 'state/indexerState'

interface PropsTypeModalTransferOwnership extends ModalTypeBase {
  userAddress: string | null
}

export const ModalTransferOwnership = ({ userAddress, onDismiss,isOpen }: PropsTypeModalTransferOwnership) => {
  const {t} = useTranslation()
  const [confirmTx] = useConfirmTxMutation()
  const { ensureNetwork, isExpectedNetwork } = useEnsureNetwork()
  const nftContract = useNftContract()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleConfirm = async () => {
    if (!nftContract || !isAddress(userAddress) || !userAddress) return
    setIsSubmitting(true)
    try {
      if (!isExpectedNetwork) {
        await ensureNetwork()
        setIsSubmitting(false)
      } else {
        const tx = await nftContract.transferOwnership(userAddress)
        const txReceipt = await tx.wait(1)
        await confirmTx(txReceipt.transactionHash)
        onDismiss()
      }
    } catch (e) {
      console.log('Change user Status Error', e)
      toast.error(<ToastError>{t('modal.transferOwner.messageFailed')}</ToastError>, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      })
    }finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal onDismiss={onDismiss} name={'modal-transfer-ownership'} isOpen={isOpen}>
      <ModalContent>
        <ModalTitle>
          <Text100120>{t('modal.transferOwner.title')}</Text100120>
          <Text2024>{t('modal.transferOwner.subTitle')} <strong>{userAddress && isAddress(userAddress) && shortenAddress(userAddress)}</strong></Text2024>
        </ModalTitle>
        <ModalBody>
          <GroupCta>
            <CtaSkip onClick={onDismiss} disabled={isSubmitting}>{t('button.skip')}</CtaSkip>
            <CtaContinue onClick={isExpectedNetwork ? handleConfirm : ensureNetwork} disabled={isSubmitting}>
              {isSubmitting && <Loader />}
              {!isExpectedNetwork ? isSubmitting ? <>{t('button.changingNetwork')}</> : <>{t('button.changeNetwork')}</> : isSubmitting ? <>{t('button.confirming')}</> : <>{t('button.confirm')}</> }
            </CtaContinue>
          </GroupCta>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  padding-left: 85px;
  padding-right: 85px;
  ${({ theme }) => theme.containerModal};
`
const ModalTitle = styled.div`
  ${({ theme }) => theme.flexColumnCenterHoz};
  margin-bottom: 40px;
  & > * {
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    & > h1 {
      font-size: ${({ theme }) => theme.fontSize60};
    }
  }
`
const ModalBody = styled.div`
  width: 100%;
`
const GroupCta = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const CtaSkip = styled(ButtonStPrimary)`
  padding: 8px 24px;
  letter-spacing: 2.4px;
`
const CtaContinue = styled(ButtonStSecondary)`
  padding: 8px 24px;
  letter-spacing: 2.4px;
  ${({theme}) => theme.flexRowCenter};
  gap: 10px;
`
