import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {LayoutAdmin} from "components/layout";
import styled from "styled-components";
import {Text24Arial, Text3236} from "components/common/Typography";
import {lockManagementApi, useHideMutation, useListLocksQuery, useUnHideMutation} from "state/lockManagementApi";
import {TableWrap} from "components/common/TableWrap";
import BoxNoData from "components/common/BoxNoData";
import Table from "rc-table";
import {ButtonStPrimary} from "components/common/ButtonSt";
import {Lock} from "@thelovebridge/core/src/entities/Lock"
import {toast} from "react-toastify";
import {ToastError, ToastSuccess} from "components/common/Toasts";
import Loader from "components/common/Loader";
import {useAppDispatch} from "state/store";
import {shortenAddress} from 'utils/address'
import moment from "moment";
import {OptionType} from "components/common/SelectSingleCs/SelectType";
import SelectSingleCs from "components/common/SelectSingleCs";
import {TypeSignNotEmpty, VISIBILITY_LOCK} from "constants/optionsSelect";
import {gallerySlice} from "state/galleryState";
import {FormInputSec} from "components/common/FormInput";
import {useDebounce} from "usehooks-ts";
import {PaginationSt,TextDbNextPrev} from "components/common/PaginationSt";
import Pagination from 'rc-pagination'
import {useTranslation} from "react-i18next";
import { LockType } from '@thelovebridge/core/src/constants/LockType'
const enum LockDisplay{
	HIDE='HIDE',
	UN_HIDE = 'UN_HIDE'
}
const PAGE_SIZE = 8
const Locks = () => {
	const {t} = useTranslation()
	const dispatch = useAppDispatch()
	const {data,isLoading} = useListLocksQuery()
	const [hide] = useHideMutation()
	const [unHide] = useUnHideMutation()
	const [isDisplay,setIsDisplay] = useState<boolean>(false)
	const [idLockChoose,setIdLockChoose] = useState<string|undefined>(undefined)
	const [optionVisibility,setOptionVisibility] = useState<OptionType>(VISIBILITY_LOCK[0])
	const [optionTypeLock,setOptionTypeLock] = useState<OptionType>(TypeSignNotEmpty[0])
	const onHandleDisplayLock = useCallback((id:string|undefined,type:LockDisplay) => {
		if(!id){
			return
		}
		;(async () => {
			setIsDisplay(true)
			try{
				const responseDisplay = type === LockDisplay.HIDE ? await hide(id) : await unHide(id)
				if('data' in responseDisplay){
					toast.success(<ToastSuccess>{type === LockDisplay.HIDE ? 'Hide' : 'Unhide'} lock success</ToastSuccess>, {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 2000
					})
					dispatch(lockManagementApi.util.invalidateTags(['LockAdmin']))
					dispatch(gallerySlice.util.invalidateTags(['Gallery']))
				}
			}catch {
				toast.error(<ToastError>{type === LockDisplay.HIDE ? 'Hide' : 'Unhide'} lock failed</ToastError>, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 2000
				})
			}finally {
				setIdLockChoose(undefined)
				setIsDisplay(false)
			}
		})()
	},[hide,unHide,dispatch])
	const [allLock,setAllLock] = useState<Lock[]>([])
	const [dataTable,setDataTable] = useState<Lock[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [searchTerm,setSearchTerm] = useState<string>('')
	const debounceTerm = useDebounce(searchTerm.trim(),800)
	const onChooseSelect = (nameField:string,option:OptionType) => {
		switch (nameField){
			case 'visibilityLock':
				setOptionVisibility(option)
				break;
			default:
				setOptionTypeLock(option)
				break;
		}
	}
	const columns = useMemo(() => {
		return [
			{
				title:'NftID',
				dataIndex: 'nftId',
				key:'nftId',
				width: 60
			},
			{
				title:'Lock Name',
				dataIndex: 'name',
				key:'name',
				width: 200
			},
			{
				title:'Type',
				dataIndex: 'type',
				key:'type',
				width: 100,
				render(value: LockType) {
					return <>{value === LockType.COUPLE ? 'DUAL' : value}</>
				}
			},
			{
				title:'Visibility',
				dataIndex: 'visibility',
				key:'visibility',
				width: 100
			},
			{
				title:'Reports',
				dataIndex: 'reportsCount',
				key:'reportsCount',
				width: 80,
				align:'center' as 'center',
			},
			{
				title:'User Address',
				dataIndex: 'userAddress',
				key:'userAddress',
				width: 130,
				render(value:string){
					return `${shortenAddress(value.toLowerCase())}`
				}
			},
			{
				title:'Partner Address',
				dataIndex: 'partnerAddress',
				key:'partnerAddress',
				width: 150,
				render(value:string,row:Lock){
					if ('metadata' in row) {
						const { type } = row
						return type === LockType.COUPLE ? `${shortenAddress(value.toLowerCase())}` : ''
					} else return ''
				}
			},
			{
				title:'Create At',
				dataIndex: 'createdAt',
				key:'createdAt',
				width: 110,
				render(value:number){
					return moment.unix(value).format("DD-MM-YYYY")
				}
			},
			{
				title:'Actions',
				dataIndex: 'actions',
				key:'actions',
				width: 120,
				render(value:undefined,row:Lock){
					const {nftId,isHidden} = row
					return <CtaGroup>
						{
							isHidden ? <CtaUnHide onClick={() => {
								setIdLockChoose(nftId)
								onHandleDisplayLock(nftId, LockDisplay.UN_HIDE)
							}} disabled={isDisplay}>
								{
									isDisplay && idLockChoose === nftId ? <>
										<Loader width={16} secondary={true}/> UnHide
									</>: 'UnHide'
								}
							</CtaUnHide>: <CtaHide onClick={() => {
							setIdLockChoose(nftId)
							onHandleDisplayLock(nftId, LockDisplay.HIDE)
						}} disabled={isDisplay}>
						{
							isDisplay && idLockChoose === nftId ? <>
							<Loader width={16} secondary={true}/> Hide
							</>: 'Hide'
						}
							</CtaHide>
						}
					</CtaGroup>
				}
			},
		]
	},[onHandleDisplayLock,isDisplay,idLockChoose])

	const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value)
	}
	const onChangePagination = (current:number) =>{
		setCurrentPage(current)
	}
	const onApplyFilter = useCallback(() => {
		if(!data || data.length === 0){
			return
		}
		let allLockFilter = []
		allLockFilter = data.filter((item:Lock) => {
			return (item.visibility === optionVisibility.value) || optionVisibility.value === 'ALL'
		}).filter((item:Lock) => {
			return (item.type === optionTypeLock.value) || optionTypeLock.value === 'ALL'
		})
		if(debounceTerm !== ''){
			allLockFilter = allLockFilter.filter((item:Lock) => {
				return item.userAddress.includes(debounceTerm) || item.partnerAddress?.includes(debounceTerm) || (item.name?.includes(debounceTerm))
			})
		}
		const dataLockTable = allLockFilter.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
		setAllLock(allLockFilter)
		setDataTable(dataLockTable)
	},[data,optionVisibility.value,optionTypeLock.value,debounceTerm,currentPage])
	const onClearFilter = () => {
		if(!data){
			return
		}
		setOptionVisibility(VISIBILITY_LOCK[0])
		setOptionTypeLock(TypeSignNotEmpty[0])
		setSearchTerm('')
	}
	useEffect(() => {
		document.title = 'Locks || Admin Panel || The Love Bridge'
	}, [])
	useEffect(() => {
		if(isLoading){
			return
		}
		onApplyFilter()
	},[isLoading,onApplyFilter])
	useEffect(() => {
		setCurrentPage(1)
	},[optionVisibility.value,optionTypeLock.value,debounceTerm])
	return (
		<LayoutAdmin>
			<Section>
				<TitleSection>{t('admin.locks.title')}</TitleSection>
				<Filters>
					<FilterInput>
						<FormInputSec placeholder={t('admin.locks.form.search.placeholder')} onChange={onSearch} value={searchTerm}/>
					</FilterInput>
					<FilterSelect>
						<FilterSelectName>{t('admin.locks.form.visibility.label')}:</FilterSelectName>
						<SelectSingleCs options={VISIBILITY_LOCK} initialSelected={optionVisibility} nameField={'visibilityLock'} onChoose={onChooseSelect}/>
					</FilterSelect>
					<FilterSelect>
						<FilterSelectName>{t('admin.locks.form.type.label')}:</FilterSelectName>
						<SelectSingleCs options={TypeSignNotEmpty} initialSelected={optionTypeLock} nameField={'typeLock'} onChoose={onChooseSelect}/>
					</FilterSelect>
					<CtaGroupFilter>
						<CtaClear onClick={onClearFilter}>{t('admin.locks.clearBtn')}</CtaClear>
					</CtaGroupFilter>
				</Filters>
				{
					dataTable && <SectionContent>
						<TableWrap>
							<Table
								columns={columns}
								data={dataTable as any}
								rowKey={'nftId'}
								sticky={true}
								className={'rc-sticky-cs'}
								emptyText={() => <BoxNoData />}
							/>
						</TableWrap>
						{
							allLock && allLock.length > PAGE_SIZE && <PaginationSt>
								<Pagination
									onChange={(current:number) => {
										onChangePagination(current)
									}}
									current={currentPage}
									pageSize={PAGE_SIZE}
									total={allLock.length}
									showTitle={false}
									prevIcon={() => (
										<TextDbNextPrev>
											Prev
										</TextDbNextPrev>
									)}
									nextIcon={() => (
										<TextDbNextPrev>
											Next
										</TextDbNextPrev>
									)}
								/>
							</PaginationSt>
						}
					</SectionContent>
				}
			</Section>
		</LayoutAdmin>
	);
};

export default Locks;
const Section = styled.section``
const TitleSection = styled(Text3236)`
	${({theme}) => theme.textBaseGradient};
`
const SectionContent = styled.div`
  margin-top: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.primaryColor2};
  box-shadow: ${({ theme }) => theme.boxShadow3};
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 20px;
`
const CtaGroup = styled.div`
  ${({theme}) => theme.flexColumn};
  gap: 4px;
`
const CtaHide = styled(ButtonStPrimary)`
  padding: 5px 0;
  ${({ theme }) => theme.text1618};
  letter-spacing: 0.8px;
`
const CtaUnHide = styled(CtaHide)``
const Filters = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	margin: 20px 0;
`
const FilterInput = styled.div`
	flex: 1 0 360px;
	align-self: flex-end;
`
const FilterSelect = styled.div`
	flex: 1 0 180px;
`
const FilterSelectName = styled(Text24Arial)`
	${({theme}) => theme.text2024};
	margin-bottom: 6px;
`
const CtaGroupFilter = styled.div`
	${({theme}) => theme.flexRowCenterVertical};
	gap: 4px;
`
const CtaClear = styled(ButtonStPrimary)`
	width: max-content;
	height: 40px;
	align-self: flex-end;
	padding-top: 4px;
	padding-bottom: 4px;
`
