import React, {useEffect, useState} from 'react';
import {LayoutPrimary} from "components/layout";
import styled, {css} from "styled-components";
import RowCs from "components/common/RowCs";
import ColumnCs from "components/common/ColumnCs";
import {BoxSt} from "components/common/BoxSt";
import {Text100120, Text2024} from "components/common/Typography";
import {IMAGES} from "assets/images";
import {useTranslation} from "react-i18next";
import withHelmet from "components/hoc/withHelmet";
import {BoxStType} from "models/common";

export interface RoadmapItem {
	time: string
	content: string
}
const Roadmap = () => {
	useEffect(() => {
		document.title = 'Roadmap || The Love Bridge'
	}, [])
	const {t} = useTranslation()
	const [numberIndexRoadmap,setNumberIndexRoadmap] = useState<number[]>([])
	useEffect(() => {
		const listIndex = Object.keys(t('roadMap', {returnObjects: true}))
		setNumberIndexRoadmap(() => {
			return listIndex.map((item:string) => Number(item))
		})
	},[t])
	return (
		<LayoutPrimary>
			<SectionRoadmap>
				<ForeignKey align={'leftTop'}>
					<img src={IMAGES.LOCK_FLY2} alt={'foreign_key'}/>
				</ForeignKey>
				<ForeignKey align={'leftBottom'} animationRight={true}>
					<img src={IMAGES.LOCK_FLY1} alt={'foreign_key'}/>
				</ForeignKey>
				<ForeignKey align={'rightTop'}>
					<img src={IMAGES.LOCK_FLY2} alt={'foreign_key'}/>
				</ForeignKey>
				<ForeignKey align={'rightBottom'} animationRight={true}>
					<img src={IMAGES.LOCK_FLY1} alt={'foreign_key'}/>
				</ForeignKey>
				<RoadmapBody>
					<RoadMapTitle>OUR ROADMAP</RoadMapTitle>
					{
						numberIndexRoadmap.length> 0 && numberIndexRoadmap.map((index:number,item,arr) => {
							const odd = index % 2 !== 0
							return <RowCs space={-10} key={index} className={odd ? 'row-right' : ''}>
								<ColumnCs gutter={10} sm={6} xs={12} className={'column'}>
									<ColumnRoad alignRight={odd}>
										<TextRoad>{t(`roadMap.${index}.time`)}</TextRoad>
										<Box size={BoxStType.LARGE}>
											<Text2024 dangerouslySetInnerHTML={{__html: t(`roadMap.${index}.content`)}}/>
										</Box>
									</ColumnRoad>
								</ColumnCs>
								<ColumnCs gutter={10} sm={6} xs={12} className={'column-down'}>
									{
										index !== arr.length - 1 && <ColumnImage alignLeft={odd}>
											<img src={odd ? IMAGES.DOWN_RP:IMAGES.DOWN_LP} alt={'down-lp'}/>
										</ColumnImage>
									}
								</ColumnCs>
							</RowCs>
						})
					}
				</RoadmapBody>
			</SectionRoadmap>
		</LayoutPrimary>
	);
};

export default withHelmet(Roadmap);
const SectionRoadmap = styled.section`
	padding: 100px 0;
	position: relative;
	@media only screen and (max-width: 991px){
		margin: 60px 0;
	}
	@media only screen and (max-width: 600px){
		.column-down{
			display: none;
		}
		.column{
			padding-top: 20px;
		}
	}
`
const RoadmapBody= styled.div`
  ${({theme}) => theme.container};
  max-width: 1000px;
	position: relative;
	z-index: 2;
  .row-right{
    & > .column-down{
      order:-1;
    }
  }
`
const RoadMapTitle = styled(Text100120)`
  text-align: center;
`
const ColumnRoad = styled.div<{alignRight?:boolean | undefined}>`
  ${({ alignRight }) =>
    alignRight &&
    css`
        padding-top: 40px;
	      @media only screen and (max-width: 600px){
		      padding-top: 0;
	      }
      `};
`
const TextRoad = styled(Text2024)`
	margin-bottom: 10px;
  @media only screen and (max-width: 600px){
    text-align: center;
  }
`
const Box = styled(BoxSt)`
	p{
		text-align: center;
	}
`
const ColumnImage = styled.div<{alignLeft?:boolean | undefined}>`
	img{
		padding-top: 60px;
		width: 145px;
    ${({ alignLeft }) =>
    alignLeft &&
    css`
		  margin-left: auto;
	    padding-top: 100px;
      `};
	}
`
const ForeignKey = styled.div<{ align: string,animationRight?:boolean }>`
  position: absolute;
  z-index: 1;
  max-width: min(70%, 180px);
  width: 100%;
  animation: ${({animationRight}) => (animationRight ? 'bounceOutUp' : 'bounceInDown')} 6s infinite alternate-reverse;
  ${({ align }) => {
    switch (align) {
      case 'leftBottom':
        return `
          bottom: 0;
          left: 20%;
          @media only screen and (max-width: 1440px){
             left: min(10%,50px);
          }
        `
      case 'rightBottom':
        return `
          bottom: 0;
          right: 20%;
          @media only screen and (max-width: 1440px){
           right: min(10%,50px);
          }
        `
      case 'rightTop':
        return `
          top: 0;
          right: 20%;
          @media only screen and (max-width: 1440px){
           right: min(10%,50px);
          }
        `
      default:
        return `
          top: 0;
          left: 20%;
          @media only screen and (max-width: 1440px){
             left: min(10%,50px);
          }
        `
    }
  }};
	
	@media only screen and (max-width: 767px){
		max-width: 120px;
	}
`
