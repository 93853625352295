import React, {useCallback, useEffect, useRef, useState} from 'react'
import styled,{css} from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import { IMAGES } from 'assets';
import { PATH } from 'config';
import { shortenAddress } from 'utils';
import useAuth from 'hooks/useAuth';
import ColumnCs from "components/common/ColumnCs";
import RowCs from "components/common/RowCs";
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import {GA_CONST} from "constants/googleAnalyticsConst";
import {useTranslation} from "react-i18next";
import { FormInputSec} from "components/common/FormInput";
import {useDebounce} from "usehooks-ts";
import {Lock} from "@thelovebridge/core/src/entities/Lock";
import {getUrlLock} from "utils/getUrlLock";
import {Text2024, Text2024Arial, Text2832} from "components/common/Typography";
import { LockVariation } from '@thelovebridge/core/src/constants/LockVariation'
import {useSearchLockQuery} from "state/commonState";
import Loader from "components/common/Loader";
import {useOnClickOutside} from "usehooks-ts";
import ConnectWalletThirdWeb from "components/common/ConnectWalletThirdWeb";

const Header = () => {
  const gaEventTrackerSign = useAnalyticsEventTracker(GA_CONST.SIGN_A_LOCK)
  const {t} = useTranslation()
  const history = useHistory()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const { isOwner,isAdmin,isBanned,address,isSigned } = useAuth()
  const [valueSearch,setValueSearch] = useState<string>('')
  const debouncedValueSearch = useDebounce(valueSearch.toLowerCase(),1000)
  const [focused,setFocused] = useState<boolean>(false)

  const refSearch = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(refSearch, () => setFocused(false))

  const {data:dataLock,isFetching} = useSearchLockQuery(debouncedValueSearch,{
    refetchOnMountOrArgChange:true,
    skip:false
  })
  const onSearch = (event:React.ChangeEvent<HTMLInputElement>) =>
    setValueSearch(event.target.value)

  const onCloseMenu = useCallback(() => {
    document.body.style.overflow = 'unset'
    setOpenMenu(false)
  },[])
  const onClickLogo = () =>{
    onCloseMenu()
    history.push('')
  }
  const handleRedirectItem = (path:string) => {
    onCloseMenu()
    if (path === 'official') {
      window.open('https://linktr.ee/lovebridgenft','_blank')
    } else {
      if (path === PATH.SIGN_LOCKS)
        gaEventTrackerSign(GA_CONST.GO_TO_SIGN_LOCK)
      history.push(path)
    }
  }
  const handleClickLock = (nftId: string | undefined) => {
    if (!nftId) return
    history.push(`/lock/${nftId}`)
    setFocused(false)
  }
  const handleClick = () => setFocused(true)

  useEffect(() => {
    if(openMenu){
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  },[openMenu])
  return (
    <SectionHeader>
      <HeaderBox>
        <Logo>
          <Link to={isBanned ? PATH.BANNED : PATH.HOME}>
            <img src={IMAGES.LOGO_SECONDARY} alt={'logo_bridge'} />
          </Link>
        </Logo>
        <HeaderRight isHide={isBanned}>
          <SearchElement ref={refSearch}>
            <SearchBox onClick={handleClick}>
              <FormSearch
                placeholder={t('header.search.placeholder')}
                id={'search'}
                onChange={onSearch}
                value={valueSearch}
                focused={focused}
              />
              <label htmlFor="search">
                <IMAGES.ICON_SEARCH />
              </label>
            </SearchBox>
            {
              dataLock && valueSearch !== '' && (
                <SearchResult show={focused}>
                  {
                    isFetching ? <BoxLoader><Loader secondary={true}/></BoxLoader> : <>
                      {
                        dataLock.length > 0 ? dataLock.map((lock:Lock) => {
                          const variationLockUrl = getUrlLock(lock.variation as LockVariation)
                          return <ItemResult key={lock.nftId} onClick={() => handleClickLock(lock.nftId)}>
                            <ResultImage>
                              <img src={variationLockUrl} alt={'variation-lock'}/>
                            </ResultImage>
                            <ResultInformation>
                              <UserName>
                                {'name' in lock ? lock.name : ''}
                              </UserName>
                              <UserAddress>
                                {shortenAddress(lock.userAddress)}
                              </UserAddress>
                            </ResultInformation>
                          </ItemResult>
                        }) : <TextNoMatch>No match</TextNoMatch>
                      }
                    </>
                  }
                </SearchResult>
              )
            }
          </SearchElement>
          <ConnectWalletThirdWeb />
          <MenuPlatformBackground $openMenu={openMenu}>
            <MenuPlatform $openMenu={openMenu}>
              <LogoMenu onClick={onClickLogo} className={'logo-menu'}>
                <img src={IMAGES.LOGO_FOOTER} alt={'log'} />
              </LogoMenu>

              <MenuPlatformBody $openMenu={openMenu}>
                <Menu>
                  <RowCs space={-20}>
                    {
                      Array.from(Array(2).keys()).map((index:number)=>{
                        const url = index === 0 ? PATH.SIGN_LOCKS : PATH.INVENTORY
                        if(index === 1 && !address) {
                          return <React.Fragment key={index}/>
                        }
                        return <ColumnCs gutter={20} md={!address ? 12 : 6} xs={12} key={index}>
                          <MenuItem className={index === 1 ? 'mt-20' :''}>
                            <div onClick={() =>handleRedirectItem(url)}>
                              <TextLink>{t(`header.menu.menuLogged.${index}.name`)}</TextLink>
                            </div>
                          </MenuItem>
                        </ColumnCs>
                      })
                    }
                  </RowCs>
                </Menu>
                <DividerMenu>
                  <img src={IMAGES.HEART} alt={'heart'}/>
                </DividerMenu>
                <MenuOutside>
                  {
                    Array.from(Array(6).keys()).map((index:number)=>{
                      const urls = [PATH.OUR_TEAM,PATH.ROAD_MAP,PATH.CONTACT_US,PATH.PUBLIC_KEY_PAGE,'official','/admin']
                      return <React.Fragment key={index}>
                        {
                          index !== 5 ? <MenuItem onClick={() => handleRedirectItem(urls[index])}>
                            <TextLink>{t(`header.menu.menuOutside.${index}.name`)}</TextLink>
                          </MenuItem> : <>
                            {
                              (isSigned && (isOwner || isAdmin)) ? <MenuItem onClick={() => handleRedirectItem('/admin')}>
                                <TextLink>{t(`header.menu.menuOutside.${index}.name`)}</TextLink>
                              </MenuItem> : <></>
                            }
                          </>
                        }
                      </React.Fragment>
                    })
                  }
                </MenuOutside>
                <List>
                  {
                    Array.from(Array(2).keys()).map((index:number)=>{
                      const url = index === 0 ? PATH.TERMS_OF_SERVICE : PATH.PRIVACY_POLICY
                      return <React.Fragment key={index}>
                        <li>
                          <Link to={url} onClick={()=>setOpenMenu(false)}>{t(`header.menu.policyAndTerms.${index}.name`)}</Link>
                        </li>
                        {index === 0 && <span>&</span>}
                      </React.Fragment>
                    })
                  }
                </List>
              </MenuPlatformBody>
            </MenuPlatform>
          </MenuPlatformBackground>
          <HamburgerBox
            onClick={() => {
              setOpenMenu(!openMenu)
            }}
            className={openMenu ? 'open' : ''}
          >
            <div className={openMenu ? 'hamburger active' : 'hamburger'}>
              <DivEmpty className={'divEmpty'}/>
            </div>
          </HamburgerBox>
        </HeaderRight>
      </HeaderBox>
    </SectionHeader>
  )
}
export default Header
const SectionHeader = styled.header`
  ${({ theme }) => theme.container};
  padding-top: 24px;
  padding-bottom: 24px;
`
const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 575px){
    position: relative;
  }
`
const Logo = styled.div`
  flex: 0 0 170px;
  max-width: 170px;
  a {
    cursor: pointer;
  }
  img {
    display: block;
    width: 100%;
    vertical-align: middle;
  }
  @media only screen and (max-width: 1024px) {
    flex-basis: 135px;
    max-width: 135px;
  }
  @media only screen and (max-width: 767px) {
    flex-basis: 80px;
    max-width: 80px;
  }
`
const SearchElement = styled.div`
  position: relative;
  @media only screen and (max-width: 575px){
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 10px;
  }
`
const SearchResult = styled.div<{show:boolean}>`
  position: absolute;
  top: 100%;
  right: 20px;
  width: 320px;
  background-color: ${({theme}) => theme.primaryColor11};
  padding: 10px;
  border-radius: 12px;
  max-height: max(70vh,350px);
  min-height: 60px;
  overflow-y: auto;
  ${({theme}) => theme.flexColumn};
  gap: 10px;
  display: none;
  ${({ show }) =>
  show &&
  css`
        display: block;
      `};
  @media only screen and (max-width: 991px){
    width: 250px;
    right: 0;
  }
  @media only screen and (max-width: 575px){
    width: 100%;
  }
`
const BoxLoader = styled.div`
  ${({theme}) => theme.flexRowCenter};
`
const TextNoMatch = styled(Text2832)`
  text-align: center;
`
const ItemResult = styled.div`
  ${({theme}) => theme.flexRowCenterVertical};
  gap: 8px;
  cursor: pointer;
  margin-bottom: 8px;
`
const ResultImage = styled.div`
  flex: 0 0 60px;
  max-width: 60px;
  height: 60px;
  img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
  }
`
const ResultInformation = styled.div``
const UserName = styled(Text2024)`
  font-weight: 600;
`
const UserAddress = styled(Text2024Arial)`
  ${({theme}) => theme.text1618};
  letter-spacing: -1px;
`
const SearchBox = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 2px;
  height: 40px;
  width: 40px;
  vertical-align: bottom;

  label {
    position: absolute;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    ${({theme}) => theme.flexRowCenter};
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.primaryColor11};
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 3;
    svg{
      width: 24px;
      height: 24px;
    }
    &:hover{
      background: ${({ theme }) => theme.bgPrimary};
      svg{
        g{
          fill: ${({ theme }) => theme.white};
        }
      }
    }
  }
  @media only screen and (max-width: 575px){
    width: 100%;
    label{
      right: 0;
      top: 0;
    }
  }
`
const FormSearch = styled(FormInputSec)<{focused:boolean}>`
  border-radius: 28px;
  position: absolute;
  top: 0;
  right: 8px;
  left: auto;
  ${({ theme }) => theme.text1620};
  padding: 0;
  width: 0;
  height: 100%;
  z-index: 2;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  max-width: none;
  transition: width 0.4s ease-in;
  &:focus{
    box-shadow:none;
  }
  @media only screen and (max-width: 575px){
    position: static;
    width: 100%;
    padding: 12px 30px 12px 16px;
    transition: none;
  }
  ${({ focused }) =>
  focused &&
  css`
      width: 360px;
      box-shadow:none;
      padding: 12px 30px 12px 16px;
      & + label {
        transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
      }
      @media only screen and (max-width: 991px){
        width: 240px;
      }
      @media only screen and (max-width: 575px){
        width: 100%;
      }
    `};
`
const HeaderRight = styled.div<{isHide:boolean | undefined}>`
  margin-left: auto;
  gap: 12px;
  align-items: center;
  display:   ${({isHide}) => isHide ? 'none' : 'flex'};
;
`
const DivEmpty = styled.div``

const Menu = styled.div`
  margin-bottom: 20px;
  @media only screen and (max-width: 767px){
    .mt-20{
      margin-top: 20px;
    }
  }
`
const MenuItem = styled.div`
  border-radius: 32px;
  padding: 6px 12px;
  background-color: ${({theme}) => theme.white};
  cursor: pointer;
  transition: background-color 0.25s ease-in;
  box-shadow: ${({theme}) => theme.boxShadow1};
  -webkit-box-shadow: ${({theme}) => theme.boxShadow1};
  &:hover{
    background-color: ${({theme}) => theme.primaryColor8};
    & p{
      color: ${({theme}) => theme.white};
    }
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 767px){
    border-radius: 20px;
  }
`
const TextLink= styled.p`
  text-align: center;
  margin: 0;
  ${({theme}) => theme.text4048};
  color: ${({theme}) => theme.primaryColor8};
  letter-spacing: 0.8px;
  @media only screen and (max-width: 767px){
    ${({theme}) => theme.text2430};
  }
  @media only screen and (min-width: 1024px) and (max-height: 850px){
    ${({theme}) => theme.text2430};
  }
`
const HamburgerBox = styled.div`
  cursor: pointer;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.white};
  z-index: 12;
  ${({ theme }) => theme.flexRowCenter};
  &:hover{
    background-image: ${({ theme }) => theme.bgPrimary};
    .hamburger{
      &:before,&:after,.divEmpty{
        background: ${({theme}) => theme.white};
      }
    }
  }
  .hamburger {
    width: 20px;
    cursor: pointer;
    &:before,
    &:after {
      content: '';
      background: ${({ theme }) => theme.bgPrimary};
      display: block;
      height: 2px;
      border-radius: 2px;
      transition: 0.5s;
    }
    &:after {
      margin-left: auto;
    }
    .divEmpty {
      background-image: ${({ theme }) => theme.bgPrimary};
      display: block;
      height: 2px;
      border-radius: 2px;
      margin: 3.5px 0;
      transition: 0.5s;
      width: 20px;
    }
    &.active {
      &::before {
        transform: translateY(5.8px) rotate(135deg);
        background: ${({ theme }) => theme.white};
      }
      &::after {
        transform: translateY(-5.8px) rotate(-135deg);
        background: ${({ theme }) => theme.white};
        width: 100%;
      }
      & .divEmpty {
        transform: scale(0);
        background: ${({ theme }) => theme.white};
        width: 100%;
      }
    }
  }
  &.open {
    background-color: ${({ theme }) => theme.bgColor3};
  }
`
const MenuPlatformBackground = styled.div<{ $openMenu?: boolean }>`
  position: fixed;
  background-image: url(${IMAGES.BG_CLOUD});
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: ${({ theme }) => theme.boxShadow1};
  backdrop-filter: blur(14px);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${({ $openMenu }) => ($openMenu ? '11' : 0)};
  transition: height 0.5s ease-in;
  min-height: ${({ $openMenu }) => ($openMenu ? 'max-content' : 0)};
  height: ${({ $openMenu }) => ($openMenu ? '100%' : 0)};
  overflow-y: auto;
  -ms-overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  &::-webkit-scrollbar-track {
    width: 0;
  }
  &::-webkit-scrollbar-thumb {
    width: 0;
  }
`
const MenuPlatform = styled.div<{ $openMenu?: boolean }>`
  width: 100%;
  height: 100%;
  max-width: 900px;
  margin: 0 auto;
  gap: 16px;
  ${({ theme }) => theme.flexColumnCenterHoz};
  opacity: ${({ $openMenu }) => ($openMenu ? '1' : 0)};
  transition: ${({ $openMenu }) => $openMenu ? 'width 1s ease-in, opacity 1s ease-in' : 'width 0.2s ease-in, opacity 0.2s ease-in'};
  ${({ $openMenu }) =>
  $openMenu &&
  css` 
     .logo-menu{
       display: block;
     }
    `};
`
const MenuPlatformBody = styled.div<{ $openMenu?: boolean }>`
  width: 100%;
  padding: 20px;
  flex: 1;
  display: ${({ $openMenu }) => ($openMenu ? 'flex' : 'none')};;
  flex-direction: column;
`
const MenuOutside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`

const LogoMenu = styled.div`
  width: 260px;
  margin: 60px auto 0;
  display: none;
  cursor: pointer;
  a {
    cursor: pointer;
  }
  @media only screen and (max-width: 991px) {
    width: 221px;
  }
  @media only screen and (max-width: 767px) {
    width: 160px;
  }
  @media only screen and (min-width: 1024px) and (max-height: 850px) {
    width: 160px;
  }
`
const DividerMenu = styled.div`
  position: relative;
  height: 40px;
  top: 20px;
  &::after{
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    height: 1px;
    border-top: 1px dashed #1677B2;
    z-index: 1;
  }
  img{
    position: absolute;
    z-index: 2;
    width: 72px;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`
const List = styled.ul`
  margin-top: auto;
  margin-bottom: 0;
  ${({theme}) => theme.flexRowCenter};
  gap: 5px;
  li{
    list-style: none;
  }
  a{
    text-decoration: none;
    ${({theme}) => theme.text1628};
    font-weight: 600;
    font-family: ${({theme}) => theme.fontArial};
    color: ${({theme}) => theme.primaryColor8};
  }
  span{
    ${({theme}) => theme.text1628};
    font-family: ${({theme}) => theme.fontArial};
    color: ${({theme}) => theme.primaryColor8};
  }
`
