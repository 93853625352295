export const GA_CONST = {
	SIGN_A_LOCK: 'Sign a lock',
	GO_TO_SIGN_LOCK:'Go to sign a lock',

	LOCK:'Lock',
	GETS_INTO_A_LOCK: 'Gets into a lock',
	CLICKS_ON_A_LOCK: 'Clicks on a lock',
	SUBMIT_LOCK_DRAFT: 'Submit lock draft',
	START_MINTING: 'Start minting',
	MINTED_SUCCESS: 'Minting process finishes correctly',
	SECURED_SUCCESS: 'Secured process finishes correctly',
	INSUFFICIENT_FUNDS: 'Insufficient funds',
	MINTING_ERROR: 'Minting error',
	CANCEL_MINTING: 'Cancel minting',
	WALLET:'Wallet',
	OPEN_CONNECT: 'Open connect',
	SIGN_MESSAGE:'Sign message',
	CANCEL_SIGN_MESSAGE: 'Cancel sign message',
	CONNECT_SUCCESS:'Connect success',
	CONNECT_ERROR: 'Connect error'
}
