import React from 'react';
import styled from "styled-components";
import {FormInputSec} from "components/common/FormInput";
import {ButtonStSecondary} from "components/common/ButtonSt";
import {Text24Arial} from "components/common/Typography";
import {useNftContract} from "hooks/useContract";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useSaleInformation} from "hooks/useSaleInformation";
import {formatEther} from "@ethersproject/units";
import {BigNumber} from "ethers";
import Loader from "components/common/Loader";
import {ToastError, ToastSuccess} from "components/common/Toasts";
import {useEnsureNetwork} from "hooks/useEnsureNetwork";
import {useTranslation} from "react-i18next";
import {POSITION_TOAST} from "constants/common";

const SectionChangePrice = () => {
	const nftContract = useNftContract()
	const {priceInUSD, priceInGas,refetch}=useSaleInformation()
	const { ensureNetwork, isExpectedNetwork } = useEnsureNetwork()
	const {t} = useTranslation()
	const formikPrice = useFormik({
		initialValues: {
			newPrice: '',
		},
		validationSchema: Yup.object().shape({
			newPrice: Yup.string().matches(/^[0-9]*$/,`Your price isn't valid`).required('New price is required'),
		}),
		onSubmit: async (values: FormikValues) => {
			if(!isExpectedNetwork){
				await ensureNetwork()
				return
			}else{
				if (!nftContract) return

				setSubmitting(true)
				try {
					const { newPrice } = values
					const cvPrice = newPrice.trim() === '' ? '0' : newPrice
					const tx = await nftContract.changePrice(parseInt(cvPrice))
					await tx.wait(1)
					toast.success(<ToastSuccess>{t('admin.contract.price.messageSuccess')}</ToastSuccess>, POSITION_TOAST)
					refetch()
				} catch (e) {
					console.log(e)
					toast.error(<ToastError>{t('admin.contract.price.messageFailed')}</ToastError>, POSITION_TOAST)
				} finally {
					setSubmitting(false)
				}
			}
		}
	})
	const { setSubmitting,handleChange,values,isSubmitting,isValid,dirty,setFieldValue,handleBlur,handleSubmit } = formikPrice
	const handleBlurQuantity = (e:React.ChangeEvent<HTMLInputElement>) => {
		if(values.newPrice === ''){
			setFieldValue && setFieldValue('newPrice','0')
		}
		handleBlur(e)
	}
	return (
		<SectionChangePriceWrap>
			<OldPriceBox>
				<FieldWrap>
					<NameField>{t('admin.contract.price.form.priceOld.label')}</NameField>
					<ValueField>{priceInUSD && priceInGas && `$${BigNumber.from(priceInUSD).toString()} (${formatEther(BigNumber.from(priceInGas).sub(BigNumber.from(priceInGas).mod(1e13)))} ETH)`}</ValueField>
				</FieldWrap>
			</OldPriceBox>
			<FormPrice onSubmit={handleSubmit}>
				<FieldWrap>
					<NameField>{t('admin.contract.price.form.priceNew.label')}</NameField>
					<FormInputSec id={'newPrice'} name={'newPrice'} placeholder={t('admin.contract.price.form.priceNew.placeholder')} value={values.newPrice ?? ''} onChange={handleChange} onBlur={(e:React.ChangeEvent<HTMLInputElement>) => handleBlurQuantity(e)}/>
				</FieldWrap>
				<ButtonChange type={'submit'} disabled={isSubmitting || !isValid || !dirty}>
					{
						isSubmitting ? <>
							<Loader width={10}/>
							{t('button.changePrice')}
						</> : <>{t('button.changePrice')}</>
					}
				</ButtonChange>
			</FormPrice>
		</SectionChangePriceWrap>
	);
};

export default SectionChangePrice;
const SectionChangePriceWrap = styled.div``
const NameField = styled(Text24Arial)`
	font-size: ${({theme}) => theme.fontSize16};
	line-height: 1.25;
	font-family: ${({theme}) => theme.fontArial};
	color: ${({theme}) => theme.primaryColor8};
  max-width: 100px;
  flex: 0 0 100px;
  font-weight: 700;
  letter-spacing: -0.5px;
`
const ValueField = styled(NameField)`
	font-weight: 700;
	flex-basis: auto;
	max-width: none;
`
const FieldWrap = styled.div`
	${({theme}) => theme.flexRowCenterVertical};
	gap: 10px;
`
const OldPriceBox = styled.div`
	margin-bottom: 20px;
`
const FormPrice = styled.form`
  ${({theme}) => theme.flexColumn};
  gap: 20px;
	width: 100%;
`
const ButtonChange = styled(ButtonStSecondary)`
	padding: 8px 24px;
	width: 160px;
	${({theme}) => theme.text1620};
	letter-spacing: 0.8px;
	${({theme}) => theme.flexRowCenter};
	gap: 10px;
  &:hover{
    background-color: ${({theme}) => theme.primaryColor2};
  }
`
