import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import { ReportType } from '@thelovebridge/core/src/entities/Report'

export const reportSlice = createApi({
  tagTypes: ['Report'],
  reducerPath: 'Report',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    report: build.mutation({
      query: (report: { type: ReportType, resourceId: string }) => {
        return {
          url: 'report',
          method: 'PUT',
          body: report
        }
      }
    }),
    unreport: build.mutation({
      query: (report: { type: ReportType, resourceId: string }) => {
        return {
          url: 'report',
          method: 'DELETE',
          body: report
        }
      }
    }),
  })
})
export const {
  useReportMutation,
  useUnreportMutation
} = reportSlice
