import React, {useMemo} from 'react'
import {OperatingSystem} from "models/System";
import {OperatingSystemContext} from "providers/OperatingSystemProvider/OperatingSystemContext";
const OperatingSystemProvider = ({children}:{children:React.ReactNode}) => {
  const system = useMemo(() => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return OperatingSystem.WINDOWS_PHONE
    }

    if (/android/i.test(userAgent)) {
      return OperatingSystem.ANDROID
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return OperatingSystem.IOS
    }

    return OperatingSystem.UNKNOWN
  },[])
  return <OperatingSystemContext.Provider
    value={{
      system
    }}
  >
    {children}
  </OperatingSystemContext.Provider>

}
export default OperatingSystemProvider
