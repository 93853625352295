import 'react-toastify/dist/ReactToastify.css'

import * as React from 'react';
import { ToastContainer } from 'react-toastify'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import 'rc-table/assets/index.css';
import 'animate.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-pagination/assets/index.css';
import { GlobalStyle, ThemeProvider } from './styled';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store, {persistor} from 'state/store';
import AuthProvider from 'providers/AuthProvider';
import { HelmetProvider } from 'react-helmet-async'
import {BrowserRouter} from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react'
import './i18n'
import SaleProvider from "providers/SaleProvider";
import WalletProvider from 'providers/WalletProvider';
import OperatingSystemProvider from "providers/OperatingSystemProvider";
if (process.env.NODE_ENV === 'production') {
  console.log = function () {}
}
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider>
        <GlobalStyle />
        <WalletProvider>
          <CookiesProvider>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <OperatingSystemProvider>
                  <BrowserRouter>
                    <AuthProvider>
                      <SaleProvider>
                        <React.Suspense>
                          <App />
                        </React.Suspense>
                      </SaleProvider>
                    </AuthProvider>
                  </BrowserRouter>
                </OperatingSystemProvider>
              </PersistGate>
            </Provider>
          </CookiesProvider>
        </WalletProvider>
        <ToastContainer />
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
