import React, {useEffect, useLayoutEffect} from 'react';
import { LayoutSecondary } from 'components/layout';
import styled from 'styled-components';
import { Text100120 } from 'components/common/Typography';
import useAuth from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';

const Banned = () => {
	const {isLoading,isBanned} = useAuth()
	const history = useHistory()
	useEffect(() => {
		document.title = 'Banned || The Love Bridge'
	}, [])
	useLayoutEffect(() => {
		if(!isLoading && !isBanned){
			history.push('/')
		}
	},[isLoading,isBanned,history])
	return (
		isLoading ? null : <LayoutSecondary>
			<Message>Account Banned</Message>
		</LayoutSecondary>
	);
};

export default Banned;
const Message = styled(Text100120)``
