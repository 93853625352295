import React, {useCallback, useEffect, useState} from 'react'
import {LayoutAdmin} from 'components/layout';
import {useListAdminGiftsQuery} from 'state/giftsState'
import {ButtonStPrimary} from 'components/common/ButtonSt'
import {GiftTable} from './GiftTable'
import {ModalCreateGift} from 'components/common/Modal/ModalCreateGift'
import {ModalDeleteGift} from 'components/common/Modal/ModalDeleteGift'
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {FormInputSec} from "components/common/FormInput";
import {useDebounce} from "usehooks-ts";
import SelectSingleCs from "components/common/SelectSingleCs";
import {STATUS_GIFT} from "constants/optionsSelect";
import {OptionType} from "components/common/SelectSingleCs/SelectType";
import {Text24Arial} from "components/common/Typography";
import {Gift, GiftStatus} from "@thelovebridge/core/src/entities/Gifts";
import {PaginationSt, TextDbNextPrev} from "components/common/PaginationSt";
import Pagination from "rc-pagination";
import {useDebouncedCallback} from "use-debounce";
import {ModalSignGift} from 'components/common/Modal/ModalSignGift'
import moment from "moment";

const PAGE_SIZE = 8
export default function Gifts() {
  const {t} = useTranslation()
  const [isCreatingGift, setIsCreatingGift] = useState<boolean>(false)
  const [isSigningGift, setIsSigningGift] = useState<boolean>(false)
  const [isDeletingGift, setIsDeletingGift] = useState<boolean>(false)
  const [giftToSign, setGiftToSign] = useState<Gift | undefined>()

  const [idToDelete,setIdToDelete] = useState<number| undefined>()
  const {data, refetch} = useListAdminGiftsQuery()
  const [dataTableGift,setDataTableGift] = useState<Gift[]>([])
  const [allGifts,setAllGits] = useState<Gift[]>([])
  const [searchTerm,setSearchTerm] = useState<string>('')
  const debounceTerm = useDebounce(searchTerm.trim(),800)
  const [optionStatus,setOptionStatus] = useState<OptionType>(STATUS_GIFT[0])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const onDebounced = useDebouncedCallback(
    () => {
      setCurrentPage(1)
    },
    900
  );
  const handleSend = useCallback((gift: Gift) => {
    setGiftToSign(gift);
    setIsSigningGift(true);
  },[])
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
    onDebounced()
  }
  const handleDelete = useCallback((id: number) => {
    setIdToDelete(id);
    setIsDeletingGift(true);
  },[])

  const onChooseSelect = (nameField:string,option:OptionType) => {
    setOptionStatus(option)
    setCurrentPage(1)
  }
  const onChangePagination = (current:number) =>{
    setCurrentPage(current)
  }
  useEffect(() => {
    document.title = 'Gifts || Admin Panel || The Love Bridge'
  }, [])
  useEffect(() => {
    if(!data || data.length === 0){
      return
    }
    let allGiftFilter = []
    allGiftFilter = data.filter((item:Gift) => {
      const {expTimestamp} = item
      const momentTimestamp = moment(new Date()).unix()
      if(optionStatus.value === 'EXPIRED'){
        return item.status === GiftStatus.SENT && expTimestamp < momentTimestamp
      } else if(optionStatus.value === GiftStatus.SENT) {
        return item.status === GiftStatus.SENT && expTimestamp > momentTimestamp
      } else{
        return (item.status === optionStatus.value) || optionStatus.value === 'ALL'
      }
    })
    if(debounceTerm !== ''){
      allGiftFilter = allGiftFilter.filter((item:Gift) => {
        return item.userAddress.toLowerCase().includes(debounceTerm.toLowerCase()) || (item.ensAddress && item.ensAddress.toLowerCase().includes(debounceTerm.toLowerCase()))
      })
    }
    const dataLockTable = allGiftFilter.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
    setAllGits(allGiftFilter)
    setDataTableGift(dataLockTable)

  },[data,optionStatus.value,currentPage,debounceTerm])

  return (
    <LayoutAdmin>
      <Filters>
        <FilterInput>
          <FormInputSec placeholder={t('admin.gifts.search')} onChange={onSearch} value={searchTerm}/>
        </FilterInput>
        <FilterSelect>
          <FilterSelectName>{t('admin.gifts.label')}:</FilterSelectName>
          <SelectSingleCs options={STATUS_GIFT} initialSelected={optionStatus} nameField={'statusGift'} onChoose={onChooseSelect}/>
        </FilterSelect>
      </Filters>
      <SectionGift>
        <GiftTable data={dataTableGift} handleDelete={handleDelete} handleSend={handleSend}/>
      </SectionGift>
      {
        allGifts && allGifts.length > PAGE_SIZE && <PaginationSt>
          <Pagination
            onChange={(current:number) => {
              onChangePagination(current)
            }}
            current={currentPage}
            pageSize={PAGE_SIZE}
            total={allGifts.length}
            showTitle={false}
            prevIcon={() => (
              <TextDbNextPrev>
                Prev
              </TextDbNextPrev>
            )}
            nextIcon={() => (
              <TextDbNextPrev>
                Next
              </TextDbNextPrev>
            )}
          />
        </PaginationSt>
      }
      <ButtonStPrimary onClick={() => setIsCreatingGift(true)}>{t('admin.gifts.createBtn')}</ButtonStPrimary>

      <ModalCreateGift onDismiss={() => {
        refetch()
        setIsCreatingGift(false)
      }} isOpen={isCreatingGift}/>
      <ModalDeleteGift idToDelete={idToDelete} onDismiss={() => {
        refetch()
        setIsDeletingGift(false)
      }} isOpen={isDeletingGift}/>
      <ModalSignGift giftToSign={giftToSign} onDismiss={() => {
        refetch()
        setIsSigningGift(false)
      }} isOpen={isSigningGift}/>
    </LayoutAdmin>
  )
}
const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`
const FilterInput = styled.div`
	flex: 1;
	align-self: flex-end;
`
const FilterSelectName = styled(Text24Arial)`
	${({theme}) => theme.text2024};
	margin-bottom: 6px;
`
const FilterSelect = styled.div`
	flex: 0 0 180px;
`
const SectionGift = styled.div`
  background: ${({ theme }) => theme.primaryColor2};
  box-shadow: ${({ theme }) => theme.boxShadow3};
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 20px;
`
