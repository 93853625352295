import styled from 'styled-components';

export const FormInputSt = styled.input`
  outline: none;
  border: none;
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.primaryColor1};
  font-family: ${({ theme }) => theme.fontArial};
  ${({ theme }) => theme.text1624};
  color: ${({ theme }) => theme.primaryColor8};
  &::placeholder {
    color: ${({ theme }) => theme.primaryColor10};
    text-align: left;
  }
  &:focus {
    -webkit-box-shadow: 0 0 2px 2px ${({ theme }) => theme.primaryColor9};
    -moz-box-shadow: 0 0 2px 2px ${({ theme }) => theme.primaryColor9};
    box-shadow: 0 0 2px 2px ${({ theme }) => theme.primaryColor9};
  }
`
export const FormTextAreaSt = styled.textarea`
  outline: none;
  border: none;
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.primaryColor1};
  font-family: ${({ theme }) => theme.fontArial};
  ${({ theme }) => theme.text1624};
  color: ${({ theme }) => theme.primaryColor8};
  &::placeholder {
    color: ${({ theme }) => theme.primaryColor10};
    text-align: left;
  }
  &:focus {
    -webkit-box-shadow: 0 0 2px 2px ${({ theme }) => theme.primaryColor9};
    -moz-box-shadow: 0 0 2px 2px ${({ theme }) => theme.primaryColor9};
    box-shadow: 0 0 2px 2px ${({ theme }) => theme.primaryColor9};
  }
  resize: none;
`
