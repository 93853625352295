import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import { Profile } from '@thelovebridge/core/src/entities/Profile'
import { ChangeUserStatusRequest } from 'components/common/Modal/ModalChangeUserStatus'
import {Parameters} from "@thelovebridge/core/src/constants/ParametersConstants";

export const userManagementSlice = createApi({
  tagTypes: ['UserManagement'],
  reducerPath: 'UserManagement',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    listUsers: build.query<Profile[], number>({
      query: () => {
        return {
          url: 'admin/users',
          method: 'GET'
        }
      },
      providesTags:['UserManagement']
    }),
    changeUserStatus: build.mutation({
      query: (body: ChangeUserStatusRequest) => {
        return {
          url: `admin/users/status`,
          method: 'PATCH',
          body
        }
      }
    }),
    uploadTranslations: build.mutation({
      query: (data:any) => {
        return {
          url: `admin/parameters/${Parameters.TRANSLATION}`,
          method: 'PATCH',
          body: data
        }
      }
    }),
    getSubscriptions: build.query<any, void>({
      query: () => {
        return {
          url: 'admin/subscriptions',
          method: 'GET'
        }
      }
    }),
  })
})
export const { useListUsersQuery, useChangeUserStatusMutation,useUploadTranslationsMutation,useGetSubscriptionsQuery } = userManagementSlice
