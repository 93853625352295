import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { defaultParameters } from 'constants/baseUrl'

export const saleInformationSlide = createApi({
  tagTypes: ['SaleInformation'],
  reducerPath: 'SaleInformation',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    getSaleInformation: build.query<void, void>({
      query: () => {
        return {
          url: 'saleInformation'
        }
      },
      providesTags: ['SaleInformation']
    }),
  })
})
export const {
  useGetSaleInformationQuery
} = saleInformationSlide
