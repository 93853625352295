import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IMAGES } from 'assets';
import { Text1628 } from '../Typography';
import useAuth from "hooks/useAuth";
import {PATH} from "../../../config";
import {useTranslation} from "react-i18next";
const socialsJoinUs = [
  {
    icon: <IMAGES.ICON_INSTAGRAM/>,
    link:'https://linktr.ee/lovebridgenft'
  },
  {
    icon: <IMAGES.ICON_TWITTER/>,
    link:'https://linktr.ee/lovebridgenft'
  },
  {
    icon: <IMAGES.ICON_TELEGRAM/>,
    link:'https://linktr.ee/lovebridgenft'
  }
]
const Footer = () => {
  const {t} = useTranslation()
  const {isBanned} = useAuth()
  return (
    <SectionFooter>
      <FooterBox>
        <LogoFooter>
          <Link to={isBanned ? PATH.BANNED : PATH.HOME}>
            <img src={IMAGES.LOGO_FOOTER} alt={'logo_footer'} />
          </Link>
        </LogoFooter>
        <SocialsJoin>
          {
            socialsJoinUs.map((item,index) => {
              return <ItemSocial key={index} href={item.link} target={'_blank'}>
                {item.icon}
              </ItemSocial>
            })
          }
        </SocialsJoin>
        <Copyright>{t('footer.reserved')}</Copyright>
      </FooterBox>
    </SectionFooter>
  )
}
export default Footer
const SectionFooter = styled.footer`
  ${({ theme }) => theme.container};
`
const FooterBox = styled.div`
  ${({ theme }) => theme.flexColumnCenter};
  gap: 20px;
`
const LogoFooter = styled.div`
  width: 322px;
  a {
    cursor: pointer;
  }
  @media only screen and (max-width: 767px) {
    width: 221px;
  }
`
const Copyright = styled(Text1628)`
  font-family: ${({ theme }) => theme.fontArial};
  font-weight: 500;
  @media only screen and (max-width: 767px) {
    font-size: ${({ theme }) => theme.fontSize12};
  }
`
const SocialsJoin= styled.div`
	${({theme}) => theme.flexRowCenter};
	gap: 20px;
`
const ItemSocial = styled.a`
	display: block;
	svg{
		width: 40px;
		height: 40px;
    transition: transform 0.25s ease-in;
    &:hover{
      transform: scale(1.2);
    }
	}
`
