import { configureStore,combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { currenciesSlice } from 'state/currenciesState';
import { faqsSlice } from 'state/faqsState';
import { gallerySlice } from 'state/galleryState';
import { giftSlice } from 'state/giftsState'
import { userSlice } from 'state/userState';
import { saleInformationSlide } from 'state/saleInformationSlice'
import { userManagementSlice } from 'state/usersManagementState'
import { reportSlice } from 'state/reportState'
import { mediaSlice } from 'state/mediaState'
import { contactSlice } from 'state/contactState'
import {reportManagementSlice} from "state/reportManagement";
import {lockManagementApi} from "state/lockManagementApi";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { timelineSlice } from 'state/timelineState'
import { lockDetailSlice } from 'state/lockDetailState'
import { commonSlice } from 'state/commonState'
import publicKeySlice from 'state/publicKeyState'
import { indexerSlice } from 'state/indexerState'
const persistConfig = { key: "root", storage, version: 1,whitelist:['userManagerAddressSlice'] }

const rootReducer = combineReducers({
	publicKeySlice,
	[indexerSlice.reducerPath]: indexerSlice.reducer,
	[userSlice.reducerPath]: userSlice.reducer,
	[faqsSlice.reducerPath]: faqsSlice.reducer,
	[gallerySlice.reducerPath]: gallerySlice.reducer,
	[currenciesSlice.reducerPath]: currenciesSlice.reducer,
	[saleInformationSlide.reducerPath]: saleInformationSlide.reducer,
	[giftSlice.reducerPath]: giftSlice.reducer,
	[reportSlice.reducerPath]: reportSlice.reducer,
	[userManagementSlice.reducerPath]: userManagementSlice.reducer,
	[mediaSlice.reducerPath]: mediaSlice.reducer,
	[reportManagementSlice.reducerPath]: reportManagementSlice.reducer,
	[lockManagementApi.reducerPath]: lockManagementApi.reducer,
	[contactSlice.reducerPath]: contactSlice.reducer,
	[timelineSlice.reducerPath]: timelineSlice.reducer,
	[lockDetailSlice.reducerPath]: lockDetailSlice.reducer,
	[commonSlice.reducerPath]: commonSlice.reducer,
})
// Persist the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: true,
			serializableCheck: false,
			immutableCheck: false
		})
			.concat(indexerSlice.middleware)
			.concat(userSlice.middleware)
			.concat(faqsSlice.middleware)
			.concat(gallerySlice.middleware)
			.concat(currenciesSlice.middleware)
			.concat(giftSlice.middleware)
			.concat(reportSlice.middleware)
			.concat(saleInformationSlide.middleware)
			.concat(userManagementSlice.middleware)
			.concat(mediaSlice.middleware)
			.concat(reportManagementSlice.middleware)
			.concat(lockManagementApi.middleware)
			.concat(contactSlice.middleware)
			.concat(timelineSlice.middleware)
			.concat(lockDetailSlice.middleware)
			.concat(commonSlice.middleware)
})
setupListeners(store.dispatch)
export default store
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export const persistor = persistStore(store)
