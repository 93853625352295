import styled from 'styled-components';

export const TextBase = styled.p`
  font-style: normal;
  line-height: normal;
  font-weight: 400;
`
export const Text100120 = styled.h1`
  ${({theme}) => theme.textBaseGradient};
  ${({ theme }) => theme.text100120};
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize80};
  }
  @media only screen and (max-width: 575px) {
    font-size: ${({ theme }) => theme.fontSize40};
  }
`
export const Text1214 = styled(TextBase)`
  ${({theme}) => theme.textBaseGradient};
  ${({ theme }) => theme.text1214};
`
export const Text1628 = styled(TextBase)`
  ${({theme}) => theme.textBaseGradient};
  ${({ theme }) => theme.text1628};
`
export const Text2024 = styled(TextBase)`
  ${({theme}) => theme.textBaseGradient};
  ${({ theme }) => theme.text2024};
`
export const Text2832 = styled(TextBase)`
  ${({theme}) => theme.textBaseGradient};
  ${({ theme }) => theme.text2832};
`
export const Text3244 = styled(TextBase)`
  ${({theme}) => theme.textBaseGradient};
  ${({ theme }) => theme.text3244};
  @media only screen and (max-width: 768px) {
    ${({ theme }) => theme.text2832};
  }
`
export const Text3236 = styled(TextBase)`
  ${({ theme }) => theme.text3236};
`
export const Text2024Arial = styled(TextBase)`
  color: ${({ theme }) => theme.primaryColor8};
  font-family: ${({ theme }) => theme.fontArial};
  ${({ theme }) => theme.text2024};
`
export const TextShadowCp = styled.p`
  font-size: ${({ theme }) => theme.fontSize60};
  line-height: 1.2;
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  ${({ theme }) => theme.textShadow};
`
export const Text24Arial = styled.p`
  ${({ theme }) => theme.text2430}
  font-family: ${({ theme }) => theme.fontArial};
  color: ${({ theme }) => theme.primaryColor8};
  line-height: 1;
  @media only screen and (max-width: 1024px) {
    font-size: ${({ theme }) => theme.fontSize20};
    line-height: 1.15;
  }
  @media only screen and (max-width: 767px) {
    font-size: ${({ theme }) => theme.fontSize18};
    line-height: 1.38;
  }
`
export const Text8484 = styled(TextBase)`
  font-size: ${({theme}) => theme.fontSize84};
  line-height: 1;
  ${({theme}) => theme.textBaseGradient};
  font-weight: 800;
`
export const MessageError = styled(Text2024Arial)`
  color: ${({theme}) => theme.primaryColor8};
  margin-top: 8px;
`
export const Text16Arial = styled.p`
  ${({theme}) => theme.text1416};
  font-family: ${({ theme }) => theme.fontArial};
  color: ${({ theme }) => theme.primaryColor8};
  @media only screen and (max-width: 1024px){
    ${({theme}) => theme.text1214};
  }
`
export const Text2832Base = styled(TextBase)`
  ${({ theme }) => theme.text2832};
  color: ${({theme}) => theme.white};
`
