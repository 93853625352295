import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import { Gift } from '@thelovebridge/core/src/entities/Gifts'

export const giftSlice = createApi({
  tagTypes: ['Gift', 'Gifts'],
  reducerPath: 'Gift',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    listAdminGifts: build.query<Gift[], void>({
      query: () => {
        return {
          url: 'admin/gifts',
          method: 'GET'
        }
      },
      providesTags:['Gifts']
    }),
    prepareGift: build.mutation<Gift, Partial<Gift>>({
      query: (gift : Partial<Gift>) => {
        return {
          url: `admin/gifts/prepare`,
          method: 'POST',
          body: gift
        }
      }
    }),
    sendGift: build.mutation({
      query: (gift : Partial<Gift>) => {
        return {
          url: `admin/gifts/send`,
          method: 'PATCH',
          body: gift
        }
      }
    }),
    deleteGift: build.mutation({
      query: (id : number ) => {
        return {
          url: `admin/gifts/${id}`,
          method: 'DELETE'
        }
      }
    }),
    getGift: build.query<Gift[], void>({
      query: () => {
        return {
          url: 'gifts',
          method: 'GET'
        }
      },
      providesTags:['Gifts']
    }),
    markAsPaid: build.mutation({
      query: (id:number) => {
        return {
          url: `gifts/${id}/markAsPaid`,
          method: 'PATCH'
        }
      }
    })
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true
})
export const { useListAdminGiftsQuery, useDeleteGiftMutation, useSendGiftMutation, usePrepareGiftMutation,useLazyGetGiftQuery, useMarkAsPaidMutation } = giftSlice