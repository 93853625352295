import React, { useEffect } from 'react'
import { LayoutAdmin } from 'components/layout';
import UsersTable  from './UsersTable'
import useAuth from 'hooks/useAuth'

export default function Users() {
  const {isOwner} = useAuth()
  useEffect(() => {
    document.title = 'Users || Admin Panel || The Love Bridge'
  }, [])
  return (
    <LayoutAdmin>
      <UsersTable isOwner={isOwner} />
    </LayoutAdmin>
  )
}
