import React, {useEffect} from 'react';
import {LayoutPrimary} from "components/layout";
import styled from "styled-components";
import {Text100120, Text2832} from "components/common/Typography";
import {BoxSt} from "components/common/BoxSt";
import RowCs from "components/common/RowCs";
import ColumnCs from "components/common/ColumnCs";
import {IMAGES} from "assets/images";
import {useTranslation} from "react-i18next";
import withHelmet from "components/hoc/withHelmet";
import {BoxStType} from "models/common";

const informationTeams = [
	{
		avatar: IMAGES.TEAM_CLINTON,
		name: 'Mister LOVR LOVR Clinton Baker <br/> OG Founder - CEO Dev',
		description: 'Concocting LOVR Goodness',
		connect: [
			{
				icon: <IMAGES.ICON_LINKEDIN />,
				link: 'https://www.linkedin.com/in/clinton-baker-42705773/'
			},
			{
				icon: <IMAGES.ICON_INSTAGRAM />,
				link: 'https://www.instagram.com/soleskull/'
			},
			{
				icon: <IMAGES.ICON_TWITTER />,
				link: 'https://x.com/soleskull?s=20'
			}
		]
	},
	{
		avatar: IMAGES.TEAM_LOOSID,
		name: 'DreamBoy - LOOSID <br/> Christian Alford- Lead 3D Artist',
		description: 'Master Sculper',
		connect: [
			{
				icon: <IMAGES.ICON_INSTAGRAM />,
				link: 'https://www.instagram.com/loosid.visuals/'
			},
			{
				icon: <IMAGES.ICON_TWITTER />,
				link: 'https://x.com/Loooosid?s=20'
			}
		]
	},
	{
		avatar: IMAGES.TEAM_DANDESLIONLABS,
		name: 'The Love Swami \'s <br/> Dandelion Labs - Lead Developers',
		description: 'Regularly mends broken',
		connect: [
			{
				icon: <IMAGES.ICON_LINKEDIN />,
				link: 'https://www.linkedin.com/company/dandelionlabs/'
			},
		]
	},
	{
		avatar: IMAGES.TEAM_EMMA,
		name: 'LOVR Pusha - Emma Lelmer <br/>  Head of Marketing',
		description: 'Spreading Love',
		connect: [
			{
				icon: <IMAGES.ICON_LINKEDIN />,
				link: 'https://www.linkedin.com/in/emmalgoode/'
			},
		]
	},
	{
		avatar: IMAGES.TEAM_ALPER,
		name: 'LOVE PATTY / Alper Ozkul <br/>  Animation 3D Modelling',
		description: 'Sending 3D hearts into the universe ',
		connect: [
			{
				icon: <IMAGES.ICON_LINKEDIN />,
				link: 'https://www.linkedin.com/in/alper-ozkul'
			},
		]
	},
	{
		avatar: IMAGES.TEAM_SABIR,
		name: 'The LOVR Doctor  <br/>  Sabir - Artist - Advisor',
		description: 'Love Injector ',
		connect: []
	},
	{
		avatar: IMAGES.TEAM_SUSAN,
		name: 'Amin Susan <br/> Social Media',
		description: 'Aameen LOVR ',
		connect: [
			{
				icon: <IMAGES.ICON_LINKEDIN />,
				link: 'https://www.linkedin.com/in/amin-sujan-78b38072/'
			},
		]
	},
	{
		avatar: IMAGES.TEAM_SAIFUR,
		name: 'Saifur Rahman <br/> Social Media',
		description: 'Loves to talk ',
		connect: []
	},
	{
		avatar: IMAGES.TEAM_DANIEL,
		name: 'Daniel Dietrich<br/> Social Media',
		description: 'The Robot ',
		connect: [
			{
				icon: <IMAGES.ICON_TWITTER />,
				link: 'https://x.com/LoveBridgeNFT?s=20'
			}
		]
	},
	{
		avatar: IMAGES.TEAM_YUSON,
		name: 'Jack Yuson<br/> Social Media',
		description: 'Mr. Handy ',
		connect: [
			{
				icon: <IMAGES.ICON_TWITTER />,
				link: 'https://x.com/nftwrasse?s=20'
			}
		]
	},
	{
		avatar: IMAGES.TEAM_MATT,
		name: 'Matt Gray <br/> Creative Advisor',
		description: 'Mr Deep Dive ',
		connect: [
			{
				icon: <IMAGES.ICON_LINKEDIN />,
				link: 'https://www.linkedin.com/in/here-be-matt/'
			},
			{
				icon: <IMAGES.ICON_TWITTER />,
				link: 'https://x.com/here_be_matt?s=20'
			}
		]
	},
	{
		avatar: IMAGES.TEAM_ANDY,
		name: 'Andy Pringle  <br/> Advisor',
		description: 'Mr. Twister ',
		connect: [
			{
				icon: <IMAGES.ICON_LINKEDIN />,
				link: 'https://www.linkedin.com/in/pringleandrew/'
			},
			{
				icon: <IMAGES.ICON_TWITTER />,
				link: 'https://x.com/laughingbudd?s=20'
			}
		]
	},
]
const partners = [
	{
		avatar: IMAGES.PARTNER_PINEAPPLE,
		name: 'P1NEAPP<br/>Naughty Nick Morris',
		description: 'Plating a tree for every NFT minted in conjunction with The Love Bridge.',
		connect: [
			{
				icon: <IMAGES.ICON_INSTAGRAM />,
				link: 'https://www.instagram.com/p1ne.app/'
			},
			{
				icon: <IMAGES.ICON_TWITTER />,
				link: 'https://x.com/p1npl?s=20'
			}
		]
	},
	{
		avatar: IMAGES.PARTNER_BMINTED,
		name: 'BMINTED.IO',
		description: 'NFT Marketplace Partner',
		connect: [
			{
				icon: <IMAGES.ICON_TWITTER />,
				link: 'https://x.com/BMintedNFT?s=20'
			}
		]
	},
	{
		avatar: IMAGES.PARTNER_DANDELIONLABS,
		name: 'Dandelion Labs',
		description: 'Developers',
		connect:[]
	},
]
const OurTeam = () => {
	useEffect(() => {
		document.title = 'Our team || The Love Bridge'
	}, [])
	const {t} = useTranslation()
	return (
		<LayoutPrimary>
			<SectionTeams>
				<Introduce>
					<TeamsTitle>{t('ourTeam.title')}</TeamsTitle>
					<TeamsDescriptionBox size={BoxStType.LARGE}>
						<TeamsDescription>
							{t('ourTeam.description')}
						</TeamsDescription>
					</TeamsDescriptionBox>
				</Introduce>
				<Teams>
					<RowCs space={-15}>
						{
							informationTeams.map((member,index:number) => {
								return <ColumnCs gutter={15} xl={3} md={4} xs={6} key={index}>
									<Member>
										<MemberCard>
											<CardImage>
												<img src={member.avatar} alt={'member'}/>
											</CardImage>
											<CardContent>
												<Name dangerouslySetInnerHTML={{__html: member.name}}/>
												<TextIntroduce>{member.description}</TextIntroduce>
													{
														member.connect.length > 0 && <Connect>
															{
																member.connect.map((link,index) => {
																	return <IconLink href={link.link} key={index} target={'_blank'}>
																		{link.icon}
																	</IconLink>
																})
															}
														</Connect>
													}
											</CardContent>
										</MemberCard>
									</Member>
								</ColumnCs>

							})
						}
					</RowCs>
				</Teams>
			</SectionTeams>
			<SectionPartners>
				<Introduce>
					<TeamsTitle>{t('ourTeam.ourPartners')}</TeamsTitle>
				</Introduce>
				<Partners>
					<RowCs space={-15}>
						{
							partners.map((partner,index:number) => {
								return <ColumnCs gutter={15} md={4} xs={6} key={index}>
									<Member>
										<MemberCard>
											<CardImage>
												<img src={partner.avatar} alt={'partner'}/>
											</CardImage>
											<CardContent>
												<Name dangerouslySetInnerHTML={{__html: partner.name}}/>
												<TextIntroduce>{partner.description}</TextIntroduce>
												{
													partner.connect.length > 0 && <Connect>
														{
															partner.connect.map((link,index) => {
																return <IconLink href={link.link} key={index} target={'_blank'}>
																	{link.icon}
																</IconLink>
															})
														}
													</Connect>
												}
											</CardContent>
										</MemberCard>
									</Member>
								</ColumnCs>

							})
						}
					</RowCs>
				</Partners>
			</SectionPartners>
		</LayoutPrimary>
	);
};

export default withHelmet(OurTeam);
const SectionTeams = styled.section`
	${({theme}) => theme.container};
`
const Introduce = styled.div``
const TeamsTitle = styled(Text100120)`
  text-align: center;
`
const TeamsDescriptionBox = styled(BoxSt)`
	width: 100%;
  max-width: 1280px;
	margin: 0 auto;
`
const TeamsDescription = styled(Text2832)`
  text-align: center;
  font-style: italic;
  font-weight: 300;
  letter-spacing: -0.4px;
	width: 100%;
	margin-inline:auto;
`
const Teams = styled.div``
const Member = styled.div`
	padding: 10px 0;
`
const MemberCard = styled.div``
const CardImage = styled.div`
  width: 100%;
	height: 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
	img{
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
		&[alt='partner']{
			object-fit: contain;
		}
	}
`
const CardContent = styled.div`
	margin-top: 12px;
	${({theme}) => theme.flexColumnCenter};
	gap: 8px;
`
const Name = styled.h3`
	${({theme}) => theme.text2024};
  color: ${({theme}) => theme.primaryColor8};
  letter-spacing: 0.8px;
	text-align: center;
`
const TextIntroduce = styled.p`
	margin: 0;
  ${({theme}) => theme.text1416};
	font-family: ${({theme}) => theme.fontArial};
	color: ${({theme}) => theme.primaryColor7};
	text-align: center;
`
const Connect = styled.div`
	${({theme}) => theme.flexRowCenterVertical};
	gap: 10px;
`
const IconLink = styled.a`
	text-decoration: none;
  width: 24px;
  height: 24px;
	cursor: pointer;
	display: block;
	svg{
		width: 24px;
		height: 24px;
	}
`
const SectionPartners = styled.div`
  ${({theme}) => theme.container};
	max-width: 1000px;
  margin-top: 60px;
	margin-bottom: 60px;
`
const Partners = styled.div``
