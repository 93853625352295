
import * as React from 'react';
import styled from 'styled-components';
import { MenuLink, MenuOption } from './MenuLink';
import { PATH } from '../../../config'
import useAuth from 'hooks/useAuth'

const options: MenuOption[] = [
    {
        route: PATH.ADMIN.GIFTS,
        name: 'Gifts',
        onlyAdmin: true,
        onlyOwner: true
    },
    {
        route: PATH.ADMIN.USERS,
        name: 'Users',
        onlyAdmin: true,
        onlyOwner: true
    },
    {
        route: PATH.ADMIN.OWNERSHIP,
        name: 'Ownership',
        onlyOwner: true
    },
    {
        route: PATH.ADMIN.CONTRACT,
        name: 'Contract',
        onlyOwner: true
    },
    {
        route: PATH.ADMIN.WITHDRAWALS,
        name: 'Withdrawals',
        onlyOwner: true
    },
    {
        route: PATH.ADMIN.REPORT,
        name: 'Report',
        onlyAdmin: true,
        onlyOwner: true
    },
    {
        route: PATH.ADMIN.LOCKS,
        name: 'Locks',
        onlyAdmin: true,
        onlyOwner: true
    },
    {
        route: PATH.ADMIN.TRANSLATIONS,
        name: 'Translations',
        onlyAdmin: true,
        onlyOwner: true
    },
    {
        route: PATH.ADMIN.SUBSCRIPTIONS,
        name: 'Subscriptions',
        onlyAdmin: true,
        onlyOwner: true
    },
]
export const SideBar = () => {
    const {isOwner, isAdmin} = useAuth()
    return (
        <SideBarWrapper>
            <MenuContainer>
                {options.map((option, index) => ((!option.onlyOwner && !option.onlyAdmin) || (option.onlyOwner && isOwner) || (option.onlyAdmin && isAdmin)) && (
                    <MenuLink key={index} menuOption={option}/>
                ))}
            </MenuContainer>
        </SideBarWrapper>
    )
}
const SideBarWrapper = styled.div`
  height: 60vh;
  background: ${({ theme }) => theme.primaryColor2};
  box-shadow: ${({ theme }) => theme.boxShadow2};
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 40px 0;
  padding: 20px;
  flex: 0 0 200px;
  max-width: 200px;  
`

const MenuContainer = styled.div`
    display: flex;
  flex-direction: column;
  gap: 10px;
    `
