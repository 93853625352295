import {lazy} from "react";

import PageNotFound from './PageNotFound';
import Admin from './Admin'
import Gifts from './Admin/Gifts';
import Users from './Admin/Users'
import Ownership from 'pages/Admin/Ownership'
import Banned from 'pages/Banned';
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import Contract from "pages/Admin/Contract";
import Withdrawals from "pages/Admin/Withdrawals";
import Report from "pages/Admin/Report";
import Locks from "pages/Admin/Locks";
import Translations from "pages/Admin/Translations";
import Subscriptions from "pages/Admin/Subscriptions";
import OurTeam from "pages/OurTeam";
import Roadmap from "pages/Roadmap";
import ContactUs from "pages/ContactUs";
const Home = lazy(() => import ('./Home'));
const Inventory = lazy(() => import ('./Inventory'));
const SignLocks = lazy(() => import ('./SignLocks'));
const LockPublicPage = lazy(() => import ('./LockPublicPage'));
const PublicKeyPage = lazy(() => import ('./PublicKey'));
const WEB_PAGES = {
  PAGE_NOT_FOUND: PageNotFound,
  HOME: Home,
  SIGN_LOCKS: SignLocks,
  INVENTORY: Inventory,
  PRIVACY_POLICY: PrivacyPolicy,
  TERMS_OF_SERVICE: TermsOfService,
  OUR_TEAM: OurTeam,
  ROAD_MAP: Roadmap,
  LOCK_DETAIL:LockPublicPage,
  CONTACT_US:ContactUs,
  PUBLIC_KEY_PAGE:PublicKeyPage,
  ADMIN: {
    HOME: Admin,
    GIFTS: Gifts,
    USERS: Users,
    OWNERSHIP: Ownership,
    CONTRACT:Contract,
    WITHDRAWALS: Withdrawals,
    REPORT: Report,
    LOCKS:Locks,
    TRANSLATIONS:Translations,
    SUBSCRIPTIONS:Subscriptions,
  },
  BANNED: Banned,
}

export { WEB_PAGES }
