import React, {useMemo} from 'react';
import Table from "rc-table";
import BoxNoData from "components/common/BoxNoData";
import {TableWrap} from "components/common/TableWrap";
import {Profile} from "@thelovebridge/core/src/entities";
interface PropsTypeProfileReportTable{
	data:Profile[]
}
const ProfileReportTable = ({data}:PropsTypeProfileReportTable) => {
	const columns = useMemo(() => {
		return [
			{
				title:'User Address',
				dataIndex: 'userAddress',
				key:'userAddress',
				width: 400
			},
			{
				title:'Reports Count',
				dataIndex: 'reportedCount',
				key:'reportedCount',
				width:120,
			},
			{
				title:'Role',
				dataIndex: 'role',
				key:'role',
				width:160,
			},
		]
	},[])
	return (<TableWrap>
		<Table
			columns={columns}
			data={data as any}
			rowKey={'userAddress'}
			sticky={true}
			className={'rc-sticky-cs'}
			emptyText={() => <BoxNoData />}
		/>
	</TableWrap>)
};

export default ProfileReportTable;