import React, {useMemo} from 'react';
import {Lock} from "@thelovebridge/core/src/entities/Lock";
import {TableWrap} from "components/common/TableWrap";
import Table from "rc-table";
import BoxNoData from "components/common/BoxNoData";
interface PropsTypeLockReportTable{
	data: Lock[]
}
const LockReportTable = ({data}:PropsTypeLockReportTable) => {
	const columns = useMemo(() => {
		return [
			{
				title:'NftID',
				dataIndex: 'nftId',
				key:'nftId',
				width: 66
			},
			{
				title:'User Address',
				dataIndex: 'userAddress',
				key:'userAddress',
				width: 380
			},
			{
				title:'Name',
				dataIndex: 'name',
				key:'name',
				width:200,
			},
			{
				title:'Reports Count',
				dataIndex: 'reportsCount',
				key:'reportsCount',
				width:120,
			},
		]
	},[])
	return (
		<TableWrap>
			<Table
				columns={columns}
				data={data as any}
				rowKey={'id'}
				sticky={true}
				className={'rc-sticky-cs'}
				emptyText={() => <BoxNoData />}
			/>
		</TableWrap>
	);
};

export default LockReportTable;
