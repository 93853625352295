import React, {useCallback, useEffect, useRef, useState} from 'react';
import {LayoutAdmin} from "components/layout";
import styled from "styled-components";
import {ButtonStPrimary} from "components/common/ButtonSt";
import {IMAGES} from 'assets'
import {Text2024, Text3236} from 'components/common/Typography';

import {useUploadTranslationsMutation} from "state/usersManagementState";
import {isJsonString} from "utils/isJsonString";
import {toast} from "react-toastify";
import {ToastError, ToastSuccess} from "components/common/Toasts";
import Loader from "components/common/Loader";
import axios from "axios";
import {Parameters} from "@thelovebridge/core/src/constants/ParametersConstants";
import {useTranslation} from "react-i18next";
import {POSITION_TOAST} from "constants/common";
const Translations = () => {
	const [uploadTranslation] = useUploadTranslationsMutation()
	const {t} = useTranslation()
	useEffect(() => {
		document.title = 'Translations || Admin Panel || The Love Bridge'
	}, [])
	const refFile = useRef<any>(null)
	const [fileJson,setFileJson] = useState<any>(undefined)
	const [dataUpload,setDataUpload] = useState<any>(undefined)
	const [isUploading,setIsUploading] = useState<boolean>(false)

	const handleFileUpload = useCallback((file: Blob) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.onload = event => {
				if (event.target) {
					const {result} = event.target
					const isCheckJson = isJsonString(result)
					if(isCheckJson){
						resolve(JSON.parse(event.target.result as string))
					}else{
						toast.error(<ToastError>{t('toast.uploadFileWarning')}</ToastError>, POSITION_TOAST)
						setDataUpload(undefined)
						if(refFile && refFile.current){
							refFile.current.value = ''
						}
					}
				}
				setFileJson(file)
			}
			fileReader.onerror = error => reject(error)
			fileReader.readAsText(file)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
	const handleChangeUploadFile = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return
		}
		const file = event.target.files[0]
		try{
			const res:any = await handleFileUpload(file)
			setDataUpload(res)
		}catch{
			toast.error(<ToastError>{t('toast.uploadFileFailed')}</ToastError>, POSITION_TOAST)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleFileUpload])

	const onDropFile = useCallback(async (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		event.stopPropagation()
		const dt = event.dataTransfer
		const file = dt.files[0]
		try{
			const res:any = await handleFileUpload(file)
			setDataUpload(res)
		}catch{
			toast.error(<ToastError>{t('toast.dropFileFailed')}</ToastError>, POSITION_TOAST)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleFileUpload])
	const onDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		event.stopPropagation()
	},[])
	const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		event.stopPropagation()
	},[])

	const handleUpload = () => {
		if(!dataUpload){
			return;
		}
		;(async () => {
			setIsUploading(true)
			try{
				const res: any = await uploadTranslation(dataUpload)
				const data = await res.data
				if(data){
					await axios.get(`${process.env.REACT_APP_URL_API}parameters/${Parameters.TRANSLATION}`)
					toast.success(<ToastSuccess>{data}</ToastSuccess>, POSITION_TOAST)
				}
			}catch {
				toast.error(<ToastError>{t('toast.uploadFileFailed')}</ToastError>, POSITION_TOAST)
			}finally {
				setIsUploading(false)
				setDataUpload(undefined)
				setFileJson(undefined)
			}
		})()
	}
	return (
		<LayoutAdmin>
			<Section>
				<TitleSection>{t('admin.translation.title')}</TitleSection>
				<SectionUpdateTranslation>
					<BoxUpload onDragEnter={onDragEnter} onDragOver={onDragOver} onDrop={onDropFile} className={'box-file'}>
						<input
							type="file"
							id="file_upload"
							accept={'.json,application/json'}
							hidden
							name={'file_upload'}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeUploadFile(e).then()}
							ref={refFile}
						/>
						<LabelUpload htmlFor={'file_upload'}>
							<TextUpload>
								<IMAGES.ICON_JSON/>
								<Text2024>
									{
										fileJson ? fileJson.name : <>{t('admin.translation.upload')}</>
									}
								</Text2024>
							</TextUpload>
						</LabelUpload>
					</BoxUpload>
					<CtaSubmit onClick={handleUpload} disabled={isUploading || !dataUpload}>
						{
							isUploading ? <>
								<Loader secondary={true} /><>{t('admin.translation.upload')}</>
							</> : <>{t('admin.translation.upload')}</>
						}
					</CtaSubmit>
				</SectionUpdateTranslation>
			</Section>
		</LayoutAdmin>
	);
}
export default Translations;
const Section = styled.section``
const TitleSection = styled(Text3236)`
	${({theme}) => theme.textBaseGradient};
	margin-bottom: 20px;
`
const SectionUpdateTranslation = styled.div`
	${({theme}) => theme.flexColumn};
	gap: 10px;
`
const BoxUpload = styled.div``
const LabelUpload = styled.label`
	width: 100%;
	border: 2px dotted ${({theme}) => theme.bgColor1};
	border-radius: 10px;
	height: 100px;
	display: block;
	${({theme}) => theme.flexRowCenter};
	cursor: pointer;
`
const TextUpload = styled.div`
  ${({theme}) => theme.flexRowCenter};
	gap: 10px;
  svg{
    width: 40px;
  }
`
const CtaSubmit = styled(ButtonStPrimary)`
  width: 100%;
	border-radius: 20px;
	padding: 10px 20px;
	${({theme}) => theme.flexRowCenter};
	gap: 10px;
`
