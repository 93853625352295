import React from 'react';
import styled from 'styled-components';

interface PropsTypeLayoutSecondary {
	children?: React.ReactNode
}
export const LayoutSecondary = ({ children }: PropsTypeLayoutSecondary) => {
	return (
		<LayoutSecondaryWrapper>
			{children && <Main>{children}</Main>}
		</LayoutSecondaryWrapper>
	)
}

const LayoutSecondaryWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryColor1};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const Main = styled.main`
  flex: 1 0 auto;
  margin-top: 138px;
	${({theme}) => theme.flexColumnCenter};
  @media only screen and (max-width: 1024px) {
    margin-top: 120px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 90px;
  }
  @media only screen and (max-width: 575px) {
    margin-top: 120px;
  }
`
