import React, {useEffect, useMemo, useState} from 'react'
import {useGetSaleInformationQuery} from "state/saleInformationSlice";
import {OptionType} from "components/common/SelectSingleCs/SelectType";
import {checkObjectEmpty} from "utils/checkObjectEmpty";
import {Currency} from "@thelovebridge/core/src/entities/Currency";
import {BigNumber} from "ethers";
import {SaleContext} from "providers/SaleProvider/SaleContext";
const SaleProvider = ({children}:{children:React.ReactNode}) => {
	const {data,refetch} = useGetSaleInformationQuery(undefined,{
		refetchOnMountOrArgChange:false
	})
	const [currencies,setCurrencies] = useState<OptionType[]>([{value: '', label: 'ETH'}])
	const dataCurrency = useMemo(() => {
		if(!data || checkObjectEmpty(data))
			return

		return {
			listCurrencies:data['paymentCurrencies'],
			priceInUSD: data['priceInUsd'],
			priceInGas: data['priceInGas'],
			isPaused: data['paused'],
		}
	},[data])

	useEffect(() => {
		if(!(dataCurrency) || !dataCurrency.listCurrencies)
			return

		setCurrencies(() => {
			const currencies = [...dataCurrency.listCurrencies].map((item:Currency) => {
				return{
					value: item.contractAddress,
					label: item?.symbol ?  item.symbol : ''
				}
			})
			return [
				{value: '', label: 'ETH'},
				...currencies,
				{value: 'card', label: 'CARD'},
			]
		})
	},[dataCurrency])
	return <SaleContext.Provider
		value={{
				currencies,
				priceInUSD: dataCurrency?.priceInUSD ? BigNumber.from(dataCurrency?.priceInUSD) : BigNumber.from(0),
				priceInGas: dataCurrency?.priceInGas ? BigNumber.from(dataCurrency?.priceInGas) : BigNumber.from(0),
				isPaused: dataCurrency?.isPaused ?? true,
				refetch
			}}
		>
			{children}
		</SaleContext.Provider>

}
export default SaleProvider
