import TheLoveBridgeNFTAbi from '@thelovebridge/core/src/contracts/abi/TheLoveBridgeNFT.json'
import ERC20Abi from '@thelovebridge/core/src/contracts/abi/ERC20.json'
import { OptionType } from 'components/common/SelectSingleCs/SelectType'
import { Contract } from '@ethersproject/contracts'
import { useSigner } from '@thirdweb-dev/react'
import { useMemo } from 'react'
import { ERC20, TheLoveBridgeNFT } from '@thelovebridge/core/src/contracts'

const contractAddress = String(process.env.REACT_APP_TLB_NFT_CONTRACT_ADDRESS);

export function useContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any
): T | null {
  const signer = useSigner()

  return useMemo(() => {
    if (!address || !ABI || !signer || !signer.provider) return null
    try {
      return new Contract(address, ABI, signer) as T
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, signer])
}

export function useNftContract() {
  return useContract<TheLoveBridgeNFT>(contractAddress, TheLoveBridgeNFTAbi)
}

export function useERC20(currency: OptionType | null) {
  return useContract<ERC20>(currency?.value, ERC20Abi)
}
