export enum ChainIds {
    MAINNET = 1,
    SEPOLIA = 11155111
}

export function getChainFromString(id?: string): ChainIds {
    return id ? getChainFromNumberOrThrow(parseInt(id)) : ChainIds.SEPOLIA;
}

export function getChainFromNumberOrThrow(id?: number): ChainIds {
    if (!id) throw Error('networkId is undefined');
    switch (id) {
        case 1:
            return ChainIds.MAINNET;
        case 11155111:
            return ChainIds.SEPOLIA;
        default:
            throw Error('network not supported');
    }
}
