import React, { useMemo } from 'react'
import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  safeWallet,
  trustWallet,
  bloctoWallet,
  frameWallet,
  rainbowWallet,
  phantomWallet, embeddedWallet
} from '@thirdweb-dev/react'
import {Sepolia, Ethereum } from '@thirdweb-dev/chains'

const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const getActiveChain = useMemo(() => {
    switch (process.env.REACT_APP_CHAIN_ID) {
      case '11155111': return Sepolia
      default: return Ethereum
    }
  }, [])

  return (
    <ThirdwebProvider
      clientId={process.env.REACT_APP_THIRD_WALLET_CLIENT_ID}
      activeChain={getActiveChain}
      authConfig={{
        authUrl: `${process.env.REACT_APP_URL_API}auth`,
        domain: `${process.env.REACT_APP_URL}`,
      }}
      autoConnect={true}
      supportedWallets={[
        metamaskWallet({ recommended: true }),
        coinbaseWallet(),
        walletConnect({ recommended: true }),
        safeWallet({
          personalWallets: [
            metamaskWallet({ recommended: true }),
            coinbaseWallet(),
            walletConnect({ recommended: true }),
            trustWallet(),
            bloctoWallet(),
            frameWallet(),
            rainbowWallet(),
            phantomWallet(),
          ],
        }),
        trustWallet(),
        bloctoWallet(),
        frameWallet(),
        rainbowWallet(),
        phantomWallet(),
        embeddedWallet({
          auth: {
            options: [
              "email",
              "google",
              "apple",
              "facebook",
            ],
          },
        }),
      ]}
    >
      {children}
    </ThirdwebProvider>
  )
}

export default WalletProvider
