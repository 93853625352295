import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { defaultParameters } from 'constants/baseUrl'
import { Currency } from '@thelovebridge/core/src/entities/Currency'

export const currenciesSlice = createApi({
  tagTypes: ['Currencies'],
  reducerPath: 'Currencies',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    editCurrency: build.mutation({
      query: (currency : Currency) => {
        return {
          url: `admin/currencies/edit`,
          method: 'PATCH',
          body: currency
        }
      }
    }),
  })
})
export const {
  useEditCurrencyMutation
} = currenciesSlice
