import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import { Lock, LockCreationRequest, LockEditableFields } from '@thelovebridge/core/src/entities/Lock'

export const userSlice = createApi({
  tagTypes: ['User','Locks','LocksPartner'],
  reducerPath: 'User',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    getProfile: build.query<void,void>({
      query:() => {
        return {
          url: 'profiles',
          method: 'GET'
        }
      }
    }),
    updateEmailUser: build.mutation({
      query: ({ email }: { email: string }) => {
        return {
          url: 'profiles/email',
          method: 'PATCH',
          body: {
            email
          }
        }
      }
    }),
    createLock: build.mutation({
      query: (body: LockCreationRequest) => {
        return {
          url: 'locks/create',
          method: 'POST',
          body
        }
      }
    }),
    convertEmptyLock: build.mutation({
      query: (data: { nftId: string, lockCreationRequest: LockCreationRequest }) => {
        return {
          url: `locks/${data.nftId}/empty`,
          method: 'PATCH',
          body: data.lockCreationRequest
        }
      }
    }),
    getLocks: build.query<any, void>({
      query: () => {
        return {
          url: 'locks',
          method: 'GET'
        }
      },
      providesTags: ['Locks'],
    }),
    getLock:build.query({
      query:(id:string) =>{
        return{
          url: `locks/${id}`,
          method: 'GET'
        }
      },
    }),
    getLockEdit:build.query<Lock, string>({
      query: (nftId: string) =>{
        return{
          url: `locks/${nftId}/private`,
          method: 'GET'
        }
      },
    }),
    editLock: build.mutation({
      query: ({ data: body, nftId }: { data: LockEditableFields, nftId: string }) => {
        return {
          url: `locks/${nftId}`,
          method: 'PATCH',
          body
        }
      }
    }),
    signUp: build.mutation({
      query: (data : Partial<{name:string,email:string}>) => {
        return {
          url: 'subscription',
          method: 'POST',
          body: data
        }
      }
    }),
    cosignCouple: build.mutation({
      query:({nftId,cosignFromEmpty}:{nftId: number, cosignFromEmpty: boolean})=> {
        return{
          url: `locks/${nftId}/cosign`,
          method:'PATCH',
          body:{
            nftId: cosignFromEmpty ? String(nftId) : null
          }
        }
      }
    }),

    deletePendingLock: build.mutation({
      query:(nftId:number) => {
        return{
          url: `locks/${nftId}`,
          method:'DELETE',
        }
      }
    }),

    markAsMinting: build.mutation({
      query:(tempId:number) => {
        return {
          url: `locks/${tempId}/markAsMinting`,
          method: 'PATCH'
        }
      }
    }),

    getMintSignature:build.query<any,string>({
      query:(nftId:string)=> {
        return{
          url:`locks/${nftId}/mint-signature`,
          method:'GET'
        }
      }
    }),
    getMintSignatureEmpty:build.query<any,string>({
      query:(nftId:string)=> {
        return{
          url:`locks/${nftId}/mint-signature-empty`,
          method:'GET'
        }
      }
    }),
    getMetadataSignature: build.query<any,any>({
      query:({nftId, customImageUrl}:{nftId:string,customImageUrl:string})=> {
        return{
          url:`locks/${nftId}/metadata-signature`,
          method:'GET',
          params:{
            customImageUrl
          }
        }
      }
    }),
    getBreakSignature:build.query<any,string>({
      query:(nftId: string)=> {
        return{
          url:`locks/${nftId}/break-signature`,
          method:'GET'
        }
      }
    }),
    getRestoreSignature: build.query<any,string>({
      query:(nftId: string)=> {
        return{
          url:`locks/${nftId}/restore-signature`,
          method:'GET'
        }
      }
    })
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true
})
export const {
  useUpdateEmailUserMutation,
  useEditLockMutation,
  useCreateLockMutation,
  useSignUpMutation,
  useLazyGetLockEditQuery,
  useGetLocksQuery,
  useLazyGetBreakSignatureQuery,
  useLazyGetRestoreSignatureQuery,
  useLazyGetMintSignatureQuery,
  useCosignCoupleMutation,
  useConvertEmptyLockMutation,
  useDeletePendingLockMutation,
  useLazyGetMetadataSignatureQuery,
  useMarkAsMintingMutation,
  useLazyGetMintSignatureEmptyQuery
} = userSlice
