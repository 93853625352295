import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import {Profile} from "@thelovebridge/core/src/entities";
import {Lock} from "@thelovebridge/core/src/entities/Lock";

export const reportManagementSlice = createApi({
	tagTypes: ['ReportAdmin'],
	reducerPath: 'ReportAdmin',
	baseQuery: fetchBaseQuery(defaultParameters),
	endpoints: (build) => ({
		getReportManagement:build.query<{reportedLocks:Lock[],reportedProfiles:Profile[]},void>({
			query:() => {
				return{
					url: 'admin/report',
					method:'GET'
				}
			},
			providesTags: ['ReportAdmin']
		})
	})
})
export const {
	useGetReportManagementQuery,
} = reportManagementSlice
