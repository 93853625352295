import {OptionType} from 'components/common/SelectSingleCs/SelectType';
import { LockVariation } from '@thelovebridge/core/src/constants/LockVariation'
import { LockType } from '@thelovebridge/core/src/constants/LockType'
import { LockPlacement } from '@thelovebridge/core/src/constants/LockPlacement'
import { LockVisibility } from '@thelovebridge/core/src/constants/LockVisibility'
import {GiftStatus} from "@thelovebridge/core/src/entities/Gifts";

export const COLORS_LOCK: OptionType[] = [
  {
    label: LockVariation.GREEN,
    value: LockVariation.GREEN
  },
  {
    label: LockVariation.RED,
    value: LockVariation.RED
  },
  {
    label: LockVariation.ORANGE,
    value: LockVariation.ORANGE
  },
  {
    label: LockVariation.YELLOW,
    value: LockVariation.YELLOW
  },
  {
    label: LockVariation.PURPLE,
    value: LockVariation.PURPLE
  },
  {
    label: LockVariation.PINK,
    value: LockVariation.PINK
  },
  {
    label: LockVariation.BLUE,
    value: LockVariation.BLUE
  },
  {
    label: 'NEON PINK',
    value: LockVariation.NEON
  }
]
export const LockTypeOptions = [
  {
    value: LockType.COUPLE,
    label: 'Dual Sign'
  },
  {
    value: LockType.SINGLE,
    label: 'Single Sign'
  },
  {
    value: LockType.EMPTY,
    label: 'Empty'
  }
]
export const PLACEMENT_ON=[
  {
    value:LockPlacement.ON_THE_CLOUDS,
    label:'A floating cloud'
  },
  {
    value:LockPlacement.ON_THE_BRIDGE,
    label:'The bridge'
  }
]
export const VISIBILITY_LOCK:OptionType[] = [
  {
    value: 'ALL',
    label: 'ALL'
  },
  {
    value: LockVisibility.PRIVATE,
    label: LockVisibility.PRIVATE
  },
  {
    value: LockVisibility.PUBLIC,
    label: LockVisibility.PUBLIC
  },
]
export const TypeSignNotEmpty = [
  {
    value: 'ALL',
    label: 'ALL'
  },
  ...LockTypeOptions.filter((item:OptionType) => item.value !== LockType.EMPTY)
]
export const STATUS_GIFT:OptionType[] = [
  {
    value: 'ALL',
    label: 'ALL'
  },
  {
    value: GiftStatus.SENT,
    label: GiftStatus.SENT
  },
  {
    value: GiftStatus.CANCELLED,
    label: GiftStatus.CANCELLED
  },
  {
    value: GiftStatus.CLAIMED,
    label: GiftStatus.CLAIMED
  },
  {
    value: 'EXPIRED',
    label: 'EXPIRED'
  },
]
