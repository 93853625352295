import 'rc-pagination/assets/index.css';

import styled from 'styled-components';

export const PaginationSt = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;

  & button {
    background: none !important;
    border: none !important;
    &:after {
      margin-top: -5px;
      display: inline-flex;
      ${({ theme }) => theme.text1416};
      color: ${({ theme }) => theme.white};
      letter-spacing: 0.96px;
    }
  }
  .rc-pagination {
    display: flex;
    align-items: center;
    gap: 12px;
    * {
      font-family: ${({ theme }) => theme.fontArial};
    }
    @media only screen and (max-width: 1024px) {
      gap: 10px;
    }
    @media (max-width: 575px) {
      gap: 4px;
    }
  }
  & .rc-pagination-item {
    min-width: 36px;
    height: 36px;
    border-radius: 0;
    ${({ theme }) => theme.flexRowCenter};
    background: none !important;
    border: none;
    margin: 0;
    & a {
      color: ${({ theme }) => theme.primaryColor8};
      padding: 0;
      ${({ theme }) => theme.text1416};
    }

    &-active {
      background: ${({ theme }) => theme.bgPrimary} !important;
      border-radius: 6px;
      &:hover {
        a {
          color: ${({ theme }) => theme.white};
        }
      }
      & a {
        color: ${({ theme }) => theme.white};
      }
    }
    @media (max-width: 768px) {
      min-width: 32px;
      height: 32px;
      padding: 2px;
    }
    @media (max-width: 575px) {
      min-width: 28px;
      height: 28px;
    }
  }
  & .rc-pagination-simple-pager {
    color: ${({ theme }) => theme.white};

    input,
    span {
      background: none;
      color: ${({ theme }) => theme.white};
      border: none;
      font-size: 14px !important;
    }

    input {
      padding: 0;
      margin-right: -5px;
      font-size: 15px !important;
    }
  }
  & .rc-pagination-jump-next,
  .rc-pagination-jump-prev {
    margin-right: 0;
    .rc-pagination-item-link::after {
      font-size: ${({ theme }) => theme.text1618};
      color: ${({ theme }) => theme.primaryColor8};
    }
  }
  & .rc-pagination-prev,
  .rc-pagination-next {
    margin-right: 0;
    ${({ theme }) => theme.flexRowCenter};
    font-weight: 700;
  }
  .rc-pagination-disabled {
    opacity: 0.4;
    font-weight: 400;
  }
`
export const TextDbNextPrev = styled.span`
	${({theme}) => theme.text1618};
	color: ${({theme}) => theme.primaryColor8};
`
