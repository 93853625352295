import { useMemo } from 'react'
import { useChainId, useSwitchChain } from '@thirdweb-dev/react'

const expectedChainId = Number(process.env.REACT_APP_CHAIN_ID);

export const useEnsureNetwork = () => {
  const chainId = useChainId()
  const switchChain = useSwitchChain()
  const isExpectedNetwork = useMemo(() => expectedChainId === chainId, [chainId])

  const ensureNetwork = async (): Promise<boolean> => {
    try {
      await switchChain(expectedChainId)
      return true
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  return {isExpectedNetwork, ensureNetwork}
}
