import React, {useEffect, useState} from 'react';
import {LayoutPrimary} from "components/layout";
import styled from "styled-components";
import {Text24Arial, Text8484} from "components/common/Typography";
import {useTranslation} from "react-i18next";
import withHelmet from "components/hoc/withHelmet";

const TermsOfService = () => {
	useEffect(() => {
		document.title = 'Terms of service || The Love Bridge'
	}, [])
	const {t} = useTranslation()
	const [numberIndexTerm,setNumberIndexTerm] = useState<number[]>([])
	useEffect(() => {
		const listIndex = Object.keys(t('terms.paragraphAll', {returnObjects: true}))
		setNumberIndexTerm(() => {
			return listIndex.map((item:string) => Number(item))
		})
	},[t])
	return (
		<LayoutPrimary>
			<SectionTermService>
				<Header>
					<Title>{t('terms.title')}</Title>
					<SubTitle>{t('terms.subTitle')}</SubTitle>
				</Header>
				<Main>
					{
						numberIndexTerm.length > 0 && numberIndexTerm.map((index:number)=>{
							return <Paragraph key={index}>
								<TitleParagraph>{t(`terms.paragraphAll.${index}.heading`)}</TitleParagraph>
								<DivContent dangerouslySetInnerHTML={{__html:t(`terms.paragraphAll.${index}.content`)}}/>
							</Paragraph>
						})
					}
				</Main>
			</SectionTermService>
		</LayoutPrimary>
	);
};

export default withHelmet(TermsOfService);
const SectionTermService = styled.section`
	${({theme}) => theme.container};
	padding-left: 190px;
	padding-right: 190px;
`
const Header = styled.div`
	${({theme}) => theme.flexColumnCenter};
	gap: 40px;
	margin-bottom: 16px;
`
const Title = styled(Text8484)`
`
const SubTitle = styled.div`
	${({theme}) => theme.text2832};
	${({theme}) => theme.textBaseGradient};
	font-family: ${({theme}) => theme.fontArial};
	text-transform: uppercase;
	font-weight: 700;
`
const Main = styled.div`
	padding-right: 48px;
	max-height: 60vh;
	overflow-y: auto;

  &::-webkit-scrollbar-track {
    border-radius: 19px;
    background-color: ${({theme}) => theme.primaryColor4};
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 19px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: ${({theme}) => theme.primaryColor13};
  }
	ol li{
		font-family: ${({theme}) => theme.fontArial};
    color: ${({ theme }) => theme.primaryColor9};
		${({theme}) => theme.text1618};
	}
`

const Paragraph = styled.div`
	margin-bottom: 20px;
`
const TitleParagraph = styled(Text24Arial)`
	color: ${({theme}) => theme.primaryColor10};
	font-weight: 700;
	margin-bottom: 8px;
`
const DivContent = styled.div`
  p{
		${({ theme }) => theme.text2024};
    font-family: ${({theme}) => theme.fontArial};
    color: ${({ theme }) => theme.secondaryColor2};
    a{
      font-family: ${({theme}) => theme.fontArial};
      color: ${({ theme }) => theme.primaryColor9};
    }
  }
	span{
    ${({ theme }) => theme.text2024};
    font-family: ${({ theme }) => theme.fontArial};
    color: ${({ theme }) => theme.secondaryColor2};
	}
`
