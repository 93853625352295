const ERROR = {
  SYSTEM_ERROR: 'System error. Please try again later!'
}
const PATH = {
  NO_PAGE: '*',
  HOME: '/',
  SIGN_LOCKS: '/sign-locks',
  SIGN_NEW_LOCK_VARIATION: '/sign-locks/:variation',
  INVENTORY: '/inventory',
  LOCK: '/edit-lock/:id',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  OUR_TEAM: '/our-team',
  ROAD_MAP: '/road-map',
  CONTACT_US: '/contact-us',
  LOCK_DETAIL: '/lock/:id',
  PUBLIC_KEY_PAGE: '/public-key',
  ADMIN: {
    HOME: '/admin',
    GIFTS: '/admin/gifts',
    USERS: '/admin/users',
    OWNERSHIP: '/admin/ownership',
    CONTRACT: '/admin/contract',
    WITHDRAWALS: '/admin/withdrawals',
    REPORT: '/admin/report',
    LOCKS:'/admin/locks',
    TRANSLATIONS: '/admin/translations',
    SUBSCRIPTIONS: '/admin/subscriptions',
  },
  BANNED: '/banned'
}

export { ERROR, PATH }
