import LogoBridge from './image/logo.png';
import LogoFooter from './image/LogoFooter.png';
import Gallery1 from './image/gallery1.jpg';
import Gallery2 from './image/gallery2.jpg';
import Gallery3 from './image/gallery3.jpg';
import Gallery4 from './image/gallery4.jpg';
import Gallery5 from './image/gallery5.jpg';
import Gallery6 from './image/gallery6.jpg';
import Gallery7 from './image/gallery7.jpg';
import Gallery8 from './image/gallery8.jpg';
import Gallery9 from './image/gallery9.jpg';
import LockFly1 from './image/lock_fly1.png';
import LockFly2 from './image/lock_fly2.png';
import { ReactComponent as ChevronDown } from './svg/chevron-down.svg';
import LogoSecondary from './image/logo_secondary.png';
import MediaDefault from './svg/icon_media.svg';
import { ReactComponent as IconUser } from './svg/icon_user.svg';
import { ReactComponent as IconInfo } from './svg/icon_info.svg';
import { ReactComponent as IconShare } from './svg/icon_share.svg';
import ImageCollectionEx from './image/imgCollectionEx.png';
import { ReactComponent as IconFacebook } from './svg/icon_facebook.svg';
import { ReactComponent as IconTwitter } from './svg/icon_twitter.svg';
import { ReactComponent as IconInstagram } from './svg/icon_instagram.svg';
import { ReactComponent as IconClose } from './svg/icon_close.svg';
import { ReactComponent as IconPlus } from './svg/icon_plus.svg';
import { ReactComponent as IconChevronRight } from './svg/icon_chevron_right.svg';
import Gift from './image/gift.png';
import Gift2 from './image/gift2.png';
import GiftClaim from './image/giftClaim.png';
import Confetti1 from './image/confetti1.png';
import Confetti2 from './image/confetti2.png';
import EmptyLock from './image/empty.png';
import LockLove from './image/lock_love.png';
import {ReactComponent as IconMinus} from "./svg/icon_minus.svg";
import {ReactComponent as IconPlus2} from "./svg/icon_plus2.svg";
import {ReactComponent as IconChevronDbRight} from "./svg/icon_chevron_db_right.svg";
import Heart from "./image/heart.png";
import { ReactComponent as IconCopy } from './svg/icon_copy.svg';
import { ReactComponent as IconInfo2 } from './svg/icon_info2.svg';
import { ReactComponent as IconExit } from './svg/icon_exit.svg';
import { ReactComponent as IconHeart } from './svg/icon_heart.svg';
import { ReactComponent as IconExpand } from './svg/icon_expand.svg';
import Scene2Crop from './image/scene-crops/scene-2-crop.png';
import EmptyLockSmall from './image/empty_lock_sm.png';
import IconUSDT from './image/icon_USDT.png';
import IconETH from './image/icon_ETH.png';
import IconUSDC from './image/icon_USDC.png';
import IconUSD from './image/icon_USD.png';
import { ReactComponent as IconDangerous } from './svg/icon_dangerous.svg';
import { ReactComponent as IconTelegram } from './svg/icon_telegram.svg';
import { ReactComponent as IconCalculate } from './svg/icon_calculate.svg';
import { ReactComponent as IconLocation } from './svg/icon_location.svg';
import { ReactComponent as IconChevronDoubleDown } from './svg/icon_chevrondoubledown.svg';
import IconEdit from './svg/icon_edit.svg';
import { ReactComponent as IconLinkedin } from './svg/icon_linkedIn.svg';
import TeamClinton from './image/teams/clinton.jpg';
import TeamLoosid from './image/teams/loosid.jpg';
import TeamDandelionlabs from './image/teams/dandelionlabs.jpg';
import TeamEmma from './image/teams/emma.jpg';
import TeamAlperOzkul from './image/teams/alperOzkul.jpg';
import TeamSabir from './image/teams/sabirPropper.jpg';
import TeamSusan from './image/teams/susan.jpg';
import TeamSaifur from './image/teams/saifur.jpg';
import TeamMattGray from './image/teams/mattGray.jpg';
import TeamAndyPringle from './image/teams/andyPringle.jpg';
import TeamDanielDietrich from './image/teams/danielDietrich.jpg';
import TeamYuson from './image/teams/yuson.jpg';
import PartnerPineApple from './image/partners/pineapple.png';
import PartnerBminted from './image/partners/bminted.png';
import PartnerDandelionlabs from './image/partners/dandelionlabs.png';
import DownLP from './image/down_l-p.png';
import DownRP from './image/down_r-p.png';
import { ReactComponent as IconDiscord } from './svg/icon_discord.svg';
import { ReactComponent as IconDice } from './svg/icon_dice.svg';
import NoImage from './image/no_image.png';
import { ReactComponent as IconChevronDown2 } from './svg/icon_chevron_down2.svg';
import { ReactComponent as IconJson } from './svg/icon_file_json.svg';
import { ReactComponent as IconInformation } from './svg/icon_information.svg';
import { ReactComponent as IconSearch } from './svg/icon_search.svg';
import AnchorMask1_1 from './image/scene-crops/anchor_mask_1_1.png';
import AnchorMask1_2 from './image/scene-crops/anchor_mask_1_2.png';
import AnchorMask2_1 from './image/scene-crops/anchor_mask_2_1.png';
import AnchorMask2_2 from './image/scene-crops/anchor_mask_2_2.png';
import AnchorMask4_1 from './image/scene-crops/anchor_mask_4_1.png';
import AnchorMask4_2 from './image/scene-crops/anchor_mask_4_2.png';
import AnchorMask4_3 from './image/scene-crops/anchor_mask_4_3.png';
import AnchorMask5_1 from './image/scene-crops/anchor_mask_5_1.png';
import AnchorMask5_2 from './image/scene-crops/anchor_mask_5_2.png';
import AnchorMask3_1_1 from './image/scene-crops/anchor_mask_3_1_1.png';
import AnchorMask3_2_1 from './image/scene-crops/anchor_mask_3_2_1.png';
import AnchorMask3_3_1 from './image/scene-crops/anchor_mask_3_3_1.png';
import AnchorMask3_4_1 from './image/scene-crops/anchor_mask_3_4_1.png';
import Bg_Cloud from './image/bgCloud.jpeg';
import Only_Cloud from './image/onlyCloud.png';
import Bg_Bubble from './image/bgBubble.jpeg';
import Bg_Bubble1 from './image/bgBubble1.jpeg';
export const IMAGES = {
  LogoBridge: LogoBridge,
  LOGO_FOOTER: LogoFooter,
  GALLERY1: Gallery1,
  GALLERY2: Gallery2,
  GALLERY3: Gallery3,
  GALLERY4: Gallery4,
  GALLERY5: Gallery5,
  GALLERY6: Gallery6,
  GALLERY7: Gallery7,
  GALLERY8: Gallery8,
  GALLERY9: Gallery9,
  LOCK_RAIN_BOW: Gallery8,
  LOCK_FLY1: LockFly1,
  LOCK_FLY2: LockFly2,
  CHEVRON_DOWN: ChevronDown,
  LOGO_SECONDARY: LogoSecondary,
  MEDIA_DEFAULT: MediaDefault,
  ICON_USER: IconUser,
  ICON_INFO: IconInfo,
  IMAGE_COLLECTION_EX: ImageCollectionEx,
  ICON_SHARE: IconShare,
  ICON_FACEBOOK: IconFacebook,
  ICON_TWITTER: IconTwitter,
  ICON_INSTAGRAM: IconInstagram,
  ICON_COPY: IconCopy,
  ICON_CLOSE: IconClose,
  ICON_PLUS: IconPlus,
  ICON_CHEVRON_RIGHT: IconChevronRight,
  GIFT: Gift,
  GIFT2:Gift2,
  GIFT_CLAIM:GiftClaim,
  CONFETTI1:Confetti1,
  CONFETTI2:Confetti2,
  EMPTY_LOCK:EmptyLock,
  LOCK_LOVE:LockLove,
  ICON_MINUS:IconMinus,
  ICON_PLUS2:IconPlus2,
  ICON_CHEVRON_DB_RIGHT:IconChevronDbRight,
  HEART:Heart,
  ICON_INFO2:IconInfo2,
  ICON_EXIT:IconExit,
  ICON_HEART:IconHeart,
  ICON_EXPAND:IconExpand,
  EMPTY_LOCK_SMALL:EmptyLockSmall,
  ICON_ETH:IconETH,
  ICON_USDT:IconUSDT,
  ICON_USDC:IconUSDC,
  ICON_DANGEROUS:IconDangerous,
  ICON_TELEGRAM:IconTelegram,
  ICON_CALCULATE:IconCalculate,
  ICON_LOCATION:IconLocation,
  ICON_CHEVRONDOUBLEDOWN:IconChevronDoubleDown,
  ICON_USD:IconUSD,
  ICON_EDIT:IconEdit,
  ICON_LINKEDIN:IconLinkedin,
  TEAM_CLINTON: TeamClinton,
  TEAM_LOOSID: TeamLoosid,
  TEAM_DANDESLIONLABS: TeamDandelionlabs,
  TEAM_EMMA: TeamEmma,
  TEAM_ALPER: TeamAlperOzkul,
  TEAM_SABIR: TeamSabir,
  TEAM_SUSAN: TeamSusan,
  TEAM_SAIFUR: TeamSaifur,
  TEAM_MATT: TeamMattGray,
  TEAM_ANDY: TeamAndyPringle,
  TEAM_DANIEL: TeamDanielDietrich,
  TEAM_YUSON: TeamYuson,
  PARTNER_PINEAPPLE:PartnerPineApple,
  PARTNER_BMINTED:PartnerBminted,
  PARTNER_DANDELIONLABS:PartnerDandelionlabs,
  DOWN_LP:DownLP,
  DOWN_RP:DownRP,
  ICON_DISCORD:IconDiscord,
  NO_IMAGE:NoImage,
  ICON_DICE:IconDice,
  ICON_CHEVRON_DOWN2:IconChevronDown2,
  ICON_JSON:IconJson,
  ICON_INFORMATION:IconInformation,
  ICON_SEARCH:IconSearch,
  BG_CLOUD: Bg_Cloud,
  ONLY_CLOUD: Only_Cloud,
  BG_BUBBLE: Bg_Bubble,
  BG_BUBBLE1: Bg_Bubble1,
}

export const SCENE_CROPS = {
  TWO: Scene2Crop,

  ANCHOR_MASK_1_1: AnchorMask1_1,
  ANCHOR_MASK_1_2: AnchorMask1_2,

  ANCHOR_MASK_2_1: AnchorMask2_1,
  ANCHOR_MASK_2_2: AnchorMask2_2,

  ANCHOR_MARK_3_1: AnchorMask3_1_1,
  ANCHOR_MARK_3_2: AnchorMask3_2_1,
  ANCHOR_MARK_3_3: AnchorMask3_3_1,
  ANCHOR_MARK_3_4: AnchorMask3_4_1,

  ANCHOR_MASK_4_1: AnchorMask4_1,
  ANCHOR_MASK_4_2: AnchorMask4_2,
  ANCHOR_MASK_4_3: AnchorMask4_3,

  ANCHOR_MASK_5_1: AnchorMask5_1,
  ANCHOR_MASK_5_2: AnchorMask5_2,

}
