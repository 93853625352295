import React, { useMemo } from 'react';
import styled, { css } from 'styled-components'

interface PropsTypeLoader{
	marginRight?:number
	width?:number
	secondary?:boolean
}
const Loader = ({marginRight, width,secondary=false}:PropsTypeLoader) => {
	const widthAttr = useMemo(() => {
		if(!width){
			return 24
		}
		return width
	},[width])
	return (
		<LoaderBox marginRight={marginRight} width={widthAttr} secondary={secondary}/>
	);
};

export default Loader;
const LoaderBox = styled.div<{marginRight?: number|undefined, width:number,secondary?:boolean | undefined}>`
  width: ${({width}) => width}px;
  height: ${({width}) => width}px;
  border: 3px solid ${({theme}) => theme.primaryColor8};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  &::after {
		${({marginRight}) => marginRight ? css`
			margin-right: ${marginRight}px;
		` : ''}
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${({width}) => width+8}px;
    height: ${({width}) => width+8}px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: ${({theme,secondary}) => secondary ? theme.primaryColor8 : theme.white};
  }
`
