import React from 'react'
import { ModalConnectStatusType } from 'components/common/Modal/ModalType'
import { Profile } from '@thelovebridge/core/src/entities'
import { createContext } from 'react'
import { MODAL_CONNECT_STATUS } from 'components/common/Modal/Modal.const'

interface IAuthContext {
  isConnected: boolean
  handleDisconnect: () => void
  popupView: ModalConnectStatusType
  setPopupView: React.Dispatch<React.SetStateAction<ModalConnectStatusType>>
  showModalConnect: boolean
  setShowModalConnect: React.Dispatch<React.SetStateAction<boolean>>
  user?:Profile
  address?:string
  isOwner:boolean
  isAdmin:boolean
  isBanned:boolean
  isSigned:boolean
  isLoading: boolean
  isWalletMetamask: boolean
  isSignWithEmail: boolean
  isNotProductionEnv: boolean
}

const initialState: IAuthContext = {
  isConnected: false,
  handleDisconnect: () => {},
  popupView: MODAL_CONNECT_STATUS.CONNECT,
  setPopupView: () => {},
  showModalConnect: true,
  setShowModalConnect: () => {},
  user:undefined,
  address: undefined,
  isOwner: false,
  isAdmin: false,
  isBanned: false,
  isSigned: false,
  isLoading: false,
  isWalletMetamask: false,
  isSignWithEmail: false,
  isNotProductionEnv: false,
}

export const AuthContext = createContext<IAuthContext>(initialState)
