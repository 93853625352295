import React, {useEffect, useMemo} from 'react';
import styled from "styled-components";
import {MessageError, Text100120, Text2832Base} from "components/common/Typography";
import {BoxSt} from "components/common/BoxSt";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {FormInputSec} from "components/common/FormInput";
import {ButtonStPrimary} from "components/common/ButtonSt";
import {FormTextAreaSt} from "components/common/FormInputSt";
import {useSendContactMutation} from "state/contactState";
import {toast} from "react-toastify";
import {ToastError, ToastSuccess} from "components/common/Toasts";
import Loader from "components/common/Loader";
import {LayoutPrimary} from "components/layout";
import useAuth from "hooks/useAuth";
import {useTranslation} from "react-i18next";
import {BoxStType} from "models/common";
import {POSITION_TOAST} from "constants/common";

const ContactUs = () => {
	const {t} = useTranslation()
	const [sendContact] = useSendContactMutation()
	const {user} = useAuth()
	const emailProfile = useMemo(() => {
		if(!user || !user.email){
			return
		}
		return user.email
	},[user])
	const formikContact = useFormik({
		initialValues: {
			userName: '',
			userEmail: '',
			subject:'',
			message:''
		},
		validationSchema: Yup.object().shape({
			userName: Yup.string().required('Name is required'),
			subject: Yup.string().required('Subject is required'),
			userEmail: Yup.string()
				.matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'Invalid email')
				.required('Email is required'),
			message: Yup.string().required('Message is required'),
		}),
		onSubmit: (values:FormikValues) => {
			const {userName,subject,userEmail,message} = values
			;(async () => {
				setSubmitting(true)
				try{
					const dataSend = {
						name:userName,
						subject,
						message,
						email:userEmail
					}
					const response:any = await sendContact(dataSend)
					if('data' in response){
						toast.success(<ToastSuccess>{t('toast.sendContactSuccess')}</ToastSuccess>, POSITION_TOAST)
					}else{
						toast.error(<ToastError>{t('toast.tryAgain')}</ToastError>, POSITION_TOAST)
					}
				}catch{
					toast.error(<ToastError>{t('toast.tryAgain')}</ToastError>, POSITION_TOAST)
				}finally {
					setSubmitting(false)
					resetForm()
				}
			})()
		}
	})
	const {handleChange,handleBlur,values,errors,handleSubmit,setSubmitting,isSubmitting,dirty,isValid,setFieldValue,resetForm} = formikContact
	useEffect(()=>{
		if(!emailProfile){
			return
		}
		setFieldValue('userEmail',emailProfile)
	},[emailProfile,setFieldValue])
	useEffect(() => {
		document.title = 'Contact us || The Love Bridge'
	}, [])

	return (
		<LayoutPrimary>
			<ContactBody>
				<Introduce>
					<Title>{t('contact.title')}</Title>
					<DescriptionBox size={BoxStType.LARGE} boxGradient={true}>
						<Description>
							{t('contact.description')}
						</Description>
					</DescriptionBox>
				</Introduce>
				<FormBox>
					<Form onSubmit={handleSubmit}>
						<FormGroup>
							<FormLabel>{t('contact.form.name.label')} *:</FormLabel>
							<FormInputSec value={values.userName ?? ''} placeholder={t('contact.form.name.placeholder')} onChange={handleChange} onBlur={handleBlur} name={'userName'} id={'userName'}/>
							{
								errors?.userName && values.userName && <MessageError>{errors.userName as any}</MessageError>
							}
						</FormGroup>
						<FormGroup>
							<FormLabel>{t('contact.form.subject.label')} *:</FormLabel>
							<FormInputSec value={values.subject ?? ''} placeholder={t('contact.form.subject.placeholder')} onChange={handleChange} onBlur={handleBlur} name={'subject'} id={'subject'}/>
							{
								errors?.subject && values.subject && <MessageError>{errors.subject as any}</MessageError>
							}
						</FormGroup>
						<FormGroup>
							<FormLabel>{t('contact.form.email.label')} *:</FormLabel>
							<FormInputSec value={values.userEmail ?? ''} placeholder={t('contact.form.email.placeholder')} onChange={handleChange} onBlur={handleBlur} name={'userEmail'} id={'userEmail'}/>
							{
								errors?.userEmail && values.userEmail && <MessageError>{errors.userEmail as any}</MessageError>
							}
						</FormGroup>
						<FormGroup>
							<FormLabel>{t('contact.form.message.label')} *:</FormLabel>
							<FormArea value={values.message ?? ''} placeholder={t('contact.form.message.placeholder')} onChange={handleChange} onBlur={handleBlur} name={'message'} id={'message'}/>
							{
								errors?.message && values.message && <MessageError>{errors.message as any}</MessageError>
							}
						</FormGroup>
						<CtaSignUp type={'submit'} disabled={isSubmitting || !dirty || !isValid}>
							{
								isSubmitting ? <>
									<Loader secondary={true}/>
									{t('button.contact')}
								</> : <>{t('button.contact')}</>
							}
						</CtaSignUp>
					</Form>

				</FormBox>
			</ContactBody>
		</LayoutPrimary>
	);
};

export default ContactUs;

const ContactBody = styled.div`
	${({theme}) => theme.container};
	max-width: 1000px;
`
const Introduce = styled.div``
const Title = styled(Text100120)`
  text-align: center;
`
const DescriptionBox = styled(BoxSt)`
	width: 100%;
  max-width: 1000px;
	margin: 0 auto;
`
const Description = styled(Text2832Base)`
  text-align: center;
  font-style: italic;
  letter-spacing: -0.4px;
	width: 100%;
`
const FormBox = styled.div`
  max-width: 600px;
  width: 100%;
  background: ${({ theme }) => theme.primaryColor2};
  box-shadow: ${({ theme }) => theme.boxShadow3};
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 20px;
  margin: 40px auto;
  ${({theme}) => theme.flexColumnCenter};

`
const Form = styled.form`
	width: 100%;
  ${({theme}) => theme.flexColumnCenter};
`
const FormGroup = styled.div`
	${({theme}) => theme.flexColumn};
	gap: 6px;
	margin-bottom: 10px;
	width: min(400px, 100%);
`
const FormLabel = styled.label`
	${({theme}) => theme.text1620};
	color: ${({theme}) => theme.primaryColor8};
	font-family: ${({theme}) => theme.fontArial};
	text-transform: capitalize;
`
const CtaSignUp= styled(ButtonStPrimary)`
	width: 160px;
	${({theme}) => theme.text2024};
	padding: 10px;
	border-radius: 20px;
	${({theme}) => theme.flexRowCenter};
	gap: 6px;
`
const FormArea = styled(FormTextAreaSt)`
  background-color: ${({ theme }) => theme.primaryColor11};
  color: ${({ theme }) => theme.primaryColor8};
  &::placeholder {
    color: ${({ theme }) => theme.primaryColor5};
  }
`
