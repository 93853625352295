import {OptionType} from "components/common/SelectSingleCs/SelectType";
import {createContext} from "react";
import {BigNumber} from "ethers";

interface ISaleContext{
	currencies: OptionType[]
	priceInUSD: BigNumber
	priceInGas: BigNumber
	isPaused: boolean
	refetch:any
}
const initialStateSale: ISaleContext = {
	currencies: [{value: '', label: 'ETH'}],
	priceInUSD: BigNumber.from(0),
	priceInGas: BigNumber.from(0),
	isPaused: true,
	refetch: () => {}
}
export const SaleContext = createContext<ISaleContext>(initialStateSale)
