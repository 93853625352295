export enum LockVariation {
    EMPTY = 'EMPTY',
    PURPLE = 'PURPLE',
    PINK = 'PINK',
    BLUE = 'BLUE',
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    ORANGE = 'ORANGE',
    RED = 'RED',
    NEON = 'NEON'
}

export const LocksVariationIPFS = [
    {
        variation: LockVariation.PURPLE,
        ipfs: 'ipfs://QmRN4XP89ionEx77URx2zEv5EJN9GT4LydcYqLgFwVg3Sd'
    },
    {
        variation: LockVariation.PINK,
        ipfs: 'ipfs://QmQRxuNPRmQm4AZ6dyy6mLDL7aQDQWsUNKxpTTcjF4zSko'
    },
    {
        variation: LockVariation.RED,
        ipfs: 'ipfs://QmVBPsV7kFDr6Sad5VmvpckaTbgxXQhmk9hs1Tqe7WSJEw'
    },
    {
        variation: LockVariation.ORANGE,
        ipfs: 'ipfs://QmSQjj5tAY8VpqfPP13qHRxATvUKT3jCy26NvHA7rhsRhR'
    },
    {
        variation: LockVariation.GREEN,
        ipfs: 'ipfs://QmcB1dhQwLT5AvwtPvqEPUDQq1kTF1TcU9YE1X228Qizhd'
    },
    {
        variation: LockVariation.BLUE,
        ipfs: 'ipfs://QmeuwwrC6AVDN76WADFtCfsjPgEUVnzU2wkZz6cZ3Tsukq'
    },
    {
        variation: LockVariation.YELLOW,
        ipfs: 'ipfs://QmRmsJktJQKW3B6PsUHhKi4Ywobvu39X18EkdKwC3AQTNX'
    },
    {
        variation: LockVariation.NEON,
        ipfs: 'ipfs://Qmcw32YZFz1ixUBjtQ6N5DkZdUH72ykPAdmKi1SuMUDGzD'
    }
];

export const LocksOnBridgeIPFS = [
    {
        variation: LockVariation.PURPLE,
        ipfs: 'ipfs://QmPnQXyxedymDgxE2rQMeFqSfwYUR34sMcbsTkHQ8zCbdE'
    },
    {
        variation: LockVariation.PINK,
        ipfs: 'ipfs://Qma98vzv8xcp946c8NHMagzk3UQh2he7pew5Pa79mgnaar'
    },
    {
        variation: LockVariation.RED,
        ipfs: 'ipfs://QmefuH78ockCCNdBN9Hs6k7bJbueKN5jmtpsM2Hhpf3Qvo'
    },
    {
        variation: LockVariation.ORANGE,
        ipfs: 'ipfs://QmQJmdpY8eRwXMqGmWfgaFjgb27eLVedw66idRiApFJFJM'
    },
    {
        variation: LockVariation.GREEN,
        ipfs: 'ipfs://Qme81jQ8rLuvXdgTWiL8oKrZFs8prUaYg3ZgzkmwEByAEa'
    },
    {
        variation: LockVariation.BLUE,
        ipfs: 'ipfs://QmeEwsCvT2yyVS1M8Cm7ggkk89JAGFJspCri5grBaCrfpS'
    },
    {
        variation: LockVariation.YELLOW,
        ipfs: 'ipfs://Qmby6qbrdCy5KPchSj2KVthqkMszdh1MpaeMZMbxtyCzez'
    },
    {
        variation: LockVariation.NEON,
        ipfs: 'ipfs://QmXWSpFvCYaYC57pbioy2A8bf8r1kkXLMp4PZaXbVnA7Cv'
    }
];

export const LocksOnCloudIPFS = [
    {
        variation: LockVariation.PURPLE,
        ipfs: 'ipfs://QmTQxFyxorjLCnu1mi2FnU5NMdoNgeby8SrnrvRT8MeiNo'
    },
    {
        variation: LockVariation.PINK,
        ipfs: 'ipfs://QmSG1uu9UCVka9j7V135tmqeaXBw6X2EqvZt61a2iJjwib'
    },
    {
        variation: LockVariation.RED,
        ipfs: 'ipfs://QmVuYo7xRtJTzChaiNwbQTKDoiYAwhrzhA8R99RhUBEBDn'
    },
    {
        variation: LockVariation.ORANGE,
        ipfs: 'ipfs://Qmce6CRXCytRJ5UMgowCJQ8nHhKiqeczZA4W379P4qQTnf'
    },
    {
        variation: LockVariation.GREEN,
        ipfs: 'ipfs://QmSyUjPmbVfvbxt67uowpHJoEq4xorDnPo6zQShxPFP8au'
    },
    {
        variation: LockVariation.BLUE,
        ipfs: 'ipfs://QmcYtyt1YwcYCcaKipAYSWCuPXHtYxk642MJj45H7KdvE7'
    },
    {
        variation: LockVariation.YELLOW,
        ipfs: 'ipfs://QmToZ4xwXk3C2xrhZDKT6Eo2xnAGk7XXQ1wedB87Y2qxwE'
    },
    {
        variation: LockVariation.NEON,
        ipfs: 'ipfs://QmZCCqcEkRCyyA8chAKXZ6y4B2adDhG5H9UpmqChmYE9RH'
    }
];
