import { PATH } from '../config'
import { HelmetProps } from 'components/common/HelmetWrapper'

type SeoMap = { [pathname: string]: HelmetProps }

export const SeoValues: SeoMap = {
  [PATH.HOME]: {
    title:'The Love Bridge - Eternalize Your Love With Unique Love Locks',
    metaTitle: 'The Love Bridge - Eternalize Your Love with Unique Love Locks & Store Precious Memories Forever',
    metaDescription: 'Discover The Love Bridge, where love becomes timeless. Immortalize your love and friendships with unique Love Locks and your favorite memories of people places and things. Join us to etch your love in Earth\'s digital history today!',
    ogTitle:'Love Locks and Precious Memories at The Love Bridge',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
    ogUrl: 'images/seo/tlb_site_og.jpg',
    ogDescription:'Store moments that can last beyond a lifetime with The Love Bridge. Our Love Locks and precious memories feature capture your unique love story or friendships, preserving them for eternity in the digital realm',
    ogSiteName:'The Love Bridge - The Forever Home of Love Locks',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterTitle:'The Love Bridge - Eternalize Your Love With Unique Love Locks',
    twitterImage: 'images/seo/tlb_site_og.jpg',
    twitterImageAt: 'The Love Bridge - Home of Love Locks and Earths Offical Ledger of Love',
    twitterDescription:'Store moments that can last beyond a lifetime with The Love Bridge. Our Love Locks and precious memories feature capture your unique love story or frindships, preserving them for eternity in the digital realm',
    twitterSite:'https://thelovebridge.io',
    dcContributor: 'The Love Bridge',
    dcCreator: 'The creator/signer',
    dcLanguage: 'English',
    dcTitle: 'Lock name',
    dcPublisher: 'The Love Bridge',
  },
  [PATH.SIGN_LOCKS]: {
    title:'Sign a New Love Lock | The Love Bridge',
    metaTitle: 'Sign a New Love Lock | The Love Bridge - Eternalize Your Love',
    metaDescription: 'Sign a new Love Lock on The Love Bridge and immortalize your love or friendships. Store a lasting memory in you\'re unique Love Lock. Etch your love in digital history today!',
    ogTitle:'Sign a New Love Lock with The Love Bridge',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
    ogUrl: 'images/seo/tlb_site_og.jpg',
    ogSiteName:'The Love Bridge - Sign a Lock',
    ogDescription:'At The Love Bridge, your love is timeless. Sign a new Love Lock, store your favorite moments and let your love and friendships echo through the ages! Embrace the symbol of eternal love today',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterTitle:'Store Everlasting Memories: Sign a New Love Lock | The Love Bridge',
    twitterSite:'https://thelovebridge.io'
  },
  [PATH.LOCK_DETAIL]: {
    title: 'Love Locks secured on The Love Bridge forever',
    metaDescription: 'Choose from a spectrum of stunning colours and ensure your love story lasts forever on the official worlds love ledger secured on the blockchain!  Love Locks in Firery Red, Carnival Orange, Sunshine Yellow, Fresh Green, Sea Blue, Stardust Pink, Magical Violet Purple and Bubblegum Pink.',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
  },
  [PATH.OUR_TEAM]: {
    title: 'Our team || The Love Bridge',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterSite:'https://thelovebridge.io'
  },
  [PATH.ROAD_MAP]: {
    title: 'Our Roadmap and Goals',
    metaDescription: 'Our rodmap details our goals and shares details of project developments planned.',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterSite:'https://thelovebridge.io'
  },
  [PATH.TERMS_OF_SERVICE]: {
    title: 'The Love Bridge Terms of Service',
    metaDescription: 'By connecting or signing up to our website you agree to our terms and condtions.  These can be viewed on the website.',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterSite:'https://thelovebridge.io'
  },
  [PATH.PRIVACY_POLICY]: {
    title: 'The Love Bridge Privacy policy',
    metaDescription: 'By connecting or signing up to our website you agree to our privacy policy terms.  These can be viewed on the website.',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterSite:'https://thelovebridge.io'
  },
  [PATH.INVENTORY] : {
    title : 'Inventory || Love Bridge',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterSite:'https://thelovebridge.io'
  },
  [PATH.PUBLIC_KEY_PAGE]: {
    title: 'Public key || The Love Bridge',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterSite:'https://thelovebridge.io'
  },
  [PATH.CONTACT_US]: {
    title: 'Contact The Love Bridge',
    metaDescription: 'Contact the Love Bridge using the contact form if you have a question or an issue relating to the website, we\'ll be happy to help.',
    ogType:'website',
    ogImage: 'images/seo/tlb_site_og.jpg',
    twitterCreator:'@TheLoveBridgeNFT',
    twitterSite:'https://thelovebridge.io'
  }
}
