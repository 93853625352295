export const BASE_URL = `${process.env.REACT_APP_URL_API}`

export const defaultParameters : any= {
  baseUrl: BASE_URL,
  credentials: "include",
  prepareHeaders: (headers: Headers) => {
    headers.set('Access-Control-Allow-Origin', String(process.env.REACT_APP_URL))
    return headers
  }
}

export const BASE_URL_IPFS = `https://${process.env.REACT_APP_THIRD_WALLET_CLIENT_ID}.ipfscdn.io/ipfs/`
