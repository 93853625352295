import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import { Parameters } from '@thelovebridge/core/src/constants/ParametersConstants'
import { RoadmapItem } from 'pages/Roadmap'

export const timelineSlice = createApi({
  tagTypes: ['Timeline'],
  reducerPath: 'Timeline',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    getTimeline: build.query<RoadmapItem[],void>({
      query: () => {
        return {
          url: `parameters/${Parameters.TIMELINE}`
        }
      },
      providesTags: ['Timeline']
    }),
    editTimeline: build.mutation({
      query: (timeline: RoadmapItem[]) => {
        return {
          url: `admin/parameters/${Parameters.TIMELINE}`,
          method: 'PATCH',
          body: timeline
        }
      }
    }),
  })
})
export const {
  useGetTimelineQuery,
  useEditTimelineMutation,
} = timelineSlice
