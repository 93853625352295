import styled from "styled-components";

export const TableWrap = styled.div`
  .rc-table{
    min-height: 370px;
    &-header{
		  border: none;
	  }
    &.rc-sticky-cs{
      .rc-table-body{
        border: none !important;
        max-height: 550px;
        overflow: auto;
        &::-webkit-scrollbar-track {
          background-color: ${({theme}) => theme.primaryColor4};
        }

        &::-webkit-scrollbar {
          width: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: ${({theme}) => theme.primaryColor13};
        }

      }
    }
    tbody tr td{
      background-color: transparent;
	    vertical-align: baseline;
    }
  }
	.rc-table-content{
		border: none;
	}
	table:{
		width: 100%;
	}
	tr{
    th{
      text-align: left;
	    ${({theme}) => theme.textBaseGradient};
	    ${({theme}) => theme.text2024};
      padding: 10px 12px;
	    border-right: none;
	    border-color: ${({theme}) => theme.primaryColor8};
	    text-transform: uppercase;
	    letter-spacing: 1px;
    }
		td{
			font-family: ${({theme}) => theme.fontArial};
			${({theme}) => theme.text1620};
			color: ${({theme}) => theme.primaryColor8};
			font-weight: 600;
			letter-spacing: -0.8px;
			padding: 10px 12px;
			border-right: none;
      border-color: ${({theme}) => theme.primaryColor8};
		}
	}
`