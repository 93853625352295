import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { defaultParameters } from 'constants/baseUrl'
import { Faq } from '@thelovebridge/core/src/entities'
import { Parameters } from '@thelovebridge/core/src/constants/ParametersConstants'

export const faqsSlice = createApi({
  tagTypes: ['Faqs'],
  reducerPath: 'Faqs',
  baseQuery: fetchBaseQuery(defaultParameters),
  endpoints: (build) => ({
    getFaqs: build.query({
      query: () => {
        return {
          url: `parameters/${Parameters.FAQS}`
        }
      },
      providesTags: ['Faqs']
    }),
    editFaqs: build.mutation({
      query: (faqs : Faq[]) => {
        return {
          url: `admin/parameters/${Parameters.FAQS}`,
          method: 'PATCH',
          body: faqs
        }
      }
    }),
  })
})
export const {
  useGetFaqsQuery,
  useEditFaqsMutation,
} = faqsSlice
