import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import Table from 'rc-table'
import {TableWrap} from "components/common/TableWrap";
import {ButtonStPrimary} from "components/common/ButtonSt";
import {useNftContract} from "hooks/useContract";
import {useEnsureNetwork} from "hooks/useEnsureNetwork";
import BoxNoData from "components/common/BoxNoData";
import {toast} from "react-toastify";
import {ToastError, ToastSuccess} from "components/common/Toasts";
import Loader from "components/common/Loader";
interface PropsTypeCurrenciesTable{
	dataTable:any
}
const CurrenciesTable = ({dataTable}:PropsTypeCurrenciesTable) => {
	const nftContract = useNftContract()
	const { ensureNetwork, isExpectedNetwork } = useEnsureNetwork()
	const [isWithdraw,setIsWithdraw]= useState<boolean>(false)
	const [withdrawContract,setWithdrawContract] = useState<string | undefined>(undefined)
	const [dataTableCurrencies,setDataTableCurrencies] = useState<any>([])
	const handleWithdraw = useCallback((contractAddress:string,name:string) => {
		setWithdrawContract(contractAddress)
		if(!isExpectedNetwork){
			(async ()=>{
				await ensureNetwork()
				return
			})()
		}else{
			if (!nftContract) return

			setIsWithdraw(true)
			;(async () => {
				try{
					const tx = name.toUpperCase() === 'ETH' ? await nftContract.withdrawEth() : await nftContract.withdrawErc20(contractAddress)
					await tx.wait(1)
					setDataTableCurrencies((prevState:any[]) => {
						return prevState.map((item:any) => {
							if(item.name === name){
								return{
									...item,
									balance: '0'
								}
							}
							return item
						})
					})
					toast.success(<ToastSuccess>Withdraw Success</ToastSuccess>, {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 2000
					})
				}catch{
					toast.error(<ToastError>Withdraw Failed</ToastError>, {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 2000
					})
				}finally {
					setIsWithdraw(false)
					setWithdrawContract(undefined)
				}
			})()
		}
	},[nftContract,isExpectedNetwork,ensureNetwork])
	const columns = useMemo(() => {
		return [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Icon',
				dataIndex: 'icon',
				key: 'icon',
				width: 100,
				render(value:string){
					return <IconSpan>
						{
							value !== '' && <img src={value} alt={'icon-currencies'} />
						}
					</IconSpan>
				}
			},
			{
				title: 'Balance',
				dataIndex: 'balance',
				key: 'balance',
				width: 100,
			},
			{
				title: 'Actions',
				dataIndex: 'actions',
				key: 'actions',
				width: 130,
				render(value:any,row:any){
					const {name,contractAddress,balance} = row
					const isETH = name==='ETH'
					return balance !== '0' && balance !== '0.0' ? <CtaWithDraw onClick={() => isETH ? handleWithdraw(contractAddress,name): handleWithdraw(contractAddress,name)}>
						{
							isWithdraw && withdrawContract === contractAddress ? <>
								<Loader width={16} secondary={true}/> With draw
							</>:'With draw'
						}
					</CtaWithDraw> : <></>
				}
			},
		];
	},[handleWithdraw,isWithdraw,withdrawContract])
	useEffect(() => {
		if(!dataTable){
			return
		}
		setDataTableCurrencies(dataTable)
	},[dataTable])
	return (
		<TableWrap>
			{
				dataTableCurrencies &&	<Table columns={columns} data={dataTableCurrencies as any} rowKey="name" emptyText={() => <BoxNoData />}/>
			}
		</TableWrap>
	);
};

export default CurrenciesTable;
const IconSpan = styled.span`
	width: 24px;
	height: 24px;
	display: block;
`
const CtaWithDraw = styled(ButtonStPrimary)`
	padding: 10px 16px;
	width: 130px;
	${({theme}) => theme.text1620};
	letter-spacing: 1.5px;
	${({theme}) => theme.flexRowCenter};
	gap: 6px;
`
