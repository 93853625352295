import { Alchemy } from 'alchemy-sdk';
import { Wallet } from 'ethers';

export const getSigner = async (alchemy: Alchemy, operatorKey: string) => {
    return new Wallet(operatorKey, await alchemy.config.getProvider());
};

export const domain = (chainId: string, verifyingContract: string) => ({
    name: 'TheLoveBridgeNFT',
    version: '1',
    chainId,
    verifyingContract
});
