export enum MODAL_NAME {
	RESTORE = 'RESTORE',
	BREAK = 'BREAK'
}
export enum MODAL_CONNECT_STATUS {
	CONNECT = 'CONNECT',
	SIGNING = 'SIGNING'
}
export enum MODAL_CONGRATULATION_CLAIM_GIFT{
	OPEN_NOW = 'OPEN NOW',
	OPEN_LATER = 'OPEN LATER'
}