import React from 'react';
import {Helmet} from "react-helmet-async";

export interface HelmetProps {
	// required tags
	title?: string
	metaTitle?: string
	metaDescription?: string

	// optional tags
	ogTitle?: string
	ogType?: string
	ogImage?: string
	ogUrl?: string
	ogDescription?: string
	ogSiteName?: string

	// twitter tags
	twitterCreator?: string
	twitterTitle?: string
	twitterCard?: string
	twitterImage?: string
	twitterImageAt?: string
	twitterSite?: string
	twitterDescription?: string

	//dynamic content
	dcContributor?:string
	dcCreator?:string
	dcLanguage?:string
	dcTitle?:string
	dcPublisher?:string
}

interface HelmetWrapperProps {
	helmetProps: HelmetProps
}
const HelmetWrapper = (props: HelmetWrapperProps) => {
	const {title,metaTitle,metaDescription,
		ogTitle,ogType,ogImage,ogUrl, ogDescription,ogSiteName,
		twitterCreator,twitterCard,twitterImageAt,twitterSite,twitterTitle,twitterDescription,twitterImage,
		dcContributor,dcCreator,dcLanguage,dcTitle,dcPublisher
	} = props.helmetProps
	return (
		<React.Fragment>
			<Helmet>
				<title>{title}</title>
				<meta
					name="keywords"
					content="lock nft,thelovebridge,lovebridge,lovelock,locklove,bridge,lock,nft"
				/>
				{
					metaTitle && <meta name="title" content={title} />
				}
				{
					metaDescription && <meta name='description' content={metaDescription} />
				}
				{ /* Facebook tags */ }
				{
					ogTitle && <meta property="og:title" content={ogTitle} />
				}
				{
					ogType && <meta property="og:type" content="article" />
				}
				{
					ogImage && <meta property="og:image" content={ogImage}/>
				}
				{
					ogDescription && <meta property="og:description" content={ogDescription} />
				}
				{ogUrl && <meta property="og:url" content={ogUrl}/>}
				{ogSiteName && <meta property="og:site_name" content={ogUrl}/>}
				{ /* End Facebook tags */ }
				{ /* Twitter tags */ }
				{twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
				{
					twitterTitle && <meta name="twitter:title" content={twitterTitle} />
				}
				{
					twitterDescription && <meta name="twitter:description" content={twitterDescription} />
				}
				{
					twitterCard && <meta name="twitter:card" content={twitterCard}/>
				}
				{
					twitterImage && <meta name="twitter:image" content={twitterImage}/>
				}
				{
					twitterImageAt && <meta name="twitter:image:alt" content={twitterImageAt}/>
				}
				{
					twitterSite && <meta name="twitter:site" content="https://thelovebridge.io"/>
				}
				{
					dcContributor && <meta name="dc.contributor" content={dcContributor}/>
				}
				{
					dcCreator && <meta name="dc.contributor" content={dcCreator}/>
				}
				{
					dcLanguage && <meta name="dc.contributor" content={dcLanguage}/>
				}
				{
					dcTitle && <meta name="dc.contributor" content={dcTitle}/>
				}
				{
					dcPublisher && <meta name="dc.contributor" content={dcPublisher}/>
				}
				{ /* End Twitter tags */ }
			</Helmet>
		</React.Fragment>
	);
};

export default HelmetWrapper;
