import React, {useCallback} from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import {MessageError, Text100120, Text2024} from '../Typography'
import { ButtonStPrimary, ButtonStSecondary } from '../ButtonSt';
import { FormInputSec } from '../FormInput';
import { ModalTypeBase } from './ModalType';
import { FormikValues, useFormik } from 'formik';
import Loader from '../Loader';
import DatetimePicker from '../DatetimePicker'
import moment from 'moment'
import { usePrepareGiftMutation, useSendGiftMutation } from 'state/giftsState'
import { generateGiftSignature } from '@thelovebridge/core/src/utils/GiftUtils'
import { toast } from 'react-toastify'
import { FormDatePicker } from 'components/common/FormDatePicker';
import * as Yup from "yup";
import useAuth from "hooks/useAuth";
import {useTranslation} from "react-i18next";
import { Gift } from '@thelovebridge/core/src/entities/Gifts'
import { useSigner } from '@thirdweb-dev/react'
import {ToastError, ToastSuccess} from "components/common/Toasts";
import { isAddress } from '@ethersproject/address'
import {isEnsName, resolveAddress, resolveEns} from "@thirdweb-dev/react-core";
import {POSITION_TOAST} from "constants/common";

interface PropsTypeModalCreateGift extends ModalTypeBase {}

export const ModalCreateGift = ({ onDismiss,isOpen }: PropsTypeModalCreateGift) => {
  const signer = useSigner()
  const {t} = useTranslation()
  const [prepareGift] = usePrepareGiftMutation()
  const [sendGift] = useSendGiftMutation()
  const {address} = useAuth()
  const checkErrorType = (e: string) => {
    if (e.includes('invalid address'))
      return t('modal.createGift.form.error.invalidAddress')
    else if (e.includes('invalid BigNumber string'))
      return t('modal.createGift.form.error.quantity')
    else
      return t('modal.createGift.form.error.unexpected')
  }
  const checkInputFieldValue = useCallback( (inputFieldValue: string | undefined) => {
    if(!inputFieldValue || !address){
      return false
    }
    if(isAddress(inputFieldValue)){
      return true
    } else {
      try{
        return isEnsName(inputFieldValue);
      } catch{
        return false
      }
    }
  },[address])
  const formikGift = useFormik({
    initialValues: {
      userAddress: '',
      quantity: '',
      expTimestamp: moment().add(10, 'days').unix()
    },
    validationSchema: Yup.object().shape({
      userAddress: Yup.string().trim().test('userAddress','Address is not valid address or linked to an ENS',(value)=>{
        try{
          return checkInputFieldValue(value)
        }catch (e: any){
          return false
        }
      }).required('Address is required')
        .test('userAddress','We are not allowed to gift to ourselves',(value)=> {
        try {
          return value?.toLowerCase() !== address?.toLowerCase()
        } catch (e: any){
          return false
        }
      }),
      quantity: Yup.string().required('Quantity is a required field!')
        .test(
          'len',
          'Quantity greater than 0',
          (val: any) => {
            return val && Number(val) >= 1
          },
        )
        .matches(/^[0-9\b]+$/, 'It can only contain numbers'),
    }),
    onSubmit: async (values: FormikValues) => {
      if (!signer) {
        toast.error(<ToastError>{t('toast.connectProvider')}</ToastError>, POSITION_TOAST)
        return
      }
      setSubmitting(true)
      try {
        const { userAddress, quantity, expTimestamp } = values

        const finalUserAddress = isAddress(userAddress) ? await resolveAddress(userAddress) : await resolveEns(userAddress)
        if(!finalUserAddress){
          toast.error(<ToastError>{t('toast.ensExit')}</ToastError>, POSITION_TOAST)
          return
        }

        const result = await prepareGift({
          userAddress: finalUserAddress, //user address is sent as an ethereum address
          ensAddress: userAddress.endsWith('.eth') ? userAddress : undefined, // ens will be sent if is valid ens
          quantity,
          expTimestamp
        })
        let data
        if (result.hasOwnProperty('data'))
          data = (result as any).data as Gift

        if (!data) {
          toast.error((result as any).error.data.message, POSITION_TOAST)
          return
        }
        const signature = await generateGiftSignature(String(process.env.REACT_APP_CHAIN_ID), String(process.env.REACT_APP_TLB_NFT_CONTRACT_ADDRESS), signer, {...data})
        const response = await sendGift({ id: data.id, signature })

        if (response && (response as any).error) {
          toast.error((response as any).error.data.message, POSITION_TOAST)
        } else {
          toast.success(<ToastSuccess>{t('toast.createGiftSuccess')}</ToastSuccess>,POSITION_TOAST)
        }
      } catch (e) {
        console.log(e)
        toast.error(checkErrorType(String(e)), POSITION_TOAST)
      } finally {
        setSubmitting(false)
        onDismiss()
      }
    }
  })
  const { values, handleChange, dirty, isValid,isSubmitting,setSubmitting, setFieldValue,errors } = formikGift

  return (
    <Modal onDismiss={onDismiss} name={'modal-create-gift'} isOpen={isOpen}>
      <ModalContent>
        <ModalTitle>
          <Text100120>{t('modal.createGift.title')}</Text100120>
          <Text2024>{t('modal.createGift.subTitle')}</Text2024>
        </ModalTitle>
        <ModalBody>
          <ModalForm>
            <FormGroup>
              <FormInputSec
                placeholder={t('modal.createGift.form.userAddress')}
                id={'userAddress'}
                name={'userAddress'}
                value={values.userAddress}
                onChange={handleChange}
              />
              {
                errors && errors.userAddress &&	<MessageErrorForm>{errors.userAddress as any}</MessageErrorForm>
              }
            </FormGroup>
            <FormGroup>
              <FormInputSec
                placeholder={t('modal.createGift.form.quantity')}
                id={'quantity'}
                name={'quantity'}
                value={values.quantity}
                onChange={handleChange}
              />
              {
                errors && errors?.quantity &&	<MessageErrorForm>{(errors as any).quantity}</MessageErrorForm>
              }
            </FormGroup>
            <FormDatePicker>
              <DatetimePicker
                placeholder={t('modal.createGift.form.date')}
                startDate={values?.expTimestamp}
                minDate={new Date()}
                onChange={(e) => {
                  setFieldValue('expTimestamp', Math.floor(e.getTime() / 1000))
                }}
              />
            </FormDatePicker>
          </ModalForm>
          <GroupCta>
            <CtaSkip onClick={onDismiss} disabled={isSubmitting}>{t('button.skip')}</CtaSkip>
            <CtaContinue onClick={formikGift.submitForm} disabled={!isValid || !dirty || isSubmitting}>
              {
                isSubmitting ? <>
                  <Loader />
                  {t('button.confirm')}
                </> : <>{t('button.confirm')}</>
              }
            </CtaContinue>
          </GroupCta>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  padding-left: 85px;
  padding-right: 85px;
  ${({ theme }) => theme.containerModal};
`
const ModalTitle = styled.div`
  ${({ theme }) => theme.flexColumnCenterHoz};
  margin-bottom: 40px;
  & > * {
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    & > h1 {
      font-size: ${({ theme }) => theme.fontSize60};
    }
  }
`
const ModalBody = styled.div`
  width: 100%;
`
const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`
const FormGroup = styled.div``
const GroupCta = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const CtaSkip = styled(ButtonStPrimary)`
  padding: 8px 24px;
  letter-spacing: 2.4px;
`
const CtaContinue = styled(ButtonStSecondary)`
  padding: 8px 24px;
  letter-spacing: 2.4px;
  ${({theme}) => theme.flexRowCenter};
  gap: 10px;
`
const MessageErrorForm = styled(MessageError)`
  ${({theme}) => theme.text1214};
`
