export enum GiftStatus {
    PREPARING = 'PREPARING',
    SENT = 'SENT',
    CANCELLED = 'CANCELLED',
    CLAIMED = 'CLAIMED',
    PAID = 'PAID'
}

export interface Gift {
    id: number;
    userAddress: string;
    ensAddress: string;
    quantity: number;
    expTimestamp: number;
    claimedDate?: number;
    status: GiftStatus;
    adminAddress: string;
    signature?: string;
}
